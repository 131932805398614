import TransformObject from './TransformObject';
import TriggerObject from './TriggerObject';
import APIObject from './APIObject';
import CloudFunctionObject from './CloudFunctionObject';
import ErrorHandlerObject from './ErrorHandlerObject';
import StateObject from './StateObject';
import LoopObject from './LoopObject';
import FilterObject from './FilterObject';

const workflowModule = {
  objects: {
    TriggerObject,
    TransformObject,
    APIObject,
    CloudFunctionObject,
    ErrorHandlerObject,
    StateObject,
    LoopObject,
    FilterObject
  },
  tab: 'Workflow'
};

export default workflowModule; 
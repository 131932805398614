<template>
  <component :is="wrapper" title="Open Project" :showSubmit="false">
    <v-layout>
      <v-flex sm12>
        <v-card flat class="projects-list-item" v-for="project in sortedProjects" :key="project.id">
          <v-card-title class="project-title">
            <img height="66" :src="projectImage(project)">
            <div class="project-header">
              <span class="headline font-weight-light">{{ project.name }}</span>
              <span class="project-meta">
                <span class="chart-count">{{ project.charts.length }} charts</span>
              </span>
            </div>
            <v-spacer />
            <v-menu bottom float-left>
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on">
                  <v-icon>more_vert</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item v-for="(menuItem, i) in menuItems" :key="i"
                  @click="menuItem.callback ? menuItem.callback(project) : null">
                  <v-list-item-title :class="menuItem.color">{{ menuItem.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-card-title>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12 md3 v-for="chart in sortedCharts(project.charts)" :key="chart.id">
                <v-hover v-slot="{ hover }">

                  <v-card :elevation="hover ? 12 : 2" :class="{
                    'on-hover': hover,
                    'is-widget': chart.widget
                  }" light @click="open({ chartId: chart.id, projectId: project.id })" class="projects-list-item__chart">
                    <v-img :src="chartPlaceholderImage(project)" aspect-ratio="2.75" />
                    <v-card-title>
                      <v-layout>
                        <v-flex>
                          <div class="chart-header">
                            <div class="title">{{ chart.name }}</div>
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-chip x-small color="success" class="embed-badge" v-if="chart.widget" v-bind="attrs"
                                  v-on="on">
                                  <v-icon x-small>code</v-icon>
                                </v-chip>
                              </template>
                              <span>Embed enabled</span>
                            </v-tooltip>
                          </div>
                          <v-divider />
                          <div class="chart-meta">
                            <div class="chart-time">
                              <v-icon x-small>update</v-icon>
                              Last updated {{ friendlyTime(chart.last_update) }}
                            </div>
                          </div>
                        </v-flex>
                      </v-layout>
                    </v-card-title>
                  </v-card>
                </v-hover>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card>
      </v-flex>
    </v-layout>
  </component>
</template>

<script>
import moment from "moment";

import EditProject from "./Edit";

export default {
  name: "OpenProject",
  props: {
    wrapper: {
      type: String,
      default: "Modal"
    }
  },
  data() {
    return {
      projects: [],
      selectedProject: null,
      menuItems: [
        {
          title: 'Edit',
          color: 'text-black',
          callback: (project) => {
            this.$modal.show(
              EditProject,
              {
                params: project,
                callback: res => {
                  project = { ...project, ...res };
                  this.api.Project.update(
                    { id: project.id },
                    { project: project }
                  ).then(response => {
                    let projectIndex = this.projects.findIndex((item) => item.id === project.id);
                    this.projects.splice(projectIndex, 1, project);
                  });
                }
              },
              { scrollable: true, height: "auto" }
            );
          }
        },
        {
          title: 'Delete',
          color: 'text-red',
          callback: (project) => {
            if (confirm("Are you sure? This cannot be undone.")) {
              this.api.Project.delete({ id: project.id })
                .then(response => {
                  let projectIndex = this.projects.findIndex((item) => item.id === project.id);
                  if (projectIndex >= 0)
                    this.projects.splice(projectIndex, 1);
                });
            }
          }
        },
      ]
    };
  },
  async created() {
    const res = await this.api.Project.query();
    const projects = res.body;
    projects.forEach(project => {
      project.charts.sort(
        (a, b) => a.options.order - b.options.order
      );
    });
    this.projects = projects;
  },
  methods: {
    open({ chartId, projectId }) {
      this.$router.push({ name: "editor", params: { chartId, projectId } });
      this.$emit("close");
    },
    close() {
      this.$emit("close");
    },
    projectImage(project) {
      var imageUrl = ""
      if (project.type == 'Chatbot') {
        imageUrl = "https://kaupang-production.s3.us-east-2.amazonaws.com/Vulcan+Client/chatbot-icon.svg"
      }
      return imageUrl
    },
    chartPlaceholderImage(project) {
      var imageUrl = ""
      if (project.type == 'AnalysisTools') {
        imageUrl = "https://kaupang-production.s3.us-east-2.amazonaws.com/Vulcan+Client/knowledge-project-placeholder.jpg"
      } else if (project.type == 'Chatbot') {
        imageUrl = "https://kaupang-production.s3.us-east-2.amazonaws.com/Vulcan+Client/chatbot-project-placeholder.jpg"
      } else {
        imageUrl = "https://kaupang-production.s3.us-east-2.amazonaws.com/Vulcan+Client/knowledge-project-placeholder.jpg"
      }
      return imageUrl
    },
    friendlyTime(date) {
      return moment(date).fromNow()
    },
    favorite(chart) {
      console.log('make chart favorited');
    },
    sortedCharts(charts) {
      return [...charts].sort((a, b) => {
        // Sort by order if available, then by name
        if (a.options?.order != null && b.options?.order != null) {
          return a.options.order - b.options.order;
        }
        return a.name.localeCompare(b.name);
      });
    },
    formatDate(date) {
      return moment(date).format('MMMM Do YYYY, h:mm a');
    }
  },
  computed: {
    sortedProjects() {
      return [...this.projects].sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
    }
  }
};
</script>

<style scoped>
/* Project List Layout */
.projects-list {
  display: flex;
  flex-wrap: wrap;
}

.projects-list-item {
  padding: 16px;
  margin-bottom: 24px;
  transition: all 0.2s ease;
  border-radius: 8px;
}

/* Project Header Styles */
.project-title {
  display: flex;
  align-items: center;
  padding: 12px 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

.project-header {
  display: flex;
  flex-direction: column;
  margin-left: 12px;
}

.project-meta {
  font-size: 0.875rem;
  color: rgba(0, 0, 0, 0.6);
  margin-top: 4px;
  display: flex;
  align-items: center;
}

/* Chart Card Styles */
.projects-list-item__chart {
  margin: 12px 8px;
  border-radius: 12px !important;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  cursor: pointer;
}

.projects-list-item__chart:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

.chart-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}

.title {
  margin-bottom: 0;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.4;
}

/* Chart Metadata */
.chart-meta {
  margin-top: 8px;
  font-size: 0.6rem;
  color: rgba(0, 0, 0, 0.6);
}

.chart-time {
  display: flex;
  align-items: center;
  gap: 4px;
}

/* Embed Badge Styles */
.embed-badge {
  padding: 0 4px !important;
  flex-shrink: 0;
}

.is-widget {
  border: 2px solid var(--v-success-base);
}

/* Menu Item Colors */
.text-red {
  color: red;
}

.text-black {
  color: black;
}

/* Responsive Adjustments */
@media screen and (max-width: 960px) {
  .projects-list-item__chart {
    margin: 8px 4px;
  }
}
</style>

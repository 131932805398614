<template>
  <v-toolbar dense no-padding>
    <v-select
      :items="fontfamilies"
      item-text="name"
      item-value="value"
      style="width: 150px"
      hide-details
      class="pa-0 ml-2"
      v-model="localSettings.fontFamily"
      :readonly="readonly"
      placeholder="Font"
      :menu-props="{ maxHeight: '400' }"
    />

    <v-divider class="ml-1 mr-2" vertical />

    <v-select
      :items="fontsizes"
      style="width: 60px"
      hide-details
      class="pa-0"
      v-model="localSettings.fontSize"
      :readonly="readonly"
      :menu-props="{ maxHeight: '400' }"
    />

    <v-divider class="ml-1 mr-1" vertical />

    <v-btn-toggle v-model="bold" group borderless>
      <v-btn text :disabled="readonly" class="ma-0 mr-1">
        <v-icon>format_bold</v-icon>
      </v-btn>
    </v-btn-toggle>
    <v-btn-toggle v-model="italic" group borderless>
      <v-btn text :disabled="readonly" class="ma-0 mr-1">
        <v-icon>format_italic</v-icon>
      </v-btn>
    </v-btn-toggle>
    <v-btn-toggle v-model="underline" group borderless>
      <v-btn text :disabled="readonly" class="ma-0 mr-1">
        <v-icon>format_underlined</v-icon>
      </v-btn>
    </v-btn-toggle>
    <ColorPickerDialog
      v-model="localSettings.textColor"
      title="Select Text Colour"
      :presetColors="projectColors"
      :disabled="readonly"
    />

    <v-divider vertical />

    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          text
          :disabled="readonly"
          class="ma-0"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>
            {{ textAlignIcon }}
          </v-icon>
        </v-btn>
      </template>
      <v-list dense>
        <v-list-item
          v-for="align in textAlignOptions"
          :key="align.value"
          @click="localSettings.textAlign = align.value"
          :class="{ 'v-item--active primary--text': localSettings.textAlign === align.value }"
        >
          <v-list-item-icon class="mr-2">
            <v-icon>{{ align.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ align.text }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-divider vertical />

    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          text
          :disabled="readonly"
          class="ma-0"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>
            {{ verticalAlignIcon }}
          </v-icon>
        </v-btn>
      </template>
      <v-list dense>
        <v-list-item
          v-for="align in verticalAlignOptions"
          :key="align.value"
          @click="localSettings.verticalAlign = align.value"
          :class="{ 'v-item--active primary--text': localSettings.verticalAlign === align.value }"
        >
          <v-list-item-icon class="mr-2">
            <v-icon>{{ align.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ align.text }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-divider vertical />

    <slot name="menumore" />
  </v-toolbar>
</template>

<script>
import * as _ from "underscore";
import ObjectPropertyEditorExtend from "../../_extends/ObjectPropertyEditor";

export default {
  name: "Base_TextObjectPropertyEditor",

  extends: ObjectPropertyEditorExtend,

  data() {
    return {
      fontsizes: [9, 10, 11, 13, 16, 18, 24, 48, 64, 72, 144, 248],
      fontfamilies: [
        {name: "Nunito Sans", value: "nunito sans"},
        {name: "Open Sans", value: "open sans"},
        {name: "Roboto", value: "roboto"},
        {name: "Raleway", value: "raleway"},
        {name: "Proxima Nova", value: "proxima-nova"},
        {name: "Verveine", value: "verveine"},
        {name: "Chantal", value: "chantal"},
        {name: "Helvetica", value: "helvetica"},
        {name: "Arial", value: "arial"},
        {name: "Sans Serif", value: "sans-serif"},
        {name: "Monospace", value: "monospace"},
        {name: "Serif", value: "serif"}
      ],
      bold: this.settings.fontWeight >= 700 ? 0 : undefined,
      italic: this.settings.fontStyle == 'italic' ? 0 : undefined,
      underline: this.settings.textDecoration == 'underline' ? 0 : undefined,
      textAlignOptions: [
        { value: 'left', icon: 'format_align_left', text: 'Left' },
        { value: 'center', icon: 'format_align_center', text: 'Center' },
        { value: 'right', icon: 'format_align_right', text: 'Right' },
        { value: 'justify', icon: 'format_align_justify', text: 'Justify' }
      ],
      verticalAlignOptions: [
        { value: 'flex-start', icon: 'mdi-format-align-top', text: 'Top' },
        { value: 'center', icon: 'mdi-format-align-middle', text: 'Center' },
        { value: 'flex-end', icon: 'mdi-format-align-bottom', text: 'Bottom' }
      ]
    };
  },

  computed: {
    textAlignIcon() {
      const option = this.textAlignOptions.find(opt => opt.value === this.localSettings.textAlign);
      return option ? option.icon : 'format_align_left';
    },
    verticalAlignIcon() {
      const option = this.verticalAlignOptions.find(opt => opt.value === this.localSettings.verticalAlign);
      return option ? option.icon : 'mdi-format-align-top';
    }
  },

  watch: {
    bold(val) {
      if (val === 0)
        this.$set(this.localSettings, 'fontWeight', 700);
      else
        this.$set(this.localSettings, 'fontWeight', 400);
    },
    italic(val) {
      if (val === 0)
        this.$set(this.localSettings, 'fontStyle', 'italic');
      else
        this.$set(this.localSettings, 'fontStyle', 'normal');
    },
    underline(val) {
      if (val === 0)
        this.$set(this.localSettings, 'textDecoration', 'underline');
      else
        this.$set(this.localSettings, 'textDecoration', 'none');
    }
  }
};
</script>

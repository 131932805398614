import { v4 as uuidv4 } from 'uuid';
import triggerDefaults from '@/components/Layout/Authorized/TheEditor/Object/Types/Workflow/TriggerObject/default';
import transformDefaults from '@/components/Layout/Authorized/TheEditor/Object/Types/Workflow/TransformObject/default';
import apiDefaults from '@/components/Layout/Authorized/TheEditor/Object/Types/Workflow/APIObject/default';
import cloudFunctionDefaults from '@/components/Layout/Authorized/TheEditor/Object/Types/Workflow/CloudFunctionObject/default';
import filterDefaults from '@/components/Layout/Authorized/TheEditor/Object/Types/Workflow/FilterObject/default';
import stateDefaults from '@/components/Layout/Authorized/TheEditor/Object/Types/Workflow/StateObject/default';
import loopDefaults from '@/components/Layout/Authorized/TheEditor/Object/Types/Workflow/LoopObject/default';
import errorHandlerDefaults from '@/components/Layout/Authorized/TheEditor/Object/Types/Workflow/ErrorHandlerObject/default';


class WorkflowMapperService {
  /**
   * Convert a workflow definition into workflow objects
   * @param {Object} definition - Parsed workflow definition
   * @returns {Object} Workflow objects and connections
   */
  async mapDefinitionToWorkflow(definition) {
    try {
      console.log('Starting workflow mapping with definition:', definition);
      const idMap = new Map(); // Map to store original ID to API-returned ID
      
      // Ensure nodes is an array
      if (!Array.isArray(definition.nodes)) {
        console.error('Invalid definition: nodes is not an array', definition);
        throw new Error('Invalid workflow definition: nodes must be an array');
      }
      
      // Step 1: Create all objects and collect their IDs
      console.log('Creating trigger object');
      const triggerObject = await this.createTriggerObject(definition.trigger);
      console.log('Created trigger object:', triggerObject);
      idMap.set('trigger', triggerObject.id);

      console.log('Creating workflow objects');
      const workflowObjects = await Promise.all(
        definition.nodes.map(async node => {
          const workflowObject = await this.createWorkflowObject(node);
          console.log('Created workflow object:', workflowObject);
          idMap.set(node.id, workflowObject.id);
          return workflowObject;
        })
      );

      // Step 2: Create all connections using the actual IDs
      console.log('Creating connections');
      const connections = [];

      for (const node of definition.nodes) {
        const targetId = idMap.get(node.id);
        
        // Create connections from previous nodes
        if (node.after) {
          console.log('Processing after connections for node:', node.id, 'after:', node.after);
          if (Array.isArray(node.after)) {
            // Handle multiple connections
            for (const sourceLogicalId of node.after) {
              const sourceId = idMap.get(sourceLogicalId === 'trigger' ? 'trigger' : sourceLogicalId);
              const connection = this.createConnection(sourceId, targetId);
              console.log('Created multiple connection:', connection);
              connections.push(connection);
            }
          } else {
            // Single connection
            const sourceId = idMap.get(node.after === 'trigger' ? 'trigger' : node.after);
            const connection = this.createConnection(sourceId, targetId);
            console.log('Created single connection:', connection);
            connections.push(connection);
          }
        }

        // Handle conditional routes for FilterObject
        if (node.type === 'FilterObject' && node.routes) {
          console.log('Processing FilterObject routes:', node.routes);
          if (node.routes.true) {
            const trueTargetId = idMap.get(node.routes.true);
            const connection = this.createConnection(targetId, trueTargetId, 'true');
            console.log('Created true route connection:', connection);
            connections.push(connection);
          }
          if (node.routes.false) {
            const falseTargetId = idMap.get(node.routes.false);
            const connection = this.createConnection(targetId, falseTargetId, 'false');
            console.log('Created false route connection:', connection);
            connections.push(connection);
          }
        }
      }

      const allObjects = [triggerObject, ...workflowObjects];
      console.log('Final mapping result:', { objects: allObjects, connections });
      return { objects: allObjects, connections };
    } catch (error) {
      console.error('Error in mapDefinitionToWorkflow:', error);
      throw new Error(`Failed to map workflow definition: ${error.message}`);
    }
  }

  /**
   * Create a trigger object from trigger definition
   * @param {Object} triggerDef - Trigger definition
   * @returns {Object} Trigger object
   */
  createTriggerObject(triggerDef) {
    return {
      type: 'Workflow_TriggerObject',
      position: { x: 100, y: 100 }, // Initial position
      size: { width: 200, height: 40 }, // Default size
      info: {
        settings: {
          ...triggerDefaults.settings,
          triggerType: triggerDef.type || 'manual',
          webhookPath: triggerDef.path || '',
          schedule: triggerDef.schedule || '',
          eventName: triggerDef.event || ''
        }
      }
    };
  }

  /**
   * Create a workflow object from node definition
   * @param {Object} node - Node definition
   * @returns {Object} Workflow object
   */
  createWorkflowObject(node) {
    let defaultSettings;
    switch (node.type) {
      case 'TransformObject':
        defaultSettings = transformDefaults;
        break;
      case 'APIObject':
        defaultSettings = apiDefaults;
        break;
      case 'CloudFunctionObject':
        defaultSettings = cloudFunctionDefaults;
        break;
      case 'FilterObject':
        defaultSettings = filterDefaults;
        break;
      case 'StateObject':
        defaultSettings = stateDefaults;
        break;
      case 'LoopObject':
        defaultSettings = loopDefaults;
        break;
      case 'ErrorHandlerObject':
        defaultSettings = errorHandlerDefaults;
        break;
      default:
        throw new Error(`Unknown object type: ${node.type}`);
    }

    return {
      id: node.id,
      type: `Workflow_${node.type}`,
      position: this.calculatePosition(node),
      settings: {
        ...defaultSettings.settings,
        ...this.mapNodeConfig(node.type, node.config)
      }
    };
  }

  /**
   * Map node configuration to object settings
   * @param {string} type - Node type
   * @param {Object} config - Node configuration
   * @returns {Object} Mapped settings
   */
  mapNodeConfig(type, config = {}) {
    switch (type) {
      case 'TransformObject':
        return {
          schema: config.schema || '{}'
        };
      
      case 'APIObject':
        return {
          method: config.method || 'GET',
          url: config.url || '',
          headers: config.headers || {},
          body: config.body || ''
        };
      
      case 'CloudFunctionObject':
        return {
          functionName: config.functionName || '',
          arguments: config.arguments || '{}'
        };
      
      case 'FilterObject':
        return {
          condition: config.condition || '',
          trueRoute: config.trueRoute || '',
          falseRoute: config.falseRoute || ''
        };
      
      case 'StateObject':
        return {
          variables: config.variables || '{}',
          persistence: config.persistence || 'session',
          scope: config.scope || 'local'
        };
      
      case 'LoopObject':
        return {
          loopType: config.loopType || 'forEach',
          collection: config.collection || '',
          batchSize: config.batchSize || 10
        };
      
      case 'ErrorHandlerObject':
        return {
          retryConfig: config.retryConfig || { enabled: false },
          fallbackAction: config.fallbackAction || '',
          logErrors: config.logErrors !== false
        };

      default:
        return config;
    }
  }

  /**
   * Create a connection between two objects
   * @param {string} sourceId - Source object ID
   * @param {string} targetId - Target object ID
   * @returns {Object} Connection object
   */
  createConnection(sourceId, targetId, route = '') {
    const connection = {
      id: uuidv4(),
      from: sourceId,
      to: targetId,
      route,
      type: 'workflow'
    };
    console.log('Creating connection:', { sourceId, targetId, route }, 'Result:', connection);
    return connection;
  }

  /**
   * Calculate position for a node (placeholder)
   * @param {Object} node - Node definition
   * @returns {Object} Position coordinates
   */
  calculatePosition(node) {
    // This is a simple placeholder. We'll implement proper layout later
    return {
      x: 100 + Math.random() * 200,
      y: 100 + Math.random() * 200
    };
  }
}

export default new WorkflowMapperService(); 
<template>
  <transition name="flip">
    <div class="workflow-importer" v-if="value">
      <v-card class="border px-4 d-flex flex-column" style="height: 100%">
        <v-card-title class="pa-0">
          <v-toolbar flat>
            <v-toolbar-title>Import Workflow</v-toolbar-title>
            <v-spacer />
            <v-btn icon @click="$emit('input', false)">
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar>
        </v-card-title>
        <v-divider />

        <div class="content flex-grow-1">
          <div class="d-flex align-center mt-4">
            <v-select
              v-model="selectedExample"
              :items="examples"
              label="Load Example"
              dense
              outlined
              hide-details
              class="mr-2"
              :disabled="!!yamlContent"
            />
            <v-btn
              small
              text
              @click="loadExample"
              :disabled="!selectedExample || !!yamlContent"
            >
              Load
            </v-btn>
          </div>

          <v-alert
            v-if="error"
            type="error"
            dense
            text
            dismissible
            @input="error = ''"
            class="mt-4"
          >
            {{ error }}
          </v-alert>

          <div class="editor-container mt-4">
            <label class="v-label theme--light">YAML Definition</label>
            <editor
              v-model="yamlContent"
              @init="initEditor"
              lang="yaml"
              theme="chrome"
              width="100%"
              height="400"
              class="workflow-yaml-editor"
            ></editor>
            <div class="v-messages theme--light">
              <div class="v-messages__wrapper">
                <div class="v-messages__message">Paste your workflow YAML definition here</div>
              </div>
            </div>
          </div>
        </div>

        <v-card-actions>
          <v-btn
            text
            @click="yamlContent = ''"
            :disabled="!yamlContent"
          >
            Clear
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="importWorkflow"
            :loading="loading"
            :disabled="!yamlContent"
          >
            Import
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>
  </transition>
</template>

<script>
import WorkflowDefinitionService from '@/services/WorkflowDefinitionService';
import WorkflowMapperService from '@/services/WorkflowMapperService';
import { workflowExamples } from './WorkflowImporter/examples';
import yaml from 'js-yaml';

export default {
  name: 'WorkflowImporter',
  components: {
    editor: require('vue2-ace-editor')
  },
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      yamlContent: '',
      error: '',
      loading: false,
      selectedExample: null,
      examples: workflowExamples
    };
  },
  methods: {
    initEditor(editor) {
      require('brace/ext/language_tools');
      require('brace/mode/yaml');
      require('brace/theme/chrome');
      
      editor.setOptions({
        enableBasicAutocompletion: true,
        enableLiveAutocompletion: true,
        showPrintMargin: false,
        fontSize: 14,
        wrap: true
      });
    },

    loadExample() {
      const example = this.examples.find(e => e.value === this.selectedExample);
      if (example) {
        this.yamlContent = yaml.dump(example.yaml);
      }
    },

    async importWorkflow() {
      this.loading = true;
      this.error = '';

      try {
        const definition = WorkflowDefinitionService.parseDefinition(this.yamlContent);
        const { objects, connections } = WorkflowMapperService.mapDefinitionToWorkflow(definition);
        this.$emit('workflow-imported', { objects, connections });
        this.$emit('input', false);
        this.yamlContent = '';
      } catch (error) {
        this.error = error.message;
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style scoped>
.workflow-importer {
  position: absolute;
  right: 0;
  top: 0;
  width: 25%;
  height: 100%;
  padding: 15px;
  z-index: 10;
  transition-duration: 300ms;
  transition-property: transform, opacity;
}

.border {
  border-radius: 6px 20px 20px 20px;
}

.content {
  overflow-y: auto;
}

.editor-container {
  margin-bottom: 16px;
}

.editor-container .v-label {
  margin-bottom: 8px;
  font-size: 0.75rem;
  color: rgba(0, 0, 0, 0.6);
}

.editor-container .ace_editor {
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  font-family: 'Monaco', 'Menlo', 'Ubuntu Mono', 'Consolas', 'source-code-pro', monospace;
}

/* Animation classes */
.flip-enter {
  opacity: .01;
  transform: translateX(100%);
}

.flip-enter-to {
  opacity: 1;
  transform: translateX(0%);
}

.flip-leave {
  opacity: 1;
}

.flip-leave-to {
  opacity: .01;
  transform: translateX(100%);
}
</style> 
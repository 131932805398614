<template>
  <Modal
    @submit="submit"
    :title="isNew ? 'New Chart' : 'Edit Chart'"
    :showSubmit="!!localParams.name"
    :submitText="isNew ? 'Create' : 'Save'"
  >
    <v-container class="px-9 pt-9">
      <v-row class="mx-0">
        <v-text-field
          class="headline"
          autofocus
          @keyup.enter.native="$emit('submit')"
          type="text"
          v-model="localParams.name"
          label="Chart Name"
        />
      </v-row>
      <v-row class="mx-0">
        <v-col class="pa-0">
          <v-switch
            v-model="localParams.options.bgImageEnabled"
            :label="localParams.options.bgImageEnabled? 'Background Image is enabled': 'Background Image is disabled'"
          />
          <div v-if="localParams.options.bgImageEnabled">
            <Uploader
              class=""
              label="Background Image"
              icon="image"
              v-model="localParams.options.icon"
            />
          </div>
        </v-col>
      </v-row>
      <v-row class="mx-0">
        <v-col class="pa-0">
          <v-switch
            v-model="localParams.options.gridEnabled"
            :label="localParams.options.gridEnabled ? 'Grid is enabled' : 'Grid is disabled'"
          />
        </v-col>
        <v-col align-self="center" class="text-right" v-if="localParams.options.gridEnabled">
          Grid size:
        </v-col>
        <v-col class="pa-0" v-if="localParams.options.gridEnabled">
          <v-select
            hide-details
            :items="gridSizes"
            v-model="localParams.options.gridSize"
          />
        </v-col>
        <v-col align-self="center" class="text-right" v-if="localParams.options.gridEnabled">
          Grid color:
        </v-col>
        <v-col class="pa-0" v-if="localParams.options.gridEnabled">
          <v-select
            hide-details
            :items="gridColor"
            v-model="localParams.options.gridColor"
          />
        </v-col>
      </v-row>
      <v-row class="mx-0" v-if="localParams.options.gridEnabled">
        <v-col class="pa-0">
          <v-switch
            v-model="localParams.options.gridSnap"
            label="Snap to grid is enabled"
          />
        </v-col>
      </v-row>
      <v-row class="mx-0">
        <v-col class="pa-0">
          <v-switch
            v-model="localParams.options.navigationControlsEnabled"
            label="Navigation Control is enabled"
          />
        </v-col>
      </v-row>

      <v-btn v-if="!isNew"
             class="my-4"
             color="primary"
             @click="moveToProject">
        Move to project...
      </v-btn>
    </v-container>

    <v-container class="px-9 pb-9 sharing-options-wrap">
      <v-card-title class="pl-0">
        <v-icon class="mr-2" color="white">public</v-icon>
        Sharing Options
      </v-card-title>
      <v-divider dark />

      <v-switch
        dark
        hide-details
        v-model="localParams.widget"
        label="Public access to chart"
      />

      <div v-if="localParams.widget">
        <v-text-field class="embed-snippet" :value="publicUrl" dark readonly>
          <template v-slot:append>
            <v-btn @click="copyUrl" dark color="secondary" class="copy-btn">
              <v-icon small>mdi-content-copy</v-icon>
            </v-btn>
          </template>
        </v-text-field>

        <div>
          <v-icon class="mr-2" color="white">widgets</v-icon>
          Embed Widget
        </div>

        <v-text-field class="embed-snippet" :value="widgetUrl" dark readonly hide-details>
          <template v-slot:append>
            <v-btn @click="copyWidget" dark color="secondary" class="copy-btn">
              <v-icon small>mdi-content-copy</v-icon>
            </v-btn>
          </template>
        </v-text-field>

        <v-switch
          dark
          hide-details
          v-model="localParams.options.public_comments"
          :label="localParams.options.public_comments? 'Comments are allowed in the Published Chart' : 'Comments are disabled in the Published Chart'"
        />
      </div>

    </v-container>


    <v-dialog v-model="moveToProjectDialog" width="500">
      <v-card :loading="!projects">
        <v-card-title>Moving chart to project</v-card-title>
        <v-container>
          <v-select
            outlined
            label="Project"
            :items="projects"
            item-text="name"
            item-value="id"
            v-model="project"
            :loading="!projects"
            dense
          />

          <v-btn @click="moveToProjectApply" color="secondary" small>ok</v-btn>
        </v-container>
      </v-card>
    </v-dialog>

  </Modal>
</template>

<script>
import {copyToClipboard, deepClone} from "@/lib";
import defaultChartOptions from './default.js';

export default {
  name: "EditChart",
  props: {
    params: Object,
    callback: Function
  },
  data() {
    const localParams = deepClone(this.params || {});
    if (!localParams.options) {
      localParams.options = defaultChartOptions;
    }

    return {
      localParams,

      submitBtn: {
        edit: 'Update',
        new: 'Create'
      },

      gridSizes: ["Small", "Medium", "Large"],
      gridColor: ["Gray", "Black"],

      moveToProjectDialog: false,
      projects: null,
      project: this.activeProject
    };
  },
  computed: {
    isNew() {
      return !this.params.created_at;
    },
    widgetUrl() {
      const src = `${window.location.origin}/widget/${this.localParams.id}`;
      return `<iframe width="420" height="280" src="${src}" />`;
    },
    publicUrl() {
      const src = `${window.location.origin}/widget/${this.localParams.id}`;
      return src;
    }
  },

  methods: {
    submit() {
      if (!this.localParams.name)
        return;

      if (this.callback)
        this.callback(this.localParams);

      this.close();
    },
    close() {
      this.$emit("close");
    },
    copyWidget() {
      let data = {
        message: "Copied Widget to Clipboard",
        color: "success"
      };
      copyToClipboard(this.widgetUrl);
      this.$root.$emit("showSnack", data);
    },
    copyUrl() {
      let data = {
        message: "Copied URL to Clipboard",
        color: "success"
      };
      copyToClipboard(this.publicUrl);
      this.$root.$emit("showSnack", data);
    },
    async moveToProject() {
      this.moveToProjectDialog = true;

      const res = await this.api.Project.query();
      this.projects = res.body;
      this.project = this.projects.find(project => project.id == this.activeProject.id);
    },
    moveToProjectApply() {
      if (!this.projects)
        return;

      if (this.project.id != this.activeProject.id)
        this.localParams.project_id = this.project;
      this.moveToProjectDialog = false;
    }
  }
};
</script>

<style scoped>
.sharing-options-wrap {
  background: #2c2c54;
  color: #FFF;
}

.sharing-options-header {
  padding: 20px;
  font-size: 24px;
}

.sharing-options-content {
  padding: 20px;
}

.embed-snippet {
  font-family: monospace !important;
}

.copy-btn {
  font-family: "Roboto", sans-serif !important;
}

.embed-switch {
  max-width: 60px;
}
</style>
<template>
  <div class="comment-object" :class="{opened: opened}">
    <div ref="point" @click="toggle" class="comment-object__target-point" :style="pointStyle">
      <div class="comment-object__target-points__text">{{settings.comments.length}}</div>
    </div>
  </div>
</template>

<script>
import ExtendsComponent from "../../_extends/ObjectComponent";

export default {
  name: "Base_CommentObject",
  extends: ExtendsComponent,
  created() {
    this.$root.$on("closeAllComments", this.onCloseAllComments);
  },
  beforeDestroy() {
    this.$root.$off("closeAllComments", this.onCloseAllComments);
  },
  data() {
    return {
      opened: false,
    };
  },
  computed: {
    pointStyle() {
      return {
        color: this.settings.color,
        background: this.settings.color,
        filter: this.selected ? `brightness(105%)` : null,
        transform: `scale(${1/this.scale})`,
        transformOrigin: '50% 50%'
      };
    }
  },
  methods: {
    onCloseAllComments() {
      this.opened = false;
      this.$store.commit('chart/closeComment', this.baseObject);
    },
    toggle() {
      this.opened = !this.opened;
      if (this.opened) {
        this.$store.commit('chart/openComment', this.baseObject);
      } else {
        this.$store.commit('chart/closeComment', this.baseObject);
      }
    }
  },
  mounted() {
    if (this.settings.comments.length === 0) {
      this.opened = true;
      this.$store.commit('chart/openComment', this.baseObject);
    }
  }
};
</script>

<style scoped>
.comment-object {
  box-shadow: none !important;
}

.comment-object__target-point {
  width: 24px;
  height: 20px;
  position: relative;
  cursor: pointer;
  z-index: 2;
  background: currentColor;
  border-radius: 4px;
  margin-top: -3px;
}

/* Create the speech bubble tail */
.comment-object__target-point:after {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 4px;
  width: 8px;
  height: 8px;
  background: currentColor;
  clip-path: polygon(0 0, 100% 0, 0 100%);
}

.comment-object__target-points__text {
  position: absolute;
  top: 0px;
  color: white;
  right: 0;
  bottom: 0;
  left: 0;
  text-align: center;
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
}
</style>

<style>
.Base_CommentObject .v-list--three-line .v-list__tile {
  height: auto;
  padding: 20px;
  min-height: 88px;
}
</style>
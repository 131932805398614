<template>
  <div class="workflow-transform">
    <div class="transform-content">
      <div class="transform-header">
        <v-icon small class="mr-2">mdi-transform</v-icon>
        <span>Transform</span>
      </div>
      <div class="transform-operations" v-if="operationCount">
        {{ operationCount }} operation{{ operationCount !== 1 ? 's' : '' }}
      </div>
      <div class="transform-empty" v-else>
        No operations defined
      </div>
    </div>
  </div>
</template>

<script>
import ExtendsComponent from '../../_extends/ObjectComponent';

export default {
  name: 'Workflow_TransformObject',
  extends: ExtendsComponent,
  computed: {
    operationCount() {
      return this.localSettings.operations?.length || 0;
    }
  }
}
</script>

<style scoped>
.workflow-transform {
  border: 3px solid #9C27B0;
  padding: 8px;
  min-width: 200px;
  border-radius: 5px;
  cursor: default;
  background: white;
}

.transform-content {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.transform-header {
  display: flex;
  align-items: center;
  font-weight: 500;
}

.transform-operations {
  font-size: 0.8em;
  color: #666;
}

.transform-empty {
  font-size: 0.8em;
  color: #999;
  font-style: italic;
}
</style> 
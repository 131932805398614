<template>
  <div class="workflow-api">
    <div class="api-content">
      <div class="api-header">
        <v-icon small class="mr-2">mdi-api</v-icon>
        <div class="method-badge" :class="methodClass">
          {{ localSettings.method }}
        </div>
        <span class="api-url">{{ displayUrl }}</span>
      </div>
      <div class="api-details" v-if="showDetails">
        <div v-if="localSettings.retryConfig.enabled" class="detail-item">
          <v-icon x-small class="mr-1">mdi-refresh</v-icon>
          Retry: {{ localSettings.retryConfig.maxAttempts }}x
        </div>
        <div v-if="localSettings.authentication.type !== 'none'" class="detail-item">
          <v-icon x-small class="mr-1">mdi-shield-key</v-icon>
          Auth: {{ localSettings.authentication.type }}
        </div>
        <div class="detail-item">
          <v-icon x-small class="mr-1">mdi-timer-outline</v-icon>
          {{ localSettings.timeout / 1000 }}s timeout
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ExtendsComponent from '../../_extends/ObjectComponent';

export default {
  name: 'Workflow_APIObject',
  extends: ExtendsComponent,
  computed: {
    methodClass() {
      const method = this.localSettings.method.toLowerCase();
      return {
        'method-get': method === 'get',
        'method-post': method === 'post',
        'method-put': method === 'put',
        'method-delete': method === 'delete',
        'method-patch': method === 'patch'
      };
    },
    displayUrl() {
      const url = this.localSettings.url || 'No URL set';
      return url.length > 40 ? url.substring(0, 37) + '...' : url;
    },
    showDetails() {
      return this.localSettings.retryConfig.enabled || 
             this.localSettings.authentication.type !== 'none' ||
             this.localSettings.timeout !== 30000;
    }
  }
};
</script>

<style scoped>
.workflow-api {
  border: 2px solid #FF9800;
  padding: 8px;
  min-width: 200px;
  border-radius: 5px;
  cursor: default;
  background: white;
}

.api-content {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.api-header {
  display: flex;
  align-items: center;
  gap: 8px;
}

.method-badge {
  padding: 2px 6px;
  border-radius: 3px;
  font-size: 0.8em;
  font-weight: bold;
  text-transform: uppercase;
}

.method-get {
  background-color: #4CAF50;
  color: white;
}

.method-post {
  background-color: #2196F3;
  color: white;
}

.method-put {
  background-color: #FF9800;
  color: white;
}

.method-delete {
  background-color: #F44336;
  color: white;
}

.method-patch {
  background-color: #9C27B0;
  color: white;
}

.api-url {
  font-family: monospace;
  font-size: 0.9em;
  color: #666;
}

.api-details {
  display: flex;
  gap: 12px;
  font-size: 0.8em;
  color: #666;
  margin-top: 4px;
}

.detail-item {
  display: flex;
  align-items: center;
}
</style> 
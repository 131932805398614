<template>
  <div class="page">
    <app-header
      title="Settings"
      :tabs="tabs"
      :activeTabInd="activeTabIndex"
      @activeTabChanged="setActiveTab"
    />

    <v-main class="page-content">
      <router-view></router-view>
    </v-main>
  </div>
</template>

<script>
import AppHeader from './AppHeader';

export default {
  name: 'TheSettings',
  components: {
    AppHeader
  },
  data () {
    return {
      tabs: [
        { name: 'Profile', route: 'settings-profile' },
        { name: 'Configuration', route: 'settings-configuration' },
        { name: 'Connected Apps', route: 'settings-connected-apps' }
      ]
    };
  },
  computed: {
    activeTabIndex() {
      const currentRoute = this.$route.name;
      return this.tabs.findIndex(tab => tab.route === currentRoute);
    }
  },
  methods: {
    setActiveTab(index) {
      const targetRoute = this.tabs[index].route;
      if (targetRoute !== this.$route.name) {
        this.$router.push({ name: targetRoute }).catch(err => {
          if (err.name !== 'NavigationDuplicated') {
            throw err;
          }
        });
      }
    }
  },
  watch: {
    '$route': {
      immediate: true,
      handler(to) {
        if (to.name === 'settings') {
          this.$router.replace({ name: 'settings-profile' }).catch(err => {
            if (err.name !== 'NavigationDuplicated') {
              throw err;
            }
          });
        }
      }
    }
  },
  mounted() {
    window.analytics.page('Settings');
  }
}
</script>
import { CONTENT_TYPES } from '../constants';

export async function parseClipboardContent(clipboardItems) {
  for (const item of clipboardItems) {
    if (item.types.includes('image/png') || item.types.includes('image/jpeg')) {
      return { type: CONTENT_TYPES.IMAGE, data: await item.getType(item.types.find(type => type.startsWith('image/'))) };
    }
    if (item.types.includes('text/html')) {
      return { type: CONTENT_TYPES.HTML, data: await item.getType('text/html') };
    }
    if (item.types.includes('text/plain')) {
      return { type: CONTENT_TYPES.TEXT, data: await item.getType('text/plain') };
    }
  }
  return null;
}
<template>
    <div>
        <v-menu offset-y offset-x>
            <template v-slot:activator="{ on }">
                <v-btn v-on="on" text class="toolbar-button" id="menuMore">
                    <v-icon>more_horiz</v-icon>
                </v-btn>
            </template>

            <v-list>
                <template v-for="(section, sectionIndex) in menuConfig.sections">
                    <!-- Section header with divider -->
                    <div :key="section.id">
                        <v-divider v-if="sectionIndex > 0" />
                        <v-subheader>{{ section.title }}</v-subheader>

                        <!-- Section items -->
                        <template v-for="item in section.items">
                            <v-list-item v-if="shouldShowItem(item)" :key="item.id" :disabled="isItemDisabled(item)"
                                @click="handleItemClick(item)">
                                <v-list-item-icon class="mr-2">
                                    <v-icon small v-if="typeof item.icon === 'function'"
                                        v-text="item.icon(context)"></v-icon>
                                    <v-icon small v-else v-text="item.icon"></v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>{{ getItemTitle(item) }}</v-list-item-title>
                                </v-list-item-content>

                                <v-list-item-action v-if="getItemHotkey(item)" class="hotkey">
                                    {{ getItemHotkey(item) }}
                                </v-list-item-action>
                            </v-list-item>
                        </template>
                    </div>
                </template>
            </v-list>
        </v-menu>

        <!-- Add dialogs section -->
        <div>
            <!-- Object Info Dialog -->
            <v-dialog v-model="dialogObjectInfo" width="500">
                <v-card>
                    <v-card-title>Object Info</v-card-title>
                    <v-card-text class="mt-4">
                        <p>
                            <b>Object ID:</b> {{ baseObject.id }}
                        </p>
                        <p>
                            <b>Object type:</b> {{ baseObject.type }}
                        </p>
                        <p>
                            <b>Created by</b> {{ creator }}, {{ createdTimeAgo }}
                        </p>
                    </v-card-text>
                </v-card>
            </v-dialog>

            <!-- Object Type Dialog -->
            <v-dialog v-model="dialogObjectType" width="500">
                <v-card>
                    <v-card-title class="headline" primary-title>Set object type</v-card-title>
                    <v-container>
                        <v-select outlined label="Object type" :items="componentTypes" item-text="name" item-value="id"
                            v-model="selectedObjectType" dense>
                            <template v-slot:item="{ item }">
                                <v-list-item-content>
                                    <v-list-item-title v-if="typeof item !== 'object'" v-text="item" />
                                    <v-list-item-title v-else>{{ item.name }}</v-list-item-title>
                                </v-list-item-content>
                            </template>
                        </v-select>

                        <v-btn @click="updateObjectType" color="secondary" small>ok</v-btn>
                    </v-container>
                </v-card>
            </v-dialog>

            <!-- Create Symbol Dialog -->
            <v-dialog v-model="dialogCreateSymbol" width="500">
                <v-card>
                    <v-card-title class="headline" primary-title>
                        {{ baseObject && baseObject.symbol_name ? 'Change symbol name' : 'Create symbol' }}
                    </v-card-title>
                    <v-container>
                        <v-text-field @click.stop ref="createSymbolField" label="Symbol name" v-model="symbolName" />
                        <v-btn @click="createSymbol" color="secondary" small>Save</v-btn>
                    </v-container>
                </v-card>
            </v-dialog>

            <v-dialog v-model="dialogTemplate" width="500" persistent :retain-focus="false">
                <v-card :loading="templateCreating" :disabled="templateCreating">
                    <v-card-title class="headline" primary-title>New template</v-card-title>
                    <v-card-text class="pa-3">
                        <div style="flex: auto">
                            <v-text-field ref="saveTemplate" label="Template name" type="text" v-model="templateName"
                                @click.stop :autofocus="true" />
                        </div>

                        <v-flex xs12 class="mb-6 preview-container">
                            <TemplatePreview ref="preview" :settings="{ background: settings.background }"
                                :objects="templateObjects" :connections="templateConnections" :group="baseObject" />
                        </v-flex>

                        <v-btn @click="createTemplate" color="primary" :disabled="!templateName || templateCreating">
                            create
                        </v-btn>
                        <v-btn class="ml-3" @click="dialogTemplate = false" color="secondary"
                            :disabled="templateCreating">
                            close
                        </v-btn>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </div>
    </div>
</template>

<script>
import { copyToClipboard, isMac } from "@/lib";
import { menuConfig } from './config';
import moment from 'moment';
import { ObjectTypesByModule, defaultProperties } from "../../Object/Types";
import * as _ from "underscore";
import TemplatePreview from "../TemplatePreview.vue";

export default {
    name: "MenuMoreNew",
    components: {
        TemplatePreview
    },
    props: {
        baseObject: {
            type: Object,
            default: null
        },
        readonly: {
            type: Boolean,
            default: false
        },
        settings: {
            type: Object,
            required: true
        }
    },

    data() {
        const componentTypes = [];
        _.keys(ObjectTypesByModule).forEach(group => {
            if (group == 'Core') return;

            componentTypes.push({ header: group });

            ObjectTypesByModule[group].forEach(el => {
                if (el == "Base_GroupObject") return;

                componentTypes.push({
                    id: el,
                    name: _.last(el.split("_")).replace("Object", "")
                });
            });
        });

        return {
            menuConfig,
            modKey1: isMac() ? "⌘" : "Ctrl",
            modKey2: isMac() ? "Ctrl" : "Win",
            dialogObjectInfo: false,
            dialogObjectType: false,
            dialogCreateSymbol: false,
            componentTypes,
            selectedObjectType: this.baseObject?.type || null,
            symbolName: "",
            templateName: "",
            templateCreating: false,
            dialogTemplate: false,
        };
    },

    computed: {
        objectType() {
            return this.baseObject?.type || '';
        },

        locked() {
            return this.settings?.locked || false;
        },

        inGroup() {
            return this.baseObject?.parent_id != null;
        },

        orderForwardDisabled() {
            return false; // Implement actual logic
        },

        orderBackDisabled() {
            return false; // Implement actual logic
        },

        defaultProperties() {
            return this.baseObject?.type ? defaultProperties[this.baseObject.type] : {};
        },

        // Context object that gets passed to dynamic functions
        context() {
            return {
                baseObject: this.baseObject,
                settings: this.settings,
                readonly: this.readonly,
                objectType: this.objectType,
                locked: this.locked,
                inGroup: this.inGroup,
                orderForwardDisabled: this.orderForwardDisabled,
                orderBackDisabled: this.orderBackDisabled,
                defaultProperties: this.defaultProperties,
                modKey1: this.modKey1,
                modKey2: this.modKey2
            };
        },

        creator() {
            const _creator = this.baseObject?.info?.settings?.created_by;
            return _creator ? _creator : 'Anonymous User';
        },
        createdTimeAgo() {
            return moment(this.baseObject?.created_at).fromNow();
        },
        templateObjects() {
            const objects = this.$store.getters["object/list"]
                .filter(object => this.settings.objectIds?.includes(object.id))
                .map(o => {
                    return {
                        ...o,
                        position: {
                            x: o.position.x - this.baseObject.position.x,
                            y: o.position.y - this.baseObject.position.y
                        }
                    };
                });
            return objects;
        },

        templateConnections() {
            const connections = this.$store.getters["connection/list"].filter(
                t =>
                    this.settings.objectIds?.includes(t.from) ||
                    this.settings.objectIds?.includes(t.to)
            );
            return connections;
        }
    },

    methods: {
        shouldShowItem(item) {
            if (!item.showWhen) return true;
            return typeof item.showWhen === 'function'
                ? item.showWhen(this.context)
                : this.context[item.showWhen];
        },

        isItemDisabled(item) {
            if (!item.disabledWhen) return false;
            return typeof item.disabledWhen === 'function'
                ? item.disabledWhen(this.context)
                : this.context[item.disabledWhen];
        },

        getItemTitle(item) {
            return typeof item.title === 'function'
                ? item.title(this.context)
                : item.title;
        },

        getItemHotkey(item) {
            if (!item.hotkey) return null;
            return typeof item.hotkey === 'function'
                ? item.hotkey(this.context)
                : item.hotkey;
        },

        handleItemClick(item) {
            // Handle special cases that need dialog management
            switch (item.action) {
                case 'view-info':
                    this.dialogObjectInfo = true;
                    return;
                case 'change-type':
                    this.selectedObjectType = this.baseObject?.type;
                    this.dialogObjectType = true;
                    return;
                case 'create-symbol':
                    this.symbolName = this.baseObject?.symbol_name || this.baseObject?.info?.settings?.title || '';
                    this.dialogCreateSymbol = true;
                    this.$nextTick(() => {
                        this.$refs.createSymbolField?.focus();
                    });
                    return;
                case 'copy-link':
                    copyToClipboard(window.location.origin + '/object/' + this.baseObject.id);
                    return;
                case 'copy-id':
                    copyToClipboard(this.baseObject.id);
                    return;
                case 'notion-toggle':
                    this.$set(this.settings, 'notionEnabled', !this.settings.notionEnabled);
                    this.$store.dispatch("object/update", this.baseObject);
                    return;
                case 'lock':
                    this.settings.locked = !this.locked;
                    this.$store.dispatch("object/update", this.baseObject);
                    this.$emit('deselect');
                    return;
                case 'bring-forward':
                    this.$store.dispatch("object/orderForward", this.baseObject.id);
                    this.$emit('deselect');
                    return;
                case 'send-backward':
                    this.$store.dispatch("object/orderBackward", this.baseObject.id);
                    this.$emit('deselect');
                    return;
                case 'bring-to-front':
                    this.$store.dispatch("object/orderFront", this.baseObject.id);
                    this.$emit('deselect');
                    return;
                case 'send-to-back':
                    this.$store.dispatch("object/orderBack", this.baseObject.id);
                    this.$emit('deselect');
                    return;
                case 'duplicate':
                    this.$store.dispatch("object/duplicate", this.baseObject.id);
                    return;
                case 'create-group':
                    this.$store.dispatch("object/createGroup");
                    return;
                case 'extract-from-group':
                    this.groups.forEach(group => {
                        group.info.settings.objectIds = group.info.settings.objectIds.filter(
                            id => id != this.baseObject.id
                        );
                        this.$store.dispatch("object/update", group);
                    });
                    return;
                case 'save-as-template':
                    console.log('Opening template dialog', {
                        objectCount: this.settings.objectIds?.length,
                        baseObjectId: this.baseObject.id
                    });

                    // Set dialog flag first
                    this.dialogTemplate = true;

                    // Use multiple attempts to find and focus the input
                    const maxAttempts = 5;
                    let attempts = 0;

                    const tryFocus = () => {
                        attempts++;
                        console.log(`Focus attempt ${attempts}`);

                        if (this.$refs.saveTemplate?.$el) {
                            const input = this.$refs.saveTemplate.$el.querySelector('input');
                            if (input) {
                                console.log('Input found, focusing');
                                input.focus();
                                return true;
                            }
                        }

                        if (attempts < maxAttempts) {
                            setTimeout(tryFocus, 100 * attempts); // Increasing delays between attempts
                            return false;
                        }

                        console.log('Max focus attempts reached');
                        return false;
                    };

                    // Start first attempt after a short delay
                    setTimeout(tryFocus, 50);
                    return;
                case 'ungroup':
                    this.baseObject.info.settings.objectIds.map((objId) => {
                        const object = this.$store.getters['object/findById'](objId);
                        if (object) {
                            const { id, info } = object
                            this.$store.dispatch('object/update', {
                                id,
                                info: {
                                    ...info,
                                    settings: { ...info.settings, transformOrigin: 'center center' }
                                }
                            });
                        }
                    });
                    this.baseObject.info.settings.objectIds = [];
                    this.$store.dispatch("object/update", this.baseObject);
                    this.$store.dispatch('object/delete', this.baseObject.id);
                    return;
                case 'delete':
                    this.$store.dispatch("object/delete", this.baseObject.id);
                    return;
            }

            // Emit any unhandled actions
            this.$emit(item.action, this.baseObject);
        },

        updateObjectType() {
            if (this.selectedObjectType !== this.baseObject.type) {
                this.$store.dispatch("object/update", {
                    id: this.baseObject.id,
                    type: this.selectedObjectType
                });
                this.$emit('change-type', {
                    id: this.baseObject.id,
                    type: this.selectedObjectType
                });
            }
            this.dialogObjectType = false;
        },

        createSymbol() {
            this.dialogCreateSymbol = false;
            this.$emit('create-symbol', {
                id: this.baseObject.id,
                symbolName: this.symbolName
            });
        },

        // Add these methods for template handling
        onDialogShow() {
            console.log('Dialog shown');
            setTimeout(() => {
                if (this.$refs.saveTemplate) {
                    console.log('Found text field ref');
                    const input = this.$refs.saveTemplate.$el.querySelector('input');
                    if (input) {
                        input.focus();
                        console.log('Input focused');
                    }
                } else {
                    console.log('Text field ref still not found');
                }
            }, 100);
        },

        onTemplateFieldMounted() {
            console.log('Template field mounted:', this.$refs.saveTemplate);
        },

        onDialogInput(value) {
            console.log('Dialog opened:', value);
            if (value) {  // Dialog is opening
                this.$nextTick(() => {
                    console.log('Next tick - saveTemplate ref:', this.$refs.saveTemplate);
                    if (this.$refs.saveTemplate) {
                        try {
                            this.$refs.saveTemplate.focus();
                            console.log('Focus attempted');
                        } catch (error) {
                            console.error('Focus error:', error);
                        }
                    } else {
                        console.log('saveTemplate ref not found');
                    }
                });
            }
        },

        async createTemplate() {
            this.templateCreating = true;
            console.log('Starting template creation', {
                objectCount: this.templateObjects.length,
                dialogOpen: this.dialogTemplate,
                hasPreviewRef: !!this.$refs.preview
            });

            try {
                // Ensure preview is ready before proceeding
                await new Promise(resolve => setTimeout(resolve, 100));
                await this.$nextTick();

                if (!this.$refs.preview?.$el) {
                    throw new Error('Preview element not ready');
                }

                // Structure according to API docs
                const chart_template = {
                    name: this.templateName,
                    settings: {
                        objects: this.templateObjects,
                        connections: this.templateConnections,
                        group: {
                            objectIds: this.settings.objectIds,
                            ...this.settings
                        },
                        background: this.settings.background
                    }
                };

                // Use ChartTemplate resource with correct param name
                await this.api.ChartTemplate.save({ chart_template });

                this.$store.dispatch('notification/success', 'Template created successfully');

                // Close dialog
                this.dialogTemplate = false;
                this.templateName = '';
            } catch (error) {
                console.error('Template creation error:', error);
                this.$store.dispatch('notification/error', 'Failed to create template: ' + error.message);
            } finally {
                this.templateCreating = false;
            }
        }
    }
};
</script>

<style scoped lang="scss">
.toolbar-button {
    min-width: 0;
    padding: 0 8px;
}

.hotkey {
    padding-left: 20px;
    opacity: 0.6;
    min-width: 90px;
    text-align: right;
}

.v-subheader {
    font-size: 0.75rem;
    height: 24px;
    padding: 0 16px;
    opacity: 0.7;
    text-transform: uppercase;
}

.preview-container {
    max-height: 300px;
    overflow: hidden;
    margin: 16px 0;
}

.v-list-item {
    min-height: 32px !important;
    height: 32px !important;
    display: flex;
    align-items: center;
}

.v-list-item--dense {
    min-height: 32px !important;
    height: 32px !important;
}

.v-list-item__content {
    padding: 4px 0;
    display: flex;
    align-items: center;
}

.v-list-item-icon {
    display: flex;
    align-items: center;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.v-list-item-action {
    display: flex;
    align-items: center;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.v-list-item__icon {
    margin: 8px 0 !important;
}
</style>
<template>
  <div class="workflow-trigger">
    <div class="trigger-content">
      <div class="trigger-header">
        <v-icon small class="mr-2">{{ triggerIcon }}</v-icon>
        <span>{{ triggerTitle }}</span>
      </div>
      <div class="trigger-details" v-if="triggerDetails">
        {{ triggerDetails }}
      </div>
      <div class="trigger-description" v-if="localSettings.description">
        {{ localSettings.description }}
      </div>
    </div>
  </div>
</template>

<script>
import ExtendsComponent from '../../_extends/ObjectComponent';
import { debug } from '@/utils/debug';

const log = debug('TriggerObject:Component');

export default {
  name: 'Workflow_TriggerObject',
  extends: ExtendsComponent,

  created() {
    log('Component created with settings:', this.localSettings);
  },

  computed: {
    triggerIcon() {
      const icons = {
        manual: 'mdi-play-circle-outline',
        scheduled: 'mdi-clock-outline',
        webhook: 'mdi-webhook',
        event: 'mdi-lightning-bolt-outline'
      };
      const icon = icons[this.localSettings.triggerType] || icons.manual;
      log('Computed trigger icon:', icon, 'for type:', this.localSettings.triggerType);
      return icon;
    },

    triggerTitle() {
      const titles = {
        manual: 'Manual Trigger',
        scheduled: 'Scheduled',
        webhook: 'Webhook',
        event: 'Event'
      };
      const title = titles[this.localSettings.triggerType] || 'Trigger';
      log('Computed trigger title:', title);
      return title;
    },

    triggerDetails() {
      let details = '';
      switch (this.localSettings.triggerType) {
        case 'scheduled':
          details = this.localSettings.schedule;
          break;
        case 'webhook':
          details = this.localSettings.webhookPath;
          break;
        case 'event':
          details = this.localSettings.eventName;
          break;
      }
      log('Computed trigger details:', details);
      return details;
    }
  },

  watch: {
    'localSettings': {
      deep: true,
      handler(newVal) {
        log('Settings changed:', newVal);
      }
    }
  }
};
</script>

<style scoped>
.workflow-trigger {
  border: 2px solid #4CAF50;
  padding: 8px;
  min-width: 200px;
  border-radius: 5px;
  cursor: default;
  background: white;
}

.trigger-content {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.trigger-header {
  display: flex;
  align-items: center;
  font-weight: 500;
}

.trigger-details {
  font-size: 0.9em;
  color: #666;
}

.trigger-description {
  font-size: 0.8em;
  color: #999;
  font-style: italic;
}
</style> 
<template>
  <v-toolbar dense no-padding>
    <v-btn-toggle group borderless v-model="openedData">
      <v-btn text style="opacity: 1" class="ma-0" value="variables">
        Variables
      </v-btn>

      <v-divider vertical />

      <v-btn text style="opacity: 1" class="ma-0" value="config">
        Configuration
      </v-btn>
    </v-btn-toggle>

    <v-divider vertical />

    <slot name="menumore" />
  </v-toolbar>
</template>

<script>
import Vue from 'vue';
import ObjectPropertyEditorExtend from '../../_extends/ObjectPropertyEditor';

export default {
  name: 'Workflow_StateObjectPropertyEditor',
  extends: ObjectPropertyEditorExtend,

  data() {
    const cmpVariables = Vue.extend({
      template: `
        <div>
          <v-textarea
            v-model="variablesString"
            label="State Variables"
            :readonly="readonly"
            rows="10"
            :error-messages="errorMessage"
            hint="JSON format: { 'variableName': 'defaultValue' }"
            persistent-hint
            @input="validateAndUpdate"
          />
          <v-alert
            v-if="!errorMessage"
            type="info"
            text
            dense
            class="mt-4"
          >
            Variables can be accessed in other workflow components using:
            <code class="ml-2">state.variableName</code>
          </v-alert>
        </div>
      `,
      props: ['settings', 'readonly'],
      data() {
        return {
          variablesString: JSON.stringify(JSON.parse(this.settings.variables || '{}'), null, 2),
          errorMessage: ''
        };
      },
      methods: {
        validateAndUpdate(value) {
          try {
            JSON.parse(value);
            this.settings.variables = value;
            this.errorMessage = '';
          } catch (e) {
            this.errorMessage = 'Invalid JSON format';
          }
        }
      }
    });

    const cmpConfig = Vue.extend({
      template: `
        <div>
          <v-select
            v-model="settings.persistence"
            :items="persistenceTypes"
            label="State Persistence"
            :readonly="readonly"
            hint="How long should the state be maintained"
            persistent-hint
          />
          
          <v-select
            v-model="settings.scope"
            :items="scopeTypes"
            label="State Scope"
            :readonly="readonly"
            class="mt-4"
            hint="Where can this state be accessed"
            persistent-hint
          />

          <v-alert
            type="info"
            text
            dense
            class="mt-4"
          >
            <strong>Persistence Types:</strong>
            <ul class="mt-2">
              <li><strong>Session:</strong> State persists until workflow completion</li>
              <li><strong>Persistent:</strong> State persists across workflow runs</li>
            </ul>
            
            <strong class="mt-4 d-block">Scope Types:</strong>
            <ul class="mt-2">
              <li><strong>Local:</strong> State only accessible within this workflow</li>
              <li><strong>Global:</strong> State accessible across all workflows</li>
            </ul>
          </v-alert>
        </div>
      `,
      props: ['settings', 'readonly'],
      data() {
        return {
          persistenceTypes: [
            { text: 'Session Only', value: 'session' },
            { text: 'Persistent', value: 'persistent' }
          ],
          scopeTypes: [
            { text: 'Local to Workflow', value: 'local' },
            { text: 'Global', value: 'global' }
          ]
        };
      }
    });

    return {
      cmpVariables,
      cmpConfig,
      openedData: null
    };
  },

  methods: {
    onOpenVariables() {
      this.$root.$emit('DataEditor.open', {
        component: this.cmpVariables,
        props: {
          settings: this.localSettings,
          readonly: this.readonly
        },
        title: 'State Variables'
      });
    },

    onOpenConfig() {
      this.$root.$emit('DataEditor.open', {
        component: this.cmpConfig,
        props: {
          settings: this.localSettings,
          readonly: this.readonly
        },
        title: 'State Configuration'
      });
    }
  },

  watch: {
    openedData(value) {
      switch (value) {
        case 'variables':
          this.onOpenVariables();
          break;
        case 'config':
          this.onOpenConfig();
          break;
        default:
          this.$root.$emit('DataEditor.close');
      }
    }
  },

  mounted() {
    this.$root.$on('DataEditor.close', this.onDataEditorClose);
    this.onOpenVariables();
  },

  beforeDestroy() {
    this.$root.$off('DataEditor.close', this.onDataEditorClose);
  }
};
</script> 
const defaultSettings = {
  name: "API Call",
  icon: 'mdi-earth',
  showLabel: true,
  size: {
    width: 'auto',
    height: 40
  },
  resizable: false,
  draggable: true,
  has: {
    in: true,
    out: true,
    settings: true
  },
  inColor: "#FF9800",  // Orange for API calls
  outColor: "#FF9800",
  layout: "horizontal",
  components: [/Workflow_.*/],
  openPropertyEditorByClick: true,
  settings: {
    locked: false,
    order: 0,
    method: 'GET',
    url: '',
    headers: {},
    body: '',
    authentication: {
      type: 'none', // none, basic, bearer, custom
      username: '',
      password: '',
      token: '',
      custom: {}
    },
    retryConfig: {
      enabled: false,
      maxAttempts: 3,
      backoffType: 'fixed', // fixed, exponential
      backoffDelay: 1000
    },
    timeout: 30000, // 30 seconds default
    validateResponse: true,
    expectedStatus: [200]
  }
};

export default defaultSettings; 
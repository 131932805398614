<template>
  <div class="workflow-filter">
    <div class="filter-content">
      <div class="filter-header">
        <v-icon small class="mr-2">mdi-filter-variant</v-icon>
        <span>Filter</span>
      </div>
      <div class="filter-condition" v-if="localSettings.condition">
        <code>{{ displayCondition }}</code>
      </div>
      <div class="filter-routes">
        <div class="route-item true-route">
          <v-icon x-small class="mr-1">mdi-check-circle</v-icon>
          {{ localSettings.trueRoute || 'True' }}
        </div>
        <div class="route-item false-route">
          <v-icon x-small class="mr-1">mdi-close-circle</v-icon>
          {{ localSettings.falseRoute || 'False' }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ExtendsComponent from '../../_extends/ObjectComponent';

export default {
  name: 'Workflow_FilterObject',
  extends: ExtendsComponent,
  computed: {
    displayCondition() {
      const condition = this.localSettings.condition;
      if (!condition) return 'No condition set';
      return condition.length > 40 ? condition.substring(0, 37) + '...' : condition;
    }
  }
};
</script>

<style scoped>
.workflow-filter {
  border: 2px solid #9C27B0;
  padding: 8px;
  min-width: 200px;
  border-radius: 5px;
  cursor: default;
  background: white;
}

.filter-content {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.filter-header {
  display: flex;
  align-items: center;
  font-weight: 500;
}

.filter-condition {
  background: rgba(156, 39, 176, 0.1);
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.9em;
  margin-top: 4px;
}

.filter-condition code {
  color: #9C27B0;
}

.filter-routes {
  display: flex;
  gap: 8px;
  margin-top: 4px;
}

.route-item {
  display: flex;
  align-items: center;
  font-size: 0.8em;
  padding: 2px 6px;
  border-radius: 4px;
}

.true-route {
  background: rgba(76, 175, 80, 0.1);
  color: #4CAF50;
}

.false-route {
  background: rgba(244, 67, 54, 0.1);
  color: #F44336;
}
</style> 
import { User } from "@/resources";
import {deepClone, HFSA_THRESHOLD_DEFAULT} from "@/lib";

const store = {
  namespaced: true,
  state: {
    tryLoad: false,
    current: null,
    token: localStorage["token"]
  },
  mutations: {
    set(state, user) {
      if (!user) {
        state.current = null;
        return;
      }

      if (state.current?.profile && user) {
        state.current = {
          ...user,
          profile: {
            ...state.current.profile,
            ...user.profile
          }
        };
      } else {
        state.current = {
          ...user,
          profile: user.profile || {}
        };
      }
    },
    setTryLoad (state, tryLoad) {
      state.tryLoad = tryLoad;
    },
    setToken(state, token) {
      state.token = token;
      localStorage["token"] = token;
    },
    deleteToken(state) {
      state.token = null;
      delete localStorage["token"];
    },
  },
  actions: {
    async load({dispatch, commit, state}) {
      if (state.tryLoad) return;
      
      commit("setTryLoad", true);
      commit('application/setLoading', true, {root: true});

      try {
        const response = await User.current();
        if (!response.body) {
          throw new Error('No user data received');
        }
        
        const userData = deepClone(response.body);
        
        if (!userData.profile) {
          userData.profile = {};
        }
        
        await dispatch('set', userData);
        return userData;
      } catch (error) {
        console.error('Failed to load user:', error);
        if (error.response?.status === 401) {
          dispatch('logOut');
        }
        throw error;
      } finally {
        commit("setTryLoad", false);
        commit('application/setLoading', false, {root: true});
      }
    },

    set({ commit, state }, user) {
      if (state.current?.profile && user) {
        user = {
          ...user,
          profile: {
            ...state.current.profile,
            ...user.profile
          }
        };
      }

      commit("set", user);
      
      if (user) {
        try {
          this._vm.$intercom.boot({
            user_id: user.id,
            name: user.username,
            first_name: user.profile?.first_name || '',
            last_name: user.profile?.last_name || '',
            email: user.email,
            organisations: user.organisations?.length || 0,
            teams: user.teams?.length || 0,
            admin: !!user.roles?.admin
          });
        } catch (error) {
          console.error('Intercom boot failed:', error);
        }
      }
    },

    async update({ commit, state }, params) {
      try {
        const previousProfile = state.current?.profile ? deepClone(state.current.profile) : {};
        
        const { body } = await User.update({ id: params.id }, { user: params });
        
        if (!body.profile) {
          body.profile = previousProfile;
        }
        
        commit("set", body);
        return body;
      } catch (error) {
        console.error('Failed to update user:', error);
        throw error;
      }
    },

    logOut({commit}) {
      commit('deleteToken');
      commit('set', null);
    }
  },
  getters: {
    current(state) {
      return state.current;
    },
    token(state) {
      return state.token;
    },
    visibleName(state) {
      if (!state.current) return '';
      
      const profile = state.current.profile || {};
      
      if (profile.first_name && profile.last_name) {
        return `${profile.first_name} ${profile.last_name}`;
      } else if (profile.first_name) {
        return profile.first_name;
      } else if (profile.last_name) {
        return profile.last_name;
      }

      return state.current.username || state.current.email || '';
    }
  }
};

export default store;

<template>
  <v-toolbar dense no-padding>
    <v-btn-toggle group borderless v-model="openedData">
      <v-btn text style="opacity: 1" class="ma-0" value="retry">
        Retry
      </v-btn>

      <v-divider vertical />

      <v-btn text style="opacity: 1" class="ma-0" value="fallback">
        Fallback
      </v-btn>

      <v-divider vertical />

      <v-btn text style="opacity: 1" class="ma-0" value="logging">
        Logging
      </v-btn>
    </v-btn-toggle>

    <v-divider vertical />

    <slot name="menumore" />
  </v-toolbar>
</template>

<script>
import Vue from 'vue';
import ObjectPropertyEditorExtend from '../../_extends/ObjectPropertyEditor';

export default {
  name: 'Workflow_ErrorHandlerObjectPropertyEditor',
  extends: ObjectPropertyEditorExtend,

  data() {
    const cmpRetry = Vue.extend({
      template: `
        <div>
          <v-switch
            v-model="settings.retryConfig.enabled"
            label="Enable Retry"
            :readonly="readonly"
          />
          
          <template v-if="settings.retryConfig.enabled">
            <v-text-field
              v-model.number="settings.retryConfig.maxAttempts"
              label="Max Attempts"
              type="number"
              :readonly="readonly"
              min="1"
              max="10"
              hint="Maximum number of retry attempts"
              persistent-hint
            />
            
            <v-select
              v-model="settings.retryConfig.backoffType"
              :items="backoffTypes"
              label="Backoff Type"
              :readonly="readonly"
              hint="How to space out retry attempts"
              persistent-hint
            />
            
            <v-text-field
              v-model.number="settings.retryConfig.backoffDelay"
              label="Initial Delay (ms)"
              type="number"
              :readonly="readonly"
              min="100"
              hint="Delay before first retry attempt"
              persistent-hint
            />

            <div class="mt-4 text-caption">
              <strong>Retry Pattern:</strong>
              <div v-for="(delay, index) in retryDelays" :key="index">
                Attempt {{ index + 1 }}: {{ delay }}ms
              </div>
            </div>
          </template>
        </div>
      `,
      props: ['settings', 'readonly'],
      data() {
        return {
          backoffTypes: [
            { text: 'Fixed', value: 'fixed' },
            { text: 'Exponential', value: 'exponential' }
          ]
        };
      },
      computed: {
        retryDelays() {
          const delays = [];
          const baseDelay = this.settings.retryConfig.backoffDelay;
          
          for (let i = 0; i < this.settings.retryConfig.maxAttempts; i++) {
            if (this.settings.retryConfig.backoffType === 'exponential') {
              delays.push(baseDelay * Math.pow(2, i));
            } else {
              delays.push(baseDelay);
            }
          }
          
          return delays;
        }
      }
    });

    const cmpFallback = Vue.extend({
      template: `
        <div>
          <v-textarea
            v-model="settings.fallbackAction"
            label="Fallback Action"
            :readonly="readonly"
            rows="4"
            hint="JavaScript code to execute as fallback"
            persistent-hint
          />
          <v-alert
            v-if="settings.fallbackAction"
            type="info"
            text
            dense
            class="mt-4"
          >
            Fallback action will execute after all retries are exhausted
          </v-alert>
        </div>
      `,
      props: ['settings', 'readonly']
    });

    const cmpLogging = Vue.extend({
      template: `
        <div>
          <v-switch
            v-model="settings.logErrors"
            label="Enable Error Logging"
            :readonly="readonly"
            hint="Log all errors to the workflow history"
            persistent-hint
          />
          
          <v-alert
            v-if="settings.logErrors"
            type="info"
            text
            dense
            class="mt-4"
          >
            Errors will be logged with:
            <ul class="mt-2">
              <li>Timestamp</li>
              <li>Error message and stack trace</li>
              <li>Current workflow state</li>
              <li>Retry attempt number (if applicable)</li>
            </ul>
          </v-alert>
        </div>
      `,
      props: ['settings', 'readonly']
    });

    return {
      cmpRetry,
      cmpFallback,
      cmpLogging,
      openedData: null
    };
  },

  methods: {
    onOpenRetry() {
      this.$root.$emit('DataEditor.open', {
        component: this.cmpRetry,
        props: {
          settings: this.localSettings,
          readonly: this.readonly
        },
        title: 'Retry Configuration'
      });
    },

    onOpenFallback() {
      this.$root.$emit('DataEditor.open', {
        component: this.cmpFallback,
        props: {
          settings: this.localSettings,
          readonly: this.readonly
        },
        title: 'Fallback Action'
      });
    },

    onOpenLogging() {
      this.$root.$emit('DataEditor.open', {
        component: this.cmpLogging,
        props: {
          settings: this.localSettings,
          readonly: this.readonly
        },
        title: 'Error Logging'
      });
    }
  },

  watch: {
    openedData(value) {
      switch (value) {
        case 'retry':
          this.onOpenRetry();
          break;
        case 'fallback':
          this.onOpenFallback();
          break;
        case 'logging':
          this.onOpenLogging();
          break;
        default:
          this.$root.$emit('DataEditor.close');
      }
    }
  },

  mounted() {
    this.$root.$on('DataEditor.close', this.onDataEditorClose);
    this.onOpenRetry();
  },

  beforeDestroy() {
    this.$root.$off('DataEditor.close', this.onDataEditorClose);
  }
};
</script> 
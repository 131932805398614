import { parseClipboardContent } from './utils';
import { PositionManager } from './utils';
import { handleImagePaste, handleHtmlPaste, handleTextPaste, handleJsonPaste, handleLinkPaste } from './handlers';

export async function handlePaste(clipboardItems, translate, scale, viewport, mouseCoordinates) {
  const positionManager = new PositionManager(translate, scale, viewport, mouseCoordinates);
  const position = positionManager.calculateFinalPosition();
  const content = await parseClipboardContent(clipboardItems);

  if (!content) return;

  switch (content.type) {
    case 'image':
      await handleImagePaste(content.data, position);
      break;
    case 'html':
      await handleHtmlPaste(content.data, position);
      break;
    case 'text':
      await handleTextPaste(content.data, position);
      break;
    case 'json':
      await handleJsonPaste(content.data, position);
      break;
    case 'link':
      await handleLinkPaste(content.data, position);
      break;
  }
}
const APPLICATION_ID =
  process.env.NODE_ENV === 'production'
    ? process.env.VUE_APP_APPLICATION_ID
    : process.env.VUE_APP_APPLICATION_ID                                                                                                                                     

const APPLICATION_SECRET =
  process.env.NODE_ENV === 'production'
    ? process.env.VUE_APP_APPLICATION_SECRET
    : process.env.VUE_APP_APPLICATION_SECRET

const ROOT_URL =
  process.env.NODE_ENV === 'production'
    ? 'https://developers.beach.io'
    : process.env.VUE_APP_ROOT_URL

const WEBSOCKETS_URL =
  process.env.NODE_ENV === 'production'
    ? 'wss://developers.beach.io/cable'
    : process.env.VUE_APP_WEBSOCKETS_URL

const NETLIFY_CLIENT_ID = process.env.VUE_APP_NETLIFY_CLIENT_ID
const GITHUB_CLIENT_ID = process.env.VUE_APP_GITHUB_CLIENT_ID
const GITHUB_CLIENT_SECRET = process.env.VUE_APP_GITHUB_CLIENT_SECRET

const PARSE_APP_ID = process.env.VUE_APP_PARSE_APP_ID;
const PARSE_SERVER_URL = process.env.VUE_APP_PARSE_SERVER_URL;

const MURAL_CLIENT_ID = process.env.VUE_APP_MURAL_CLIENT_ID;
const MURAL_CLIENT_SECRET = process.env.VUE_APP_MURAL_CLIENT_SECRET;

export { 
  APPLICATION_ID, APPLICATION_SECRET, ROOT_URL, WEBSOCKETS_URL, NETLIFY_CLIENT_ID, GITHUB_CLIENT_ID, GITHUB_CLIENT_SECRET,  
  PARSE_APP_ID, PARSE_SERVER_URL, MURAL_CLIENT_ID, MURAL_CLIENT_SECRET
};

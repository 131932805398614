<template>
  <div class="workflow-error-handler">
    <div class="error-handler-content">
      <div class="error-handler-header">
        <v-icon small class="mr-2">mdi-alert-circle-outline</v-icon>
        <span>Error Handler</span>
      </div>
      <div class="error-handler-details">
        <div class="detail-item">
          <v-icon x-small class="mr-1">mdi-refresh</v-icon>
          {{ retryText }}
        </div>
        <div v-if="localSettings.logErrors" class="detail-item">
          <v-icon x-small class="mr-1">mdi-text-box-search-outline</v-icon>
          Logging enabled
        </div>
        <div v-if="localSettings.fallbackAction" class="detail-item">
          <v-icon x-small class="mr-1">mdi-call-split</v-icon>
          Has fallback
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ExtendsComponent from '../../_extends/ObjectComponent';

export default {
  name: 'Workflow_ErrorHandlerObject',
  extends: ExtendsComponent,
  computed: {
    retryText() {
      if (!this.localSettings.retryConfig.enabled) {
        return 'No retry';
      }
      return `Retry ${this.localSettings.retryConfig.maxAttempts}x (${this.localSettings.retryConfig.backoffType})`;
    }
  }
};
</script>

<style scoped>
.workflow-error-handler {
  border: 2px solid #F44336;
  padding: 8px;
  min-width: 200px;
  border-radius: 5px;
  cursor: default;
  background: white;
}

.error-handler-content {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.error-handler-header {
  display: flex;
  align-items: center;
  font-weight: 500;
}

.error-handler-details {
  display: flex;
  gap: 12px;
  font-size: 0.8em;
  color: #666;
  margin-top: 4px;
  flex-wrap: wrap;
}

.detail-item {
  display: flex;
  align-items: center;
  background: rgba(244, 67, 54, 0.1);
  padding: 2px 6px;
  border-radius: 4px;
}
</style> 
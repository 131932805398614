<template>
  <v-toolbar dense no-padding>
    <v-btn-toggle group borderless v-model="openedData">
      <v-btn text style="opacity: 1" class="ma-0" value="condition">
        Condition
      </v-btn>

      <v-divider vertical />

      <v-btn text style="opacity: 1" class="ma-0" value="routes">
        Routes
      </v-btn>
    </v-btn-toggle>

    <v-divider vertical />

    <slot name="menumore" />
  </v-toolbar>
</template>

<script>
import Vue from 'vue';
import ObjectPropertyEditorExtend from '../../_extends/ObjectPropertyEditor';

export default {
  name: 'Workflow_FilterObjectPropertyEditor',
  extends: ObjectPropertyEditorExtend,

  data() {
    const cmpCondition = Vue.extend({
      template: `
        <div>
          <v-textarea
            v-model="settings.condition"
            label="Filter Condition"
            :readonly="readonly"
            rows="5"
            hint="JavaScript expression that evaluates to true/false"
            persistent-hint
          />
          
          <v-alert
            type="info"
            text
            dense
            class="mt-4"
          >
            <strong>Example Conditions:</strong>
            <ul class="mt-2">
              <li><code>data.status === 'active'</code></li>
              <li><code>response.count > 10</code></li>
              <li><code>state.user.role === 'admin'</code></li>
            </ul>

            <strong class="mt-4 d-block">Available Context:</strong>
            <ul class="mt-2">
              <li><code>data</code> - Input data</li>
              <li><code>state</code> - Workflow state</li>
              <li><code>response</code> - Previous node response</li>
            </ul>
          </v-alert>
        </div>
      `,
      props: ['settings', 'readonly']
    });

    const cmpRoutes = Vue.extend({
      template: `
        <div>
          <v-text-field
            v-model="settings.trueRoute"
            label="True Route"
            :readonly="readonly"
            hint="Route name for true condition"
            persistent-hint
            prepend-inner-icon="mdi-check-circle"
            class="mb-4"
          />
          
          <v-text-field
            v-model="settings.falseRoute"
            label="False Route"
            :readonly="readonly"
            hint="Route name for false condition"
            persistent-hint
            prepend-inner-icon="mdi-close-circle"
          />
          
          <v-alert
            type="info"
            text
            dense
            class="mt-4"
          >
            <strong>Route Configuration:</strong>
            <ul class="mt-2">
              <li>Routes define the path for each condition outcome</li>
              <li>Leave empty to use default 'True' and 'False' routes</li>
              <li>Routes can be referenced in connecting nodes</li>
            </ul>
          </v-alert>
        </div>
      `,
      props: ['settings', 'readonly']
    });

    return {
      cmpCondition,
      cmpRoutes,
      openedData: null
    };
  },

  methods: {
    onOpenCondition() {
      this.$root.$emit('DataEditor.open', {
        component: this.cmpCondition,
        props: {
          settings: this.localSettings,
          readonly: this.readonly
        },
        title: 'Filter Condition'
      });
    },

    onOpenRoutes() {
      this.$root.$emit('DataEditor.open', {
        component: this.cmpRoutes,
        props: {
          settings: this.localSettings,
          readonly: this.readonly
        },
        title: 'Route Configuration'
      });
    }
  },

  watch: {
    openedData(value) {
      switch (value) {
        case 'condition':
          this.onOpenCondition();
          break;
        case 'routes':
          this.onOpenRoutes();
          break;
        default:
          this.$root.$emit('DataEditor.close');
      }
    }
  },

  mounted() {
    this.$root.$on('DataEditor.close', this.onDataEditorClose);
    this.onOpenCondition();
  },

  beforeDestroy() {
    this.$root.$off('DataEditor.close', this.onDataEditorClose);
  }
};
</script> 
const defaultSettings = {
  name: "State Manager",
  icon: 'mdi-database',
  showLabel: true,
  size: {
    width: 'auto',
    height: 70
  },
  resizable: false,
  draggable: true,
  has: {
    in: true,
    out: true,
    settings: true
  },
  inColor: "#009688",  // Teal for state management
  outColor: "#009688",
  layout: "horizontal",
  components: [/Workflow_.*/],
  openPropertyEditorByClick: true,
  settings: {
    locked: false,
    order: 0,
    variables: '{}', // JSON representation of variables
    persistence: 'session', // session, persistent
    scope: 'global' // global, local
  }
};

export default defaultSettings; 
<template>
  <v-card class="navigation-control">
    <div class="minimap" v-if="activeChart.options && activeChart.options.minimapEnabled">
      <ViewportPreview
          :settings="{background: 'rgba(241,183,235,0.69)'}"
          :width="297"
          :objects="objects"
          :connections="connections"
          :allowedObjectTypes="allowedObjectTypes"
          :viewport="viewport"
      />
    </div>
    <v-card-actions class="d-flex flex-row">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              :color="fullscreen ? 'primary' : null"
              icon
              v-bind="attrs"
              v-on="on"
              @click="toggleFullscreen"
          >
            <v-icon>mdi-fullscreen</v-icon>
          </v-btn>
        </template>
        <span>Fullscreen</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              :color="activeChart.options && activeChart.options.minimapEnabled ? 'primary' : null"
              icon
              v-bind="attrs"
              v-on="on"
              @click="toggleMinimap"
          >
            <v-icon>mdi-map-outline</v-icon>
          </v-btn>
        </template>
        <span>Show Minimap</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              :color="activeChart.options && activeChart.options.cursorsEnabled ? 'primary' : null"
              icon
              v-bind="attrs"
              v-on="on"
              @click="toggleCursors"
          >
            <v-icon>mouse</v-icon>
          </v-btn>
        </template>
        <span>Show Cursors</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{on, attrs}">
          <v-btn
              :color="specialKey === 'space' ? 'primary' : null"
              icon
              v-bind="attrs"
              v-on="on"
              @click="toggleMoveAround">
            <v-icon>pan_tool</v-icon>
          </v-btn>
        </template>
        <span>Pan</span>
      </v-tooltip>
      <v-spacer/>
      <v-divider vertical class="mx-2"/>
      <v-spacer/>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              color="primary"
              icon
              v-bind="attrs"
              v-on="on"
              @click="zoomOut"
          >
            <v-icon>mdi-minus</v-icon>
          </v-btn>
        </template>
        <span>Zoom Out</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              color="primary"
              text
              v-bind="attrs"
              v-on="on"
              @click="resetScale"
              class="zoom-text-btn px-0"
          >
            {{ Math.round(activeChart.options.scale * 100) }} %
          </v-btn>
        </template>
        <span>Zoom to 100%</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              color="primary"
              icon
              v-bind="attrs"
              v-on="on"
              @click="zoomIn"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>Zoom In</span>
      </v-tooltip>
    </v-card-actions>
  </v-card>
</template>

<script>
import {mapGetters, mapState} from "vuex";

import {deepClone} from "@/lib";
import {MIN_SCALE, MAX_SCALE} from '@/components/Layout/Authorized/TheEditor/Viewport';

const SCALE_COEFF = 1.25;

export default {
  name: "NavigationControl",
  props: {
    viewport: Object
  },
  mounted() {
    window.addEventListener("fullscreenchange", this.updateFullscreen);
  },
  beforeDestroy() {
    window.removeEventListener("fullscreenchange", this.updateFullscreen);
  },
  data() {
    return {
      fullscreen: false
    };
  },
  computed: {
    ...mapState({
      allowedObjectTypes: state => state.chart.allowedObjectTypes,
      scale: state => state.chart.scale,
      translate: state => state.chart.translate,
      connections: state => state.connection.list,
    }),
    ...mapGetters({
      objects: "object/list",
      specialKey: 'chart/specialKey'
    })
  },
  methods: {
    toggleMinimap() {
      const activeChart = deepClone(this.activeChart);
      activeChart.options.minimapEnabled = !activeChart.options.minimapEnabled;
      this.$store.dispatch("chart/update", activeChart);
    },
    toggleCursors() {
      const activeChart = deepClone(this.activeChart);
      activeChart.options.cursorsEnabled = !activeChart.options.cursorsEnabled;
      this.$store.dispatch("chart/update", activeChart);
    },
    toggleMoveAround() {
      this.$root.$emit('grabMode', {activate: this.specialKey != 'space'})
    },
    toggleFullscreen() {
      const elem = document.documentElement;
      if (this.fullscreen) { // exit full screen
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitExitFullscreen) { /* Safari */
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) { /* IE11 */
          document.msExitFullscreen();
        }
      } else { // enter full screen
        if (elem.requestFullscreen) {
          elem.requestFullscreen();
        } else if (elem.webkitRequestFullscreen) { /* Safari */
          elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) { /* IE11 */
          elem.msRequestFullscreen();
        }
      }
    },
    updateFullscreen() {
      this.fullscreen = !!document.fullscreenElement;
    },
    resetScale() {
      this.setScale(1);
    },
    zoomIn() {
      const scale = Math.min(this.scale * SCALE_COEFF, MAX_SCALE);
      this.setScale(scale);
    },
    zoomOut() {
      const scale = Math.max(this.scale / 1.1, MIN_SCALE);
      this.setScale(scale);
    },
    setScale(scale) {
      const rect = this.viewport.$el.getBoundingClientRect();

      const x = (-this.translate.x + rect.width / 2) / this.scale;
      const y = (-this.translate.y + rect.height / 2) / this.scale;

      this.$store.dispatch("chart/setScale", scale);

      this.$store.commit("chart/setTranslate", {
        x: -x * this.scale + rect.width / 2,
        y: -y * this.scale + rect.height / 2,
      });
    }
  }
  ,
}
;
</script>

<style scoped>
.navigation-control {
  position: absolute;
  bottom: 20px;
  right: 20px;
  z-index: 7;
  border-radius: 8px !important;
  box-shadow: 0 4px 4px -1px rgba(0, 0, 0, 0.2) !important;
}

.zoom-text-btn {
  padding-bottom: 1px !important;
  min-width: 48px !important;
}
</style>

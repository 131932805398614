<template>
  <v-toolbar dense no-padding>
    <v-btn
      class="toolbar-button px-3"
      v-for="item in availableControls[type]"
      :key="item"
      @click="add(item)"
      text
    >
      {{item}}
    </v-btn>
  </v-toolbar>
</template>

<script>
export default {
  name: "ControlPicker",
  props: {
    type: String,
    customComponent: Boolean,
    controls: Array,
    callback: Function
  },
  data() {
    const body = ["CheckboxList", "RadioList", "Image", "Video"];
    if (this.customComponent)
      body.push("CustomComponent");

    return {
      localOpened: this.opened,
      availableControls: {
        body,
        actions: ["Button", "Select", "Input"]
      }
    };
  },
  methods: {
    add(item) {
      this.callback({
        id: this.guid(),
        type: `ControlElement_${item}`,
        name: item,
        settings: {
          _new: true
        }
      });
    }
  }
};
</script>
<template>
  <div class="workflow-loop">
    <div class="loop-content">
      <div class="loop-header">
        <v-icon small class="mr-2">mdi-repeat</v-icon>
        <span>Loop</span>
      </div>
      <div class="loop-details">
        <div class="detail-item">
          <v-icon x-small class="mr-1">mdi-format-list-bulleted</v-icon>
          {{ loopTypeText }}
        </div>
        <div v-if="localSettings.loopType === 'batch'" class="detail-item">
          <v-icon x-small class="mr-1">mdi-package-variant-closed</v-icon>
          Batch Size: {{ localSettings.batchSize }}
        </div>
        <div v-if="localSettings.pagination.enabled" class="detail-item">
          <v-icon x-small class="mr-1">mdi-book-open-page-variant</v-icon>
          Page Size: {{ localSettings.pagination.pageSize }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ExtendsComponent from '../../_extends/ObjectComponent';

export default {
  name: 'Workflow_LoopObject',
  extends: ExtendsComponent,
  computed: {
    loopTypeText() {
      switch (this.localSettings.loopType) {
        case 'forEach':
          return 'For Each Item';
        case 'batch':
          return 'Batch Processing';
        case 'pagination':
          return 'Pagination';
        default:
          return 'Loop';
      }
    }
  }
};
</script>

<style scoped>
.workflow-loop {
  border: 2px solid #FF5722;
  padding: 8px;
  min-width: 200px;
  border-radius: 5px;
  cursor: default;
  background: white;
}

.loop-content {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.loop-header {
  display: flex;
  align-items: center;
  font-weight: 500;
}

.loop-details {
  display: flex;
  gap: 12px;
  font-size: 0.8em;
  color: #666;
  margin-top: 4px;
  flex-wrap: wrap;
}

.detail-item {
  display: flex;
  align-items: center;
  background: rgba(255, 87, 34, 0.1);
  padding: 2px 6px;
  border-radius: 4px;
}
</style> 
<template>
  <div class="workflow-cloud-function">
    <!-- Header -->
    <div class="function-header">
      <div class="function-name">{{ localSettings.functionName || 'exampleFunction1' }}</div>
      <div class="http-method">POST</div>
    </div>

    <!-- Body -->
    <div class="function-body">
      <!-- Left side parameters -->
      <div class="parameters input-params">
        <div v-for="key in inputKeys" 
             :key="'input-' + key" 
             class="param-item input">
          {{ key }}
        </div>
      </div>

      <!-- Divider -->
      <div class="divider"></div>

      <!-- Right side response -->
      <div class="parameters output-params">
        <div v-for="(field, name) in responseFields" 
             :key="'output-' + name" 
             class="param-item">
          <span class="param-name">{{ name }}</span>
          <span class="param-type">{{ field.type }}</span>
        </div>
      </div>
    </div>

    <!-- Footer -->
    <div class="function-footer" v-if="showFooter">
      <div class="footer-info">
        <div class="auth-info" v-if="authInfo.length">
          <v-icon x-small class="mr-1">mdi-shield-key</v-icon>
          {{ authInfo.join(' + ') }}
        </div>
        <div class="retry-info" v-if="localSettings.retryConfig && localSettings.retryConfig.enabled">
          <v-icon x-small class="mr-1">mdi-refresh</v-icon>
          Retry: {{ localSettings.retryConfig.maxAttempts }}x
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ExtendsComponent from '../../_extends/ObjectComponent';

export default {
  name: 'Workflow_CloudFunctionObject',
  extends: ExtendsComponent,
  props: {
    settings: {
      type: Object,
      required: true
    }
  },
  watch: {
    'settings.arguments': {
      immediate: true,
      handler(newVal) {
        try {
          const args = typeof newVal === 'string' ? JSON.parse(newVal) : newVal;
          // Update input ports based on argument keys
          this.updateInputPorts(Object.keys(args));
        } catch (e) {
          console.error('Failed to parse arguments:', e);
        }
      }
    }
  },
  computed: {
    inputKeys() {
      try {
        const args = typeof this.localSettings.arguments === 'string' 
          ? JSON.parse(this.localSettings.arguments) 
          : this.localSettings.arguments;
        return Object.keys(args || {});
      } catch (e) {
        console.error('Failed to parse arguments:', e);
        return [];
      }
    },
    responseFields() {
      try {
        return JSON.parse(this.localSettings.responseSchema || '{}');
      } catch (e) {
        return {};
      }
    },
    authInfo() {
      if (!this.localSettings.auth) return [];
      
      const authLabels = [];
      if (this.localSettings.auth.useAppId) authLabels.push('App ID');
      if (this.localSettings.auth.useJsKey) authLabels.push('JS Key');
      if (this.localSettings.auth.useClientKey) authLabels.push('Client Key');
      if (this.localSettings.auth.useSessionToken) authLabels.push('Session');
      
      return authLabels;
    },
    showFooter() {
      return (this.authInfo.length > 0) || (this.localSettings.retryConfig && this.localSettings.retryConfig.enabled);
    }
  },
  methods: {
    updateInputPorts(keys) {
      // Implementation depends on how ports are managed in your system
      // This is where we'd update the component's input ports
    }
  }
};
</script>

<style scoped>
.workflow-cloud-function {
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  min-width: 400px;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.12);
}

.function-header {
  background: #424242;
  color: white;
  padding: 12px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.function-name {
  font-size: 16px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.9);
}

.http-method {
  background: #B388FF;
  color: white;
  padding: 2px 12px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
}

.function-body {
  background: white;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  padding: 16px 0;
}

.parameters {
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.input-params {
  text-align: left;
}

.output-params {
  text-align: right;
}

.param-item {
  display: flex;
  align-items: center;
  gap: 8px;
}

.param-item.input {
  justify-content: flex-start;
}

.param-name {
  color: #B388FF;
  font-size: 14px;
}

.param-type {
  color: #9E9E9E;
  font-size: 12px;
  font-style: italic;
}

.divider {
  width: 1px;
  background: rgba(0, 0, 0, 0.12);
  margin: 0 8px;
}

.function-footer {
  background: white;
  color: #424242;
  padding: 8px 24px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.footer-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}

.auth-info, .retry-info {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  color: #666;
}

.auth-info {
  color: #B388FF;
}
</style> 
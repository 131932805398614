const defaultSettings = {
  name: "Cloud Function",
  icon: 'mdi-cloud-braces',
  showLabel: true,
  size: {
    width: 'auto',
    height: 120
  },
  resizable: false,
  draggable: true,
  has: {
    in: true,
    out: true,
    settings: true
  },
  inColor: "#3F51B5",  // Indigo for Cloud Functions
  outColor: "#3F51B5",
  layout: "horizontal",
  components: [/Workflow_.*/],
  openPropertyEditorByClick: true,
  settings: {
    locked: false,
    order: 0,
    functionName: '',
    isForgeImport: false,
    arguments: '{}',
    retryConfig: {
      enabled: false,
      maxAttempts: 3,
      backoffType: 'fixed',
      backoffDelay: 1000
    }
  }
};

export default defaultSettings; 
<template>
  <v-container class="sign-container" container--fluid fill-height>
    <v-layout wrap align-center justify-center>
      <v-flex xs12 md6 lg4 class="sign-flex">
        <img src="../img/vulcan-logo.svg" alt="Vulcan Logo" class="vulcan-logo">
        <div class="sign-wrapper">
          <v-card class="sign-in-up" :loading="loading">
            <div class="welcome">
              <div class="welcome-emoji">🖖</div>
              <div class="welcome-title">{{ btnLabel }}</div>
              <div class="welcome-subtitle">{{ subtitle }}</div>
            </div>
            <slot />
            <slot name="footer" />
          </v-card>
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: "SignFormsWrapper",
  props: {
    loading: Boolean,
    subtitle: String,
    signIn: {
      default: true,
      type: Boolean
    }
  },
  data() {
    return {
    };
  },
  computed: {
    btnLabel() {
      return this.signIn ? 'Welcome Back' : 'Welcome to Vulcan';
    }
  }
};
</script>

<style scoped>
.sign-container {
  padding: 0;
  background: url('../img/auth-bg-1.png') no-repeat fixed left,
    url('../img/auth-bg-2.png') no-repeat fixed right;
  background-size: 30%, 30%;
}

.vulcan-logo {
  width: 113px;
  height: 29px;
  margin: 60px auto 40px;
  display: block;
}

.sign-flex {
  z-index: 1;
}

.sign-wrapper {
  position: relative;
  z-index: 1;
}

.welcome-logo {
  height: 75px;
  width: 75px;
  margin: 20px auto;
  background: white;
  border: 2px solid #ccc;
  font-weight: 800;
  border-radius: 8px 20px 20px 20px;
  color: #313133;
  font-size: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sign-in-up {
  width: 400px;
  margin: auto;
  box-shadow: 0px 20px 20px rgba(0, 0, 0, .12) !important;
  background: white;
  border-radius: 6px 20px 20px 20px !important;
  padding: 30px;
}

.welcome {
  text-align: center;
  margin: 0 0 80px 0;
}

.welcome-title {
  font-size: 30px;
  font-weight: 500;
}

.welcome-subtitle {
  font-size: 16px;
  color: #888484;
}

.welcome-emoji {
  font-size: 48px;
  margin-bottom: 16px;
}

@media screen and (max-width: 600px) {
  .sign-wrapper {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  .sign-in-up {
    margin: 0;
    width: 100%;
    height: 100%;
  }

  .sign-in-up-body {
    height: calc(100% - 51px);
  }
}
</style>
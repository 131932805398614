<template>
  <v-toolbar dense no-padding>
    <v-btn-toggle group borderless v-model="openedData">
      <v-btn text style="opacity: 1" class="ma-0" value="trigger">
        Trigger
      </v-btn>

      <v-divider vertical />

      <v-btn text style="opacity: 1" class="ma-0" value="description">
        Description
      </v-btn>
    </v-btn-toggle>

    <v-divider vertical />

    <slot name="menumore" />
  </v-toolbar>
</template>

<script>
import Vue from 'vue';
import ObjectPropertyEditorExtend from '../../_extends/ObjectPropertyEditor';
import { debug } from '@/utils/debug';
import { debounce } from 'lodash';

const log = debug('TriggerObject:PropertyEditor');

export default {
  name: 'Workflow_TriggerObjectPropertyEditor',
  extends: ObjectPropertyEditorExtend,

  data() {
    // Initialize default settings
    const defaultSettings = {
      triggerType: 'manual',
      schedule: '',
      webhookPath: '',
      eventName: '',
      description: '',
      title: 'unnamed-trigger'
    };

    // Initialize components
    const cmpTrigger = Vue.extend({
      template: `
        <div>
          <v-text-field
            v-model="localTitle"
            label="Trigger Name"
            :readonly="readonly"
            :rules="[v => !!v || 'Name is required']"
            required
            @input="onTitleChangeDebounced"
            class="mb-4"
          />

          <v-select
            v-model="settings.triggerType"
            :items="triggerTypes"
            label="Trigger Type"
            :readonly="readonly"
            @change="onTriggerTypeChange"
          />
          
          <v-text-field
            v-if="settings.triggerType === 'scheduled'"
            v-model="settings.schedule"
            label="Cron Schedule"
            :readonly="readonly"
            hint="e.g., 0 0 * * * (daily at midnight)"
            persistent-hint
            @input="onScheduleChange"
          />
          
          <template v-if="settings.triggerType === 'webhook'">
            <v-text-field
              :value="webhookPath"
              label="Webhook Path"
              readonly
              outlined
              persistent-hint
              hint="This path is automatically generated based on your project structure"
              class="mb-4"
            >
              <template v-slot:prepend>
                <div class="text-subtitle-2 grey--text">
                  /api/webhooks/
                </div>
              </template>
              <template v-slot:append>
                <v-btn
                  icon
                  small
                  @click="copyWebhookPath"
                >
                  <v-icon small>mdi-content-copy</v-icon>
                </v-btn>
              </template>
            </v-text-field>

            <v-alert
              type="info"
              text
              dense
              class="mt-4"
            >
              <div class="text-body-2">
                <v-icon small left>mdi-information</v-icon>
                Webhook path follows the pattern: <code>/api/webhooks/[project]/[chart]/[trigger]</code>
              </div>
            </v-alert>
          </template>
          
          <v-text-field
            v-if="settings.triggerType === 'event'"
            v-model="settings.eventName"
            label="Event Name"
            :readonly="readonly"
            hint="e.g., user.created"
            persistent-hint
            @input="onEventNameChange"
          />
        </div>
      `,
      props: ['settings', 'readonly'],
      data() {
        return {
          triggerTypes: [
            { text: 'Manual Trigger', value: 'manual' },
            { text: 'Scheduled', value: 'scheduled' },
            { text: 'Webhook', value: 'webhook' },
            { text: 'Event', value: 'event' }
          ],
          localTitle: this.settings.title || 'unnamed-trigger',
          onTitleChangeDebounced: debounce(this.onTitleChange, 300)
        };
      },
      computed: {
        webhookPath() {
          const sanitizePath = (str) => {
            return (str || '')
              .toLowerCase()
              .replace(/\s+/g, '-')
              .replace(/[^a-z0-9-]/g, '')
              .replace(/-+/g, '-')
              .replace(/^-+|-+$/g, '');
          };

          const projectName = sanitizePath(this.$store.getters['project/active']?.name || 'default-project');
          const chartName = sanitizePath(this.$store.getters['chart/active']?.name || 'default-chart');
          const triggerTitle = sanitizePath(this.settings?.title || 'unnamed-trigger');
          
          const path = `${projectName}/${chartName}/${triggerTitle}`;
          log('Computed webhook path:', path);
          
          // Emit the computed path back to parent
          this.$emit('update:settings', {
            ...this.settings,
            webhookPath: path
          });

          return path;
        }
      },
      methods: {
        onTriggerTypeChange(value) {
          log('Trigger type changed:', value);
          if (value === 'webhook') {
            // Force webhook path update
            this.$emit('update:settings', {
              ...this.settings,
              webhookPath: this.webhookPath
            });
          }
        },
        onScheduleChange(value) {
          log('Schedule changed:', value);
        },
        onEventNameChange(value) {
          log('Event name changed:', value);
        },
        copyWebhookPath() {
          const fullPath = `/api/webhooks/${this.webhookPath}`;
          navigator.clipboard.writeText(fullPath)
            .then(() => {
              this.$root.$emit('showSnackbar', {
                text: 'Webhook path copied to clipboard',
                color: 'success'
              });
            })
            .catch(err => {
              console.error('Failed to copy webhook path:', err);
              this.$root.$emit('showSnackbar', {
                text: 'Failed to copy webhook path',
                color: 'error'
              });
            });
        },
        onTitleChange(value) {
          // Ensure title is never empty
          const newTitle = value || 'unnamed-trigger';
          this.localTitle = newTitle;
          
          this.$emit('update:settings', {
            ...this.settings,
            title: newTitle
          });
        }
      },
      beforeDestroy() {
        // Cancel any pending debounced calls
        this.onTitleChangeDebounced.cancel();
      },
      watch: {
        'settings.title': {
          immediate: true,
          handler(newValue) {
            if (newValue !== this.localTitle) {
              this.localTitle = newValue;
            }
          }
        }
      }
    });

    const cmpDescription = Vue.extend({
      template: `
        <div>
          <v-textarea
            v-model="settings.description"
            label="Description"
            :readonly="readonly"
            rows="4"
            hint="Describe the purpose of this workflow trigger"
            persistent-hint
            @input="onDescriptionChange"
          />
        </div>
      `,
      props: ['settings', 'readonly'],
      methods: {
        onDescriptionChange(value) {
          log('Description changed:', value);
        }
      }
    });

    return {
      defaultSettings,
      cmpTrigger,
      cmpDescription,
      openedData: null,
      localSettings: this.settings ? { ...defaultSettings, ...this.settings } : defaultSettings
    };
  },

  created() {
    log('PropertyEditor created with settings:', this.localSettings);
  },

  methods: {
    updateSettings(settings) {
      log('Updating settings with:', settings);
      const updatedSettings = {
        ...this.defaultSettings,
        ...this.localSettings,
        ...settings
      };
      log('Final updated settings:', updatedSettings);
      
      // Call the parent's updateSettings if it exists
      if (typeof ObjectPropertyEditorExtend.methods.updateSettings === 'function') {
        ObjectPropertyEditorExtend.methods.updateSettings.call(this, updatedSettings);
      } else {
        // Fallback if parent method doesn't exist
        this.$emit('update:settings', updatedSettings);
      }
    },

    onOpenTrigger() {
      log('Opening trigger settings');
      if (!this.localSettings) {
        this.localSettings = { ...this.defaultSettings };
      }
      
      this.$root.$emit('DataEditor.open', {
        component: this.cmpTrigger,
        props: {
          settings: this.localSettings,
          readonly: this.readonly
        },
        title: 'Trigger Settings'
      });
    },

    onOpenDescription() {
      log('Opening description editor');
      if (!this.localSettings) {
        this.localSettings = { ...this.defaultSettings };
      }

      this.$root.$emit('DataEditor.open', {
        component: this.cmpDescription,
        props: {
          settings: this.localSettings,
          readonly: this.readonly
        },
        title: 'Description'
      });
    },

    onDataEditorClose() {
      log('Data editor closed');
      this.openedData = null;
    }
  },

  watch: {
    settings: {
      immediate: true,
      handler(newSettings) {
        log('External settings changed:', newSettings);
        this.localSettings = { ...this.defaultSettings, ...newSettings };
      }
    },

    localSettings: {
      deep: true,
      handler(newVal) {
        log('Local settings changed:', newVal);
      }
    },

    'localSettings.triggerType': {
      handler(newType) {
        log('Trigger type changed to:', newType);
        if (newType === 'webhook' && this.localSettings) {
          this.updateSettings({
            ...this.localSettings,
            webhookPath: this.computedWebhookPath
          });
        }
      }
    }
  },

  mounted() {
    log('PropertyEditor mounted');
    this.$root.$on('DataEditor.close', this.onDataEditorClose);
    
    // Ensure we have initial settings
    if (!this.localSettings) {
      this.localSettings = { ...this.defaultSettings };
    }
    
    this.onOpenTrigger();
  },

  beforeDestroy() {
    log('PropertyEditor being destroyed');
    this.$root.$off('DataEditor.close', this.onDataEditorClose);
  }
};
</script> 
<template>
    <div class="json-editor">
      <div class="d-flex align-center mb-2">
        <v-spacer></v-spacer>
        <v-btn-toggle v-model="editorMode" mandatory dense>
          <v-btn small value="ui" :disabled="formDisabled">
            <v-icon small left>mdi-form-select</v-icon>
            Form
          </v-btn>
          <v-btn small value="code">
            <v-icon small left>mdi-code-braces</v-icon>
            Code
          </v-btn>
        </v-btn-toggle>
      </div>
  
      <!-- Code Editor Mode -->
      <editor
        v-if="editorMode === 'code'"
        v-model="editorContent"
        @init="initEditor"
        :lang="language"
        theme="chrome"
        width="100%"
        :height="height"
        @change="handleCodeChange"
      ></editor>
  
      <!-- UI Form Mode -->
      <v-card v-else flat>
        <v-card-text class="pa-0">
          <div v-for="(key, index) in keys" :key="index" class="d-flex align-center mb-2">
            <v-text-field
              :value="key"
              label="Key"
              dense
              outlined
              class="mr-2"
              @input="handleKeyChange(index, $event)"
            />
            <v-text-field
              :value="parsedContent[key]"
              label="Value"
              dense
              outlined
              @input="handleValueChange(key, $event)"
            />
          </div>
          <v-btn
            small
            text
            color="primary"
            @click="addNewField"
            v-if="allowNewFields"
          >
            <v-icon small left>mdi-plus</v-icon>
            Add Field
          </v-btn>
        </v-card-text>
      </v-card>
  
      <!-- Error Message -->
      <div class="error-message" v-if="error">
        {{ error }}
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'JsonEditor',
    components: {
      editor: require('vue2-ace-editor')
    },
    props: {
      value: {
        type: [Object, String],
        required: true
      },
      language: {
        type: String,
        default: 'json'
      },
      height: {
        type: String,
        default: '200'
      },
      allowNewFields: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        editorMode: 'ui',
        editorContent: '',
        parsedContent: {},
        keys: [],
        error: '',
        formDisabled: false
      };
    },
    watch: {
      value: {
        immediate: true,
        deep: true,
        handler(newVal) {
          if (JSON.stringify(newVal) !== JSON.stringify(this.parsedContent)) {
            this.updateContent(newVal);
          }
        }
      }
    },
    methods: {
      initEditor(editor) {
        require('brace/ext/language_tools');
        require(`brace/mode/${this.language}`);
        require('brace/theme/chrome');
        
        editor.setOptions({
          enableBasicAutocompletion: true,
          enableLiveAutocompletion: true,
          showPrintMargin: false,
          fontSize: 14,
          wrap: true
        });
      },
      updateContent(value) {
        try {
          if (typeof value === 'string') {
            this.editorContent = value;
            this.parsedContent = JSON.parse(value);
          } else {
            this.editorContent = JSON.stringify(value || {}, null, 2);
            this.parsedContent = { ...(value || {}) };
          }
          this.keys = Object.keys(this.parsedContent);
          this.error = '';
          this.formDisabled = false;
        } catch (e) {
          this.error = 'Invalid format';
          this.formDisabled = true;
        }
      },
      handleCodeChange(value) {
        try {
          const parsed = JSON.parse(value);
          const isFlatStructure = Object.entries(parsed).every(
            ([key, val]) => typeof key === 'string' && 
            (typeof val === 'string' || typeof val === 'number' || typeof val === 'boolean' || val === null)
          );

          if (isFlatStructure) {
            this.parsedContent = parsed;
            this.keys = Object.keys(parsed);
            this.formDisabled = false;
          } else {
            this.formDisabled = true;
          }

          this.$emit('input', parsed);
          this.error = '';
        } catch (e) {
          this.error = 'Invalid JSON format';
          this.formDisabled = true;
        }
      },
      handleKeyChange(index, newKey) {
        const oldKey = this.keys[index];
        if (newKey !== oldKey) {
          const value = this.parsedContent[oldKey];
          this.$delete(this.parsedContent, oldKey);
          this.$set(this.parsedContent, newKey, value);
          this.$set(this.keys, index, newKey);
          this.handleFormChange();
        }
      },
      handleValueChange(key, newValue) {
        this.$set(this.parsedContent, key, newValue);
        this.handleFormChange();
      },
      handleFormChange() {
        try {
          const value = this.keys.reduce((acc, key) => {
            acc[key] = this.parsedContent[key];
            return acc;
          }, {});
          this.editorContent = JSON.stringify(value, null, 2);
          this.$emit('input', value);
          this.error = '';
        } catch (e) {
          this.error = 'Invalid format';
        }
      },
      addNewField() {
        const newKey = `field${this.keys.length + 1}`;
        this.$set(this.parsedContent, newKey, '');
        this.keys.push(newKey);
        this.handleFormChange();
      }
    }
  };
  </script>
  
  <style scoped>
  .json-editor {
    position: relative;
  }
  
  .error-message {
    color: #ff5252;
    font-size: 12px;
    margin-top: 4px;
  }
  </style>
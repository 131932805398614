import Vue from "vue";
import {guid} from "@/lib";
import {ChartObject} from "@/resources";
import _ from "underscore";
import {defaultProperties} from "@/components/Layout/Authorized/TheEditor/Object/Types";

const sorter = (a, b) => {
    if (!a || !b || !a.info || !a.info.settings)
        return -1;
    if (!b.info || !b.info.settings)
        return 1;
    return a.info.settings.order > b.info.settings.order ? 1 : -1;
};

const getObjectInd = (list, id) => {
    for (let i = 0; i < list.length; i++) {
        if (list[i].id == id)
            return i;
    }
    return -1;
};

const store = {
    namespaced: true,
    state: {
        list: [],
        selectedIds: [],
        contentEditable: false,
        delayedUpdates: [],
        highlightedId: null,
        lastCreatedId: null
    },
    mutations: {
        setList(state, objects) {
            state.list = objects.sort(sorter);
        },
        select(state, objectId) {
            if (!state.selectedIds.includes(objectId)) {
                state.selectedIds.push(objectId);
            }
        },
        deselect(state, objectId) {
            if (state.selectedIds.includes(objectId)) {
                state.selectedIds = state.selectedIds.filter(id => id != objectId);
            }
        },
        toggleSelection(state, objectId) {
            if (state.selected.includes(objectId)) {
                state.selectedIds = state.selectedIds.filter(id => id != objectId);
            } else {
                state.selectedIds.push(objectId);
            }
        },
        selectAll(state) {
            state.selectedIds = state.list.map(object => object.id);
        },
        deselectAll(state) {
            state.selectedIds = [];
        },
        deleteSelected(state) {
            state.list = state.list.filter(
                object =>
                    !state.selectedIds.includes(object.id) ||
                    (object.info && object.info.settings && object.info.settings.locked)
            );
            state.list.forEach((object, i) => (object.info.settings.order = i));
            state.selectedIds = [];
        },
        create(state, params) {
            state.list = [...state.list, params];
            state.lastCreatedId = params.id;
        },
        delete(state, param) {
            let id = param.id;
            if (!id)
                id = param;

            state.list = state.list.filter(object => id != object.id);
            state.list.forEach((object, i) => (object.info.settings.order = i));
        },
        update(state, params) {
            state.list = state.list.map(object =>
                object.id == params.id ?
                    {
                        ...object,
                        skipSideEffect: false,
                        ...params
                    } :
                    object
            );
        },
        removeGroupPreview(state) {
            state.list = state.list.filter(object => {
                return (
                    object.type != "Base_GroupObject" ||
                    (object.type == "Base_GroupObject" && object.chart_id)
                );
            });
        },
        addToGroup(state, {groupId, objectId}) {
            const object = state.list.find(object => object.id == groupId);
            if (!object.info.settings.objectIds)
                object.info.settings.objectIds = [];
            if (!object.info.settings.objectIds.includes(objectId))
                object.info.settings.objectIds.push(objectId);
        },
        setContentEditable(state, contentEditable) {
            state.contentEditable = contentEditable;
        },
        addAnimation(state, {objectId, animation}) {
            const object = state.list.find(object => object.id == objectId);
            if (!object)
                return;
            Vue.set(object, '_animation', animation);
            setTimeout(() => Vue.set(object, '_animation', null), 4000);
        },
        setHighlight(state, id) {
            state.highlightedId = id;
        }
    },
    actions: {
        setGroupPreview({state, commit, dispatch}) {
            commit('removeGroupPreview');

            if (state.selectedIds.length < 2)
                return;

            dispatch('prepareGroup');
        },
        prepareGroup({state, commit}) {
            const objectIds = state.selectedIds;

            let group = state.list.find(object =>
                object.type == "Base_GroupObject" &&
                JSON.stringify(object.info.settings.objectIds.sort()) ==
                JSON.stringify(objectIds.sort())
            );

            if (!group) {
                group = {
                    type: "Base_GroupObject",
                    id: guid(),
                    info: {
                        settings: {
                            ...defaultProperties["Base_GroupObject"].settings,
                            objectIds
                        }
                    }
                };
                commit('create', group);
            }

            return group;
        },
        async createGroup({state, rootGetters, commit, dispatch}, settings) {
            const objectIds = state.selectedIds;
            if (!objectIds.length)
                return;

            let group = await dispatch('prepareGroup');
            group = {
                ...group,
                info: {
                    ...group.info,
                    settings: {
                        ...group.info.settings,
                        ...settings,
                    }
                },
                openPropertyEditorAfterCreate: true,
                chart_id: rootGetters["chart/active"].id
            };

            commit("update", group);
            return ChartObject.save({object: group});
        },
        removeFromGroups({state, dispatch}, object) {
            //remove deleted objects from groups
            state.list
                .filter(tempObj =>
                    tempObj.type == 'Base_GroupObject' &&
                    tempObj.info.settings.objectIds.indexOf(object.id) != -1
                )
                .forEach(tempObj => {
                    tempObj.info.settings.objectIds = tempObj.info.settings.objectIds.filter(objId => objId != object.id);
                    dispatch('update', tempObj);
                });

            //remove objects from deleting group
            if (object.type == 'Base_GroupObject')
                object.info.settings.objectIds
                    .forEach(id => dispatch('delete', id));
        },
        deleteSelected({getters, commit, dispatch}) {
            const objects = getters.selectedIds.map(id => getters["findById"](id));
            commit("deleteSelected");
            objects.forEach(object => {
                if (object && (
                    !object.info ||
                    !object.info.settings ||
                    !object.info.settings.locked
                )) {
                    ChartObject.delete({id: object.id});
                    dispatch('removeFromGroups', object);
                    if (object.info.settings.commentIds)
                        object.info.settings.commentIds.forEach(commentId => dispatch('delete', commentId));
                    if (object.info.settings.arrows)
                        object.info.settings.arrows.forEach(arrow => dispatch('delete', arrow.id));
                }
            });
        },
        delete({getters, commit, dispatch}, id) {
            const object = getters["findById"](id);
            if (!object)
                return;

            if (object.type == "Presentation_Wayfinder")
                dispatch('delete', object.info.settings.content_id);

            if (object.type == "Base_CommentObject" && object.info.settings.parent_id)
                dispatch('removeCommentFromObject', {
                    objectId: object.info.settings.parent_id,
                    commentId: id
                });

            if (object.info.settings.commentIds)
                object.info.settings.commentIds.forEach(commentId => dispatch('delete', commentId));
            if (object.info.settings.arrows)
                object.info.settings.arrows.forEach(arrow => dispatch('delete', arrow.id));

            dispatch('connection/deleteConnectionsOfDeletedObj', id, {root: true});
            commit("delete", id);
            ChartObject.delete({id});
            dispatch('removeFromGroups', object);
        },
        update({commit, state, getters, dispatch}, params) {
            commit("update", params);

            const object = getters["findById"](params.id);
            if (!object)
                return;

            // removing empty groups
            if (object.type == 'Base_GroupObject' &&
                (!object.info.settings.objectIds || !object.info.settings.objectIds.length)) {
                if (object.chart_id)
                    dispatch('delete', object.id);
                else
                    commit('removeGroupPreview');

                return;
            }

            if (!object.chart_id)
                return;

            commit('chart/updateObject', object, {root: true});

            const ind = state.delayedUpdates.findIndex(o => o.id == params.id);
            if (ind != -1)
                state.delayedUpdates[ind] = object;
            else
                state.delayedUpdates.push(object);
            dispatch("updateOnServer");
        },
        updateVolume({commit, state, getters, dispatch}, params) {
            commit("updateVolume", params);

            const object = getters["findById"](params.id);
            if (!object)
                return;

            commit('chart/updateObject', object, {root: true});

            const ind = state.delayedUpdates.findIndex(o => o.id == params.id);
            if (ind != -1)
                state.delayedUpdates[ind] = object;
            else
                state.delayedUpdates.push(object);
            dispatch("updateOnServer");
        },
        updateOnServer: _.debounce(function ({state}) {
            for (let object of state.delayedUpdates) {
                ChartObject.update({id: object.id}, {object});
            }
            state.delayedUpdates = [];
        }, 300),
        create({getters, rootGetters, commit, dispatch}, params) {
            const payload = {
                size: defaultProperties[params.type].size || {width: 200, height: 200},
                info: {},
                ...params,
                id: guid(),
                chart_id: rootGetters["chart/active"].id,
                created_at: new Date().toGMTString()
            };

            payload.info.settings = {
                ...defaultProperties[payload.type].settings,
                created_by: rootGetters["user/visibleName"],
                commentIds: [],
                arrows: [],
                visible: true,
                order: payload.type == "Base_CommentObject"
                    ? 0
                    : getters["list"].length,
                ...payload.info.settings
            }

            commit("create", payload);
            ChartObject.save({object: payload});

            if (payload.type == "Presentation_Wayfinder" && !params.id) // "id" exists when restoring from template
                dispatch('addWayfinderContent', payload);

            return Promise.resolve(payload);
        },
        duplicate({commit, dispatch, state}, id) {
            const index = getObjectInd(state.list, id);
            if (index == -1)
                return;

            const originalObject = state.list[index];
            const ratio = 1.1;
            ChartObject.duplicate({id}, {id}).then(function ({body}) {
                const count = state.list.reduce((acc, current) => (current.parent === id) ? acc + 1 : acc, 0);
                body.position = {
                    x: originalObject.position.x + body.size.width * ratio * (count + 1),
                    y: originalObject.position.y
                };
                commit('create', body);
                body.parent = id;
                // No children copy
                if (body.info && body.info.settings) {
                    body.info.settings.arrows = [];
                    body.info.settings.commentIds = [];
                }
                dispatch('update', body);
                commit('deselect', id);
                commit('select', body.id);
            });
        },
        orderForward({state, dispatch}, objectId) {
            const ind = getObjectInd(state.list, objectId);
            if (ind == -1 || ind == state.list.length - 1)
                return;

            const objCurr = state.list[ind];
            objCurr.info.settings.order = ind + 1;
            dispatch('update', objCurr);

            const objNext = state.list[ind + 1];
            objNext.info.settings.order = ind;
            dispatch('update', objNext);

            state.list = state.list.map((object, i) => {
                if (i == ind)
                    return objNext;
                if (i == ind + 1)
                    return objCurr;
                return object;
            });
        },
        orderBackward({state, dispatch}, objectId) {
            const ind = getObjectInd(state.list, objectId);
            if (ind == -1 || ind == 0)
                return;

            const objCurr = state.list[ind];
            objCurr.info.settings.order = ind - 1;
            dispatch('update', objCurr);

            const objPrev = state.list[ind - 1];
            objPrev.info.settings.order = ind;
            dispatch('update', objPrev);

            state.list = state.list.map((object, i) => {
                if (i == ind - 1)
                    return objCurr;
                if (i == ind)
                    return objPrev;
                return object;
            });
        },
        orderFront({state, dispatch}, objectId) {
            const ind = getObjectInd(state.list, objectId);
            if (ind == -1 || ind == state.list.length - 1)
                return;

            const objCurr = state.list[ind];
            objCurr.info.settings.order = state.list.length - 1;
            dispatch('update', objCurr);

            state.list = state.list.map((object, i) => {
                if (i == state.list.length - 1)
                    return objCurr;
                if (i >= ind) {
                    let objNext = state.list[i + 1];
                    objNext.info.settings.order = i;
                    dispatch('update', objNext);
                    return objNext;
                }
                return object;
            });
        },
        orderBack({state, dispatch}, objectId) {
            const ind = getObjectInd(state.list, objectId);
            if (ind == -1 || ind == 0)
                return;

            const objCurr = state.list[ind];
            objCurr.info.settings.order = 0;
            dispatch('update', objCurr);

            state.list = state.list.map((object, i) => {
                if (i == 0)
                    return objCurr;
                if (i <= ind) {
                    let objPrev = state.list[i - 1];
                    objPrev.info.settings.order = i;
                    dispatch('update', objPrev);
                    return objPrev;
                }
                return object;
            });
        },
        async addWayfinderContent({getters, rootGetters, dispatch}, wayfinder) {
            const wayfinderContent = {
                type: "Presentation_WayfinderContentObject",
                created_at: new Date().toGMTString(),
                info: {
                    settings: {
                        title: "",
                        image: null,
                        wayfinder_id: wayfinder.id
                    }
                },
                position: {
                    x: wayfinder.position.x + wayfinder.size.width / 2 + 100,
                    y: wayfinder.position.y + wayfinder.size.height / 2 + 30
                }
            }
            const contentObj = await dispatch('create', wayfinderContent);

            wayfinder.info.settings.content_id = contentObj.id;
            dispatch("update", wayfinder);

            dispatch("connection/create", {
                from: wayfinder.id,
                to: contentObj.id
            }, {root: true});
        },
        addCommentToObject({state, dispatch}, {objectId, commentId}) {
            const object = state.list.find(object => object.id == objectId);
            if (object.info.settings.commentIds)
                object.info.settings.commentIds = _.uniq([...object.info.settings.commentIds, commentId]);
            else
                object.info.settings.commentIds = [commentId];
            dispatch("update", object);
        },
        removeCommentFromObject({state, dispatch}, {objectId, commentId}) {
            const object = state.list.find(object => object.id == objectId);
            object.info.settings.commentIds = object.info.settings.commentIds.filter(cId => cId !== commentId);
            dispatch("update", object);
        },
        addArrowToObject({state, dispatch}, {objectId, arrowId, which}) { // which: 'start' or 'end'
            const object = state.list.find(object => object.id == objectId);
            if (!object) return;
            if (object.info.settings.arrows) {
                const oldArrow = object.info.settings.arrows.find(arrow => arrow.id === arrowId);
                if (!oldArrow) {
                    object.info.settings.arrows = [...object.info.settings.arrows, {id: arrowId, which}]
                    dispatch("update", object);
                }
            } else {
                object.info.settings.arrows = [{id: arrowId, which}];
                dispatch("update", object);
            }
        },
        removeArrowFromObject({state, dispatch}, {objectId, arrowId}) {
            const object = state.list.find(object => object.id == objectId);
            if (object && object.info.settings.arrows) {
                object.info.settings.arrows = object.info.settings.arrows.filter(arrow => arrow.id !== arrowId);
                dispatch("update", object);
            }
        },
        moveItem({state, dispatch, getters}, {objectId, targetPositionObjectId}) {
            // Move the object to the target position
            const object = getters["findById"](objectId);
            const list = state.list.filter(object => object.id != objectId);
            const targetOrderIndex = getObjectInd(list, targetPositionObjectId);
            if (targetOrderIndex == -1) return;
            list.splice(targetOrderIndex, 0, object);

            // Update the order property of object to match object position in the list
            state.list = list.map((object, i) => {
                if (object.info.settings.order != i) {
                    object.info.settings.order = i;
                    dispatch('update', object);
                }
                return object;
            });
        },
    },
    getters: {
        selectedIds(state) {
            return state.selectedIds;
        },
        selected(state) {
            return state.list.filter(object => state.selectedIds.includes(object.id));
        },
        highlightedId(state) {
            return state.highlightedId;
        },
        findById: state => id => {
            return state.list.find(object => object.id == id);
        },
        comments: state => object => {
            return state.list.filter(o => object.info.settings.commentIds && object.info.settings.commentIds.includes(o.id));
        },
        transformOrigin: state => id => {
            const object = state.list.find(object => object.id == id);
            if (!object || !object.info.settings || !object.info.settings.transformOrigin) return []
            return object.info.settings.transformOrigin
        },
        arrows: state => id => {
            const object = state.list.find(object => object.id == id);
            if (!object || !object.info.settings || !object.info.settings.arrows) return [];
            return object.info.settings.arrows.map(arrow => {
                const arrowObject = state.list.find(object => object.id == arrow.id);
                return {arrow: arrowObject, which: arrow.which};
            });
        },
        list(state) {
            return state.list.filter(o => {
                return (
                    o.type == "Base_GroupObject" ||
                    (o.position &&
                        typeof o.position.x == "number" &&
                        typeof o.position.y == "number")
                );
            });
        },
        ulist(state) {
            return state.list.filter(
                o =>
                    !o.position ||
                    typeof o.position.x != "number" ||
                    typeof o.position.y != "number"
            );
        },
        isOrderMin: state => id => state.list[0].id == id,
        isOrderMax: state => id => state.list[state.list.length - 1].id == id,
        lastCreatedId: state => state.lastCreatedId,
    }
};

export default store;

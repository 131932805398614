<template>
  <v-toolbar dense no-padding>
    <v-btn-toggle group borderless v-model="openedData">
      <v-btn text style="opacity: 1" class="ma-0" value="operations">
        Operations
      </v-btn>

      <v-divider vertical />

      <v-btn text style="opacity: 1" class="ma-0" value="validation">
        Validation
      </v-btn>
    </v-btn-toggle>

    <v-divider vertical />

    <slot name="menumore" />
  </v-toolbar>
</template>

<script>
import Vue from 'vue';
import ObjectPropertyEditorExtend from '../../_extends/ObjectPropertyEditor';

export default {
  name: 'Workflow_TransformObjectPropertyEditor',
  extends: ObjectPropertyEditorExtend,

  data() {
    const cmpOperations = Vue.extend({
      template: `
        <div>
          <draggable v-model="settings.operations" handle=".drag" :disabled="readonly">
            <div class="mb-4" v-for="(operation, index) in settings.operations" :key="index">
              <v-layout align-center>
                <v-flex xs1>
                  <v-icon class="drag" :class="readonly ? 'disabled' : ''">drag_handle</v-icon>
                </v-flex>
                <v-flex xs4>
                  <v-text-field
                    v-model="operation.from"
                    label="From"
                    :readonly="readonly"
                  />
                </v-flex>
                <v-flex xs4>
                  <v-text-field
                    v-model="operation.to"
                    label="To"
                    :readonly="readonly"
                  />
                </v-flex>
                <v-flex xs2>
                  <v-select
                    v-model="operation.transform"
                    :items="transformTypes"
                    label="Transform"
                    :readonly="readonly"
                  />
                </v-flex>
                <v-btn
                  text
                  icon
                  @click="deleteOperation(index)"
                  :disabled="readonly"
                >
                  <v-icon>delete</v-icon>
                </v-btn>
              </v-layout>
            </div>
          </draggable>
          <v-btn
            text
            color="primary"
            @click="addOperation"
            :disabled="readonly"
          >
            Add Operation
          </v-btn>
        </div>
      `,
      props: ['settings', 'readonly'],
      data() {
        return {
          transformTypes: [
            'none',
            'toString',
            'toNumber',
            'toBoolean',
            'toDate',
            'custom'
          ]
        };
      },
      methods: {
        addOperation() {
          this.settings.operations.push({
            from: '',
            to: '',
            transform: 'none'
          });
        },
        deleteOperation(index) {
          this.settings.operations.splice(index, 1);
        }
      }
    });

    const cmpValidation = Vue.extend({
      template: `
        <div>
          <v-switch
            v-model="settings.validateOutput"
            label="Validate Output"
            :readonly="readonly"
          />
          <v-textarea
            v-if="settings.validateOutput"
            v-model="settings.schema"
            label="JSON Schema"
            :readonly="readonly"
            rows="10"
          />
        </div>
      `,
      props: ['settings', 'readonly']
    });

    return {
      cmpOperations,
      cmpValidation,
      openedData: null
    };
  },

  methods: {
    onOpenOperations() {
      this.$root.$emit('DataEditor.open', {
        component: this.cmpOperations,
        props: {
          baseObjectId: this.baseObject.id,
          settings: this.localSettings,
          readonly: this.readonly
        },
        title: 'Transform Operations'
      });
    },

    onOpenValidation() {
      this.$root.$emit('DataEditor.open', {
        component: this.cmpValidation,
        props: {
          baseObjectId: this.baseObject.id,
          settings: this.localSettings,
          readonly: this.readonly
        },
        title: 'Output Validation'
      });
    }
  },

  watch: {
    openedData(value) {
      switch (value) {
        case 'operations':
          this.onOpenOperations();
          break;
        case 'validation':
          this.onOpenValidation();
          break;
        default:
          this.$root.$emit('DataEditor.close');
      }
    }
  },

  mounted() {
    this.$root.$on('DataEditor.close', this.onDataEditorClose);
    this.onOpenOperations();
  },

  beforeDestroy() {
    this.$root.$off('DataEditor.close', this.onDataEditorClose);
  }
};
</script> 
import yaml from 'js-yaml';

class WorkflowDefinitionService {
  /**
   * Parse a YAML workflow definition into a structured object
   * @param {string} yamlContent - The YAML workflow definition
   * @returns {Object} Parsed workflow definition
   * @throws {Error} If YAML is invalid or missing required fields
   */
  parseDefinition(yamlContent) {
    try {
      // Parse YAML to JavaScript object
      const definition = yaml.load(yamlContent);

      // Validate required fields
      this.validateDefinition(definition);

      return definition;
    } catch (error) {
      throw new Error(`Failed to parse workflow definition: ${error.message}`);
    }
  }

  /**
   * Validate the workflow definition has all required fields
   * @param {Object} definition - The parsed workflow definition
   * @throws {Error} If definition is invalid
   */
  validateDefinition(definition) {
    const requiredFields = ['name', 'version', 'trigger', 'nodes'];
    
    for (const field of requiredFields) {
      if (!definition[field]) {
        throw new Error(`Missing required field: ${field}`);
      }
    }

    // Validate trigger
    if (!definition.trigger.type) {
      throw new Error('Trigger must have a type');
    }

    // Validate nodes
    if (!Array.isArray(definition.nodes) || definition.nodes.length === 0) {
      throw new Error('Nodes must be a non-empty array');
    }

    // Validate each node
    definition.nodes.forEach((node, index) => {
      if (!node.id || !node.type) {
        throw new Error(`Node at index ${index} must have id and type`);
      }
    });
  }
}

export default new WorkflowDefinitionService(); 
// Native/common actions with descriptions
const nativeActions = {
  'user.create': 'Create a new user account',
  'user.read': 'Retrieve user information',
  'user.update': 'Update user information',
  'user.delete': 'Delete user account',
  'user.authenticate': 'Authenticate user credentials',
  'user.logout': 'Log out current user',
  'team.create': 'Create a new team',
  'team.read': 'Retrieve team information',
  'team.update': 'Update team details',
  'team.delete': 'Delete a team',
  'team.addMember': 'Add a member to team',
  'team.removeMember': 'Remove a member from team',
  'team.updateMember': 'Update team member details'
};

// Function to get all available actions including custom ones from project settings
const getActions = (projectSettings = {}) => {
  // Get the custom actions array from the correct path
  const customActionsArray = Array.isArray(projectSettings?.actions?.custom) 
    ? projectSettings.actions.custom 
    : [];

  // Convert array of custom actions to key-value format
  const customActions = customActionsArray.reduce((acc, action) => {
    if (action?.key && action?.value) {
      acc[action.key] = action.value;
    }
    return acc;
  }, {});

  console.log('Parsed custom actions:', customActions);

  return {
    ...nativeActions,
    ...customActions
  };
};

export { getActions, nativeActions };

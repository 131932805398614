<template>
  <v-tooltip top>
    <template v-slot:activator="{ on: tooltip }">
      <div
        class="active-member mr-3"
        :class="classObj"
        v-on="tooltip"
      >
        <div class="avatar" :style="{ color }">
          <v-avatar size="32" color="#e6e6e6">
            <img v-if="avatar" :src="avatar" />
            <h3 v-else style="margin-bottom: 1px">{{capital}}</h3>
          </v-avatar>

          <div class="menu-hover d-flex justify-center align-center">
            <v-menu offset-y offset-x>
              <template v-slot:activator="{ on: menu }">
                <v-icon v-on="menu" >more_horiz</v-icon>
              </template>

              <v-list>
                <v-list-item
                  v-if="cursor"
                  @click="goToCollaborator"
                >
                  <v-list-item-content>
                    <v-list-item-title>Go to user</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item
                  v-if="cursor && cursor.user.id != followingId"
                  @click="followUser"
                >
                  <v-list-item-content>
                    <v-list-item-title>Follow user</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  v-if="cursor && cursor.user.id == followingId"
                  @click="unfollowUser"
                >
                  <v-list-item-content>
                    <v-list-item-title>Unfollow user</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
      </div>
    </template>
    <span>{{ name }}</span>
  </v-tooltip>

</template>

<script>
import {mapGetters, mapState} from "vuex";

import {generateColor, isInactiveCursor } from '@/lib';


export default {
  name: "ActiveMember",
  props: {
    cursor: Object,
    viewport: Object,
    now: Date
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters({
      visibleName: "user/visibleName",
    }),
    ...mapState({
      scale: state => state.chart.scale,
      translate: state => state.chart.translate,
      following: state => state.chart.following,
      followingId: state => state.chart.following ? state.chart.following.id : null,
    }),
    classObj() {
      if (this.cursor)
        return {inactive: isInactiveCursor(this.now, this.cursor.update_at)};
      return {};
    },
    color() {
      if (this.cursor)
        return generateColor(this.cursor.user.username);
      return null;
    },
    name() {
      if (this.cursor) {
        const {user} = this.cursor;
        if (user.first_name && user.last_name)
          return user.first_name + ' ' + user.last_name;
        else if (user.first_name)
          return user.first_name;
        else if (user.last_name)
          return user.last_name;
        return user.username;

      } else {
        return this.visibleName + ' (me)';
      }
    },
    avatar() {
      if (this.cursor) {
        if (this.cursor.user.avatar_url)
          return 'https://developers.beach.io' + this.cursor.user.avatar_url;
        return null;
      }
      if (this.currentUser.profile)
        return this.currentUser.profile.avatar_url;
      return null;
    },
    capital() {
      return this.name.charAt(0).toUpperCase();
    },
    volumeIcon() {
      return this.muted ? 'mdi-volume-off' : 'mdi-volume-high';
    },
  },
  methods: {
    onClick(e) {
      this.$emit('click', e);
    },
    onDblClick(e) {
      this.$emit('dblclick', e);
    },
    followUser() {
      this.$store.commit("chart/setFollowing", this.cursor.user);
    },
    unfollowUser() {
      this.$store.commit("chart/setFollowing", null);
    },

    goToCollaborator() {
      this.$root.$emit("PropertyEditor.close");

      if (!this.cursor.params.x || !this.cursor.params.y)
        return;

      const rect = this.viewport.$el.getBoundingClientRect();

      this.$store.commit("chart/setTranslate", {
        x: - this.cursor.params.x * this.scale + rect.width / 2,
        y: - this.cursor.params.y * this.scale + rect.height / 2,
      });
    },

  },
  beforeDestroy() {
    clearInterval(this.badgeBlinkInterval);
  }
}

</script>

<style scoped>
.active-member {
  position: relative;
  margin-top: 2px;
  margin-bottom: 2px;
}

.active-member.inactive {
  opacity: 0.5;
}

.avatar {
  border-radius: 50%;
}

.menu-hover {
  position: absolute;
  top: 0;
  left: 0;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  opacity: .01;
  z-index: 3;
  cursor: pointer;
  background-color: rgba(255, 255, 255, .7);
}

.active-member:hover .menu-hover {
  opacity: 1;
}

</style>

<template>
  <v-toolbar dense no-padding>
    <v-btn-toggle group borderless v-model="openedData">
      <v-btn text style="opacity: 1" class="ma-0" value="function">
        Function
      </v-btn>

      <v-divider vertical />

      <v-btn text style="opacity: 1" class="ma-0" value="arguments">
        Arguments
      </v-btn>

      <v-divider vertical />

      <v-btn text style="opacity: 1" class="ma-0" value="auth">
        Auth
      </v-btn>

      <v-divider vertical />

      <v-btn text style="opacity: 1" class="ma-0" value="retry">
        Retry
      </v-btn>

      <v-divider vertical />

      <v-btn text style="opacity: 1" class="ma-0" value="response">
        Response
      </v-btn>
    </v-btn-toggle>

    <v-divider vertical />

    <slot name="menumore" />
  </v-toolbar>
</template>

<script>
import Vue from 'vue';
import ObjectPropertyEditorExtend from '../../_extends/ObjectPropertyEditor';
import JsonEditor from '../../../../../../../_Common/ControlElements/JsonEditor.vue';

export default {
  name: 'Workflow_CloudFunctionObjectPropertyEditor',
  extends: ObjectPropertyEditorExtend,

  data() {
    const availableFunctions = [
      { 
        name: 'getPluginData', 
        description: 'Retrieves plugin configuration and metadata from Forge'
      },
      { 
        name: 'validatePluginConfig', 
        description: 'Validates plugin configuration against Forge schema'
      },
      { 
        name: 'installPlugin', 
        description: 'Installs or updates plugin in Forge environment'
      },
      { 
        name: 'activatePlugin', 
        description: 'Activates plugin in the Forge runtime'
      },
      { 
        name: 'deactivatePlugin', 
        description: 'Deactivates plugin in the Forge runtime'
      },
      { 
        name: 'uninstallPlugin', 
        description: 'Removes plugin from Forge environment'
      },
      { 
        name: 'getPluginStatus', 
        description: 'Checks current status of plugin in Forge'
      },
      { 
        name: 'listPluginVersions', 
        description: 'Lists all available versions of the plugin'
      },
      { 
        name: 'rollbackPlugin', 
        description: 'Rolls back plugin to previous version'
      },
      { 
        name: 'validatePluginDependencies', 
        description: 'Checks compatibility with other installed plugins'
      },
      {
        name: 'sendApprovalNotification',
        description: 'Sends an approval notification to the specified email address'
      },
      {
        name: 'sendRejectionNotification',
        description: 'Sends a rejection notification to the specified email address'
      },
      {
        name: 'approvePlugin',
        description: 'Approves the plugin installation'
      },
      {
        name: 'rejectPlugin',
        description: 'Rejects the plugin installation'
      }
    ];

    const cmpFunction = Vue.extend({
      template: `
        <div>
          <v-card flat>
            <v-card-text>
              <v-autocomplete
                v-model="settings.functionName"
                :items="availableFunctions"
                :filter="customFilter"
                item-text="name"
                item-value="name"
                label="Function Name"
                :readonly="readonly"
                hide-no-data
                clearable
                auto-select-first
                :rules="[v => !!v || 'Function name is required']"
              >
                <template v-slot:item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                    <v-list-item-subtitle class="text--secondary">
                      {{ item.description }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </template>

                <template v-slot:prepend-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-subtitle class="text--disabled">
                        Select an existing function or type to create a new one
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>
              </v-autocomplete>

              <v-alert
                v-if="!isConnectedToForge"
                type="info"
                text
                class="mt-4"
                dense
              >
                <div class="text-body-2">
                  <v-icon small left>mdi-information</v-icon>
                  Not connected to Forge Ignite. 
                  <a href="#" @click.prevent="connectToForge">Connect now</a> 
                  to access your cloud functions.
                </div>
              </v-alert>
            </v-card-text>
          </v-card>
        </div>
      `,
      props: ['settings', 'readonly'],
      data() {
        return {
          availableFunctions,
          isConnectedToForge: false // Mock connection status
        };
      },
      methods: {
        customFilter(item, queryText) {
          // Allow both selecting from existing and creating new
          const name = item.name.toLowerCase();
          const searchText = queryText.toLowerCase();
          
          return name.indexOf(searchText) > -1;
        },
        connectToForge() {
          // Mock connection method
          console.log('Connecting to Forge Ignite...');
        }
      }
    });

    const cmpArguments = Vue.extend({
      template: `
        <div>
          <v-card flat>
            <v-card-text>
              <json-editor
                v-model="settings.arguments"
                language="json"
                height="200"
                :allow-new-fields="true"
              />
            </v-card-text>
          </v-card>
        </div>
      `,
      components: {
        JsonEditor
      },
      props: ['settings', 'readonly']
    });

    const cmpAuth = Vue.extend({
      template: `
        <div>
          <v-card flat>
            <v-card-text>
              <v-checkbox
                v-model="settings.auth.useAppId"
                label="Application ID"
                :readonly="readonly"
                @change="updateAuth"
              />
              <v-text-field
                v-if="settings.auth.useAppId"
                v-model="settings.auth.applicationId"
                label="Application ID"
                :readonly="readonly"
                hint="Parse Application ID"
                persistent-hint
                class="ml-4"
              />

              <v-checkbox
                v-model="settings.auth.useJsKey"
                label="JavaScript Key"
                :readonly="readonly"
                @change="updateAuth"
              />
              <v-text-field
                v-if="settings.auth.useJsKey"
                v-model="settings.auth.jsKey"
                label="JavaScript Key"
                :readonly="readonly"
                hint="Parse JavaScript Key"
                persistent-hint
                class="ml-4"
              />

              <v-checkbox
                v-model="settings.auth.useClientKey"
                label="Client Key"
                :readonly="readonly"
                @change="updateAuth"
              />
              <v-text-field
                v-if="settings.auth.useClientKey"
                v-model="settings.auth.clientKey"
                label="Client Key"
                :readonly="readonly"
                hint="Parse Client Key"
                persistent-hint
                class="ml-4"
              />

              <v-checkbox
                v-model="settings.auth.useSessionToken"
                label="Session Token"
                :readonly="readonly"
                @change="updateAuth"
              />
              <v-text-field
                v-if="settings.auth.useSessionToken"
                v-model="settings.auth.sessionToken"
                label="Session Token"
                :readonly="readonly"
                hint="User session token for authenticated requests"
                persistent-hint
                class="ml-4"
              />
            </v-card-text>
          </v-card>
        </div>
      `,
      props: ['settings', 'readonly'],
      created() {
        // Initialize auth settings if they don't exist
        if (!this.settings.auth) {
          const defaultAuth = {
            useAppId: false,
            useJsKey: false,
            useClientKey: false,
            useSessionToken: false,
            applicationId: '',
            jsKey: '',
            clientKey: '',
            sessionToken: ''
          };
          // Use Vue.set to ensure reactivity
          Vue.set(this.settings, 'auth', defaultAuth);
        }
      },
      methods: {
        updateAuth() {
          // Create a clean copy of the auth settings
          const authUpdate = JSON.parse(JSON.stringify(this.settings.auth));
          Vue.set(this.settings, 'auth', authUpdate);
        }
      }
    });

    const cmpRetry = Vue.extend({
      template: `
        <div>
          <v-switch
            v-model="settings.retryConfig.enabled"
            label="Enable Retry"
            :readonly="readonly"
          />
          
          <template v-if="settings.retryConfig.enabled">
            <v-text-field
              v-model.number="settings.retryConfig.maxAttempts"
              label="Max Attempts"
              type="number"
              :readonly="readonly"
              min="1"
              max="10"
            />
            
            <v-select
              v-model="settings.retryConfig.backoffType"
              :items="backoffTypes"
              label="Backoff Type"
              :readonly="readonly"
            />
            
            <v-text-field
              v-model.number="settings.retryConfig.backoffDelay"
              label="Backoff Delay (ms)"
              type="number"
              :readonly="readonly"
              min="100"
            />
          </template>
        </div>
      `,
      props: ['settings', 'readonly'],
      data() {
        return {
          backoffTypes: [
            { text: 'Fixed', value: 'fixed' },
            { text: 'Exponential', value: 'exponential' }
          ]
        };
      }
    });

    const cmpResponse = Vue.extend({
      template: `
        <div>
          <v-card flat>
            <v-card-text>
              <!-- Response Schema Fields -->
              <div v-for="(field, index) in responseFields" :key="index" class="mb-4">
                <div class="d-flex align-center">
                  <v-text-field
                    v-model="field.name"
                    label="Field Name"
                    class="mr-2"
                    :readonly="readonly"
                  />
                  <v-select
                    v-model="field.type"
                    :items="dataTypes"
                    label="Data Type"
                    class="mr-2"
                    :readonly="readonly"
                  />
                  <v-btn icon small @click="removeField(index)" :disabled="readonly">
                    <v-icon small>mdi-delete</v-icon>
                  </v-btn>
                </div>
                <json-editor
                  v-if="field.type === 'object'"
                  v-model="field.schema"
                  language="json"
                  height="100"
                  :allow-new-fields="true"
                  class="mt-2"
                  @input="validateSchema(index)"
                />
                <div class="v-messages theme--light" v-if="field.error">
                  <div class="v-messages__wrapper">
                    <div class="v-messages__message error--text">{{ field.error }}</div>
                  </div>
                </div>
              </div>

              <!-- Add Field Button -->
              <v-btn
                text
                color="primary"
                @click="addField"
                :disabled="readonly"
                class="mt-2"
              >
                <v-icon left small>mdi-plus</v-icon>
                Add Response Field
              </v-btn>

              <!-- State Variable Mapping -->
              <v-text-field
                v-model="settings.stateVariable"
                label="Map to State Variable"
                class="mt-4"
                :readonly="readonly"
                hint="Specify the state variable to map the response"
                persistent-hint
              />
            </v-card-text>
          </v-card>
        </div>
      `,
      components: {
        JsonEditor
      },
      props: ['settings', 'readonly'],
      data() {
        return {
          responseFields: this.initializeResponseFields(),
          dataTypes: [
            { text: 'String', value: 'string' },
            { text: 'Number', value: 'number' },
            { text: 'Boolean', value: 'boolean' },
            { text: 'Array', value: 'array' },
            { text: 'Object', value: 'object' }
          ]
        };
      },
      methods: {
        initializeResponseFields() {
          try {
            const schema = JSON.parse(this.settings.responseSchema || '{}');
            return Object.entries(schema).map(([name, def]) => ({
              name,
              type: def.type || 'string',
              schema: def.schema ? JSON.stringify(def.schema, null, 2) : '',
              error: ''
            }));
          } catch (e) {
            return [];
          }
        },
        addField() {
          this.responseFields.push({
            name: '',
            type: 'string',
            schema: '',
            error: ''
          });
        },
        removeField(index) {
          this.responseFields.splice(index, 1);
          this.updateSchema();
        },
        validateSchema(index) {
          const field = this.responseFields[index];
          this.updateSchema();
        },
        updateSchema() {
          const schema = {};
          this.responseFields.forEach(field => {
            if (field.name) {
              schema[field.name] = {
                type: field.type,
                ...(field.type === 'object' && field.schema ? { schema: field.schema } : {})
              };
            }
          });
          Vue.set(this.settings, 'responseSchema', JSON.stringify(schema));
        }
      },
      watch: {
        responseFields: {
          handler() {
            this.updateSchema();
          },
          deep: true
        }
      }
    });

    return {
      cmpFunction,
      cmpArguments,
      cmpAuth,
      cmpRetry,
      cmpResponse,
      openedData: null
    };
  },

  methods: {
    onOpenFunction() {
      this.$root.$emit('DataEditor.open', {
        component: this.cmpFunction,
        props: {
          settings: this.localSettings,
          readonly: this.readonly
        },
        title: 'Cloud Function'
      });
    },

    onOpenArguments() {
      this.$root.$emit('DataEditor.open', {
        component: this.cmpArguments,
        props: {
          settings: this.localSettings,
          readonly: this.readonly
        },
        title: 'Function Arguments'
      });
    },

    onOpenAuth() {
      this.$root.$emit('DataEditor.open', {
        component: this.cmpAuth,
        props: {
          settings: this.localSettings,
          readonly: this.readonly
        },
        title: 'Authentication'
      });
    },

    onOpenRetry() {
      this.$root.$emit('DataEditor.open', {
        component: this.cmpRetry,
        props: {
          settings: this.localSettings,
          readonly: this.readonly
        },
        title: 'Retry Settings'
      });
    },

    onOpenResponse() {
      this.$root.$emit('DataEditor.open', {
        component: this.cmpResponse,
        props: {
          settings: this.localSettings,
          readonly: this.readonly
        },
        title: 'Response Mapping'
      });
    }
  },

  watch: {
    openedData(value) {
      switch (value) {
        case 'function':
          this.onOpenFunction();
          break;
        case 'arguments':
          this.onOpenArguments();
          break;
        case 'auth':
          this.onOpenAuth();
          break;
        case 'retry':
          this.onOpenRetry();
          break;
        case 'response':
          this.onOpenResponse();
          break;
        default:
          this.$root.$emit('DataEditor.close');
      }
    },
    localSettings: {
      handler(newVal) {
        // Create a clean copy before emitting
        const settings = JSON.parse(JSON.stringify(newVal));
        this.$emit('input', settings);
      },
      deep: true
    }
  },

  mounted() {
    this.$root.$on('DataEditor.close', this.onDataEditorClose);
    this.onOpenFunction();
  },

  beforeDestroy() {
    this.$root.$off('DataEditor.close', this.onDataEditorClose);
  }
};
</script>

<style scoped>
.editor-container {
  margin-bottom: 16px;
}

.editor-container .v-label {
  margin-bottom: 8px;
  font-size: 0.75rem;
  color: rgba(0, 0, 0, 0.6);
}

.editor-container .ace_editor {
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  font-family: 'Monaco', 'Menlo', 'Ubuntu Mono', 'Consolas', 'source-code-pro', monospace;
}
</style> 
<template>
  <v-app-bar flat app :color="searchActive ? 'grey lighten-3' : 'white'" class="the-header" extension-height="48">
    <v-app-bar-nav-icon @click.stop="toggleDrawer" />
    <v-toolbar-title class="font-weight-black">{{title}}</v-toolbar-title>
    <AppHeaderMore :menu="moreMenuItems" v-if="moreMenuItems && moreMenuItems.length" />
    <v-row v-if="isCollapsable" class="ml-2">
      <v-btn icon small @click="toggleTabs"><v-icon>{{tabsToggleIcon}}</v-icon></v-btn>
      <div v-if="activeTabLabel"> 
        <v-chip dark color="deep-purple lighten-5" class="mx-2 headline" label >
          {{this.localTabs[localActiveTabInd].name}}
        </v-chip>
      </div>
    </v-row>
    <v-spacer v-if="!moreMenuItems" />
    <v-autocomplete
      v-model="selectSearch"
      :loading="loadingSearch"
      :items="searchItems"
      :search-input.sync="searchText"
      class="search"
      hide-details
      no-filter
      label="Search"
      solo
      dense
      rounded
      flat
      return-object
      item-text="name"
      prepend-inner-icon="mdi-magnify"
      style="max-width: 300px;"
      @focus="searchActive = true"
      @blur="searchActive = false"
    >
      <template v-slot:no-data>
        <v-list-item class="search-message">
          <v-list-item-title>
            Search your <strong>Projects</strong>, <strong>Charts</strong> and <strong>Objects</strong>
          </v-list-item-title>
        </v-list-item>
      </template>
      <template v-slot:item="{item}">
        <template v-if="typeof item !== 'object'">
          <v-list-item-content v-text="item" />
        </template>
        <template v-else>
          <v-list-item-content>
            <v-list-item-title v-html="item.name" />
            <v-list-item-subtitle v-if="item.subtitle">
              <i>
                from
                {{item.subtitle}}
              </i>
            </v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </template>
    </v-autocomplete>
    <v-spacer />
    <v-btn text @click="openCommunity" class="no-uppercase">Community</v-btn>
    <v-btn text disabled class="no-uppercase mr-4">Learning</v-btn>
    <AppHeaderAccount />
    <template v-slot:extension v-if="localTabs && localTabs.length && tabsVisible">
      <div class="d-flex tabs-container">
        <v-tabs
          v-model="localActiveTabInd"
          background-color="#f8f8f8"
          center-active
          show-arrows
          optional
          style="width: 100px"
        >
          <draggable
            class="v-slide-group__content v-tabs-bar__content"
            :disabled="!isDraggable"
            v-model="localTabs"
            @update="tabUpdate"
          >
            <v-tab :ref="`tab${index}`" v-for="(tab, index) in localTabs" :key="index">
              <slot name="tab" :tab="tab">{{tab.name}}</slot>
              <v-icon v-if="tab.icon">{{tab.icon}}</v-icon>
            </v-tab>
          </draggable>
        </v-tabs>
        <slot name="afterTabs" />
      </div>
    </template>

  </v-app-bar>
</template>


<script>
import AppHeaderMore from "./AppHeader/AppHeaderMore";
import AppHeaderAccount from "./AppHeader/AppHeaderAccount";
import * as _ from "underscore";
import {deepClone} from "@/lib";

export default {
  name: "AppHeader",
  props: {
    title: String,
    tabs: Array,
    moreMenuItems: Array,
    activeTabInd: Number,
    isDraggable: Boolean,
    isCollapsable: Boolean
  },
  components: {
    AppHeaderMore,
    AppHeaderAccount
  },
  data() {
    const localActiveTabInd = this.activeTabInd || 0;
    return {
      searchText: "",
      searchItems: [],
      loadingSearch: false,
      selectSearch: null,
      localTabs: deepClone(this.tabs),
      localActiveTabInd,
      activeTab: this.tabs[localActiveTabInd],
      tabsVisible: true,
      tabsToggleIcon: "mdi-menu-down",
      activeTabLabel: false,
      showIcons: false,
      searchActive: false
    };
  },
  methods: {
    toggleDrawer() {
      this.$store.dispatch("navigation/toggleDrawer");
    },
    querySelections: _.debounce(function(query) {
      this.loadingSearch = true;
      this.api.Search.query({ query }).then(({ body }) => {
        this.searchItems = body;
        this.loadingSearch = false;
      });
    }, 500),

    tabUpdate() {
      this.$emit('tabsChanged', this.localTabs);
      this.localActiveTabInd = this.localTabs.indexOf(this.activeTab);
    },
    toggleTabs() {
      this.tabsVisible = !this.tabsVisible;
      this.tabsToggleIcon = this.tabsVisible? "mdi-menu-down" : "mdi-menu-up";
      this.activeTabLabel = this.tabsVisible? false : true;
    },
    openCommunity() {
      const url = 'https://community.beach.io/c/products/vulcan/';
      window.open(url, '_blank');
    }
  },
  watch: {
    tabs: {
      handler() {
        if (JSON.stringify(this.localTabs) != JSON.stringify(this.tabs))
          this.localTabs = deepClone(this.tabs);
      },
      deep: true
    },
    localActiveTabInd () {
      this.activeTab = this.localTabs[this.localActiveTabInd];
      if (this.activeTab)
        this.$emit('activeTabChanged', this.localActiveTabInd);
    },
    activeTabInd (value) {
      this.localActiveTabInd = value;
    },
    searchText(val) {
      val && val !== this.selectSearch && this.querySelections(val);
    },
    selectSearch(val) {
      // console.log("which val", val)
      this.api.Search.navigateTo(val).then(({ body }) => {
        // console.log("found the url", body.url);
        this.$router.push(body.url);
      });
    }
  }
};
</script>

<style scoped>
.tabs-container {
  width: calc(100% + 32px);
  margin-left: -16px;
  margin-right: -16px;
  background-color: #f8f8f8;
}

.search {
  margin: 0 auto;
}

.search >>> .v-input__slot {
  background-color: #f5f5f5 !important;
}

.search.v-input--is-focused >>> .v-input__slot {
  background-color: #fff !important;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1) !important;
}

/* Updated dropdown styles */
.v-application >>> .v-menu__content.v-autocomplete__content {
  margin-top: -1px !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
  width: calc(100% - 24px) !important;
  transform: translateX(12px) !important;
}

.search >>> .search-message {
  padding: 16px;
  text-align: center;
}
</style>
<template>
  <v-toolbar dense no-padding>
    <v-btn-toggle group borderless v-model="openedData">
      <v-btn text style="opacity: 1" class="ma-0" value="type">
        Type
      </v-btn>

      <v-divider vertical />

      <v-btn text style="opacity: 1" class="ma-0" value="collection">
        Collection
      </v-btn>

      <v-divider vertical />

      <v-btn text style="opacity: 1" class="ma-0" value="advanced">
        Advanced
      </v-btn>
    </v-btn-toggle>

    <v-divider vertical />

    <slot name="menumore" />
  </v-toolbar>
</template>

<script>
import Vue from 'vue';
import ObjectPropertyEditorExtend from '../../_extends/ObjectPropertyEditor';

export default {
  name: 'Workflow_LoopObjectPropertyEditor',
  extends: ObjectPropertyEditorExtend,

  data() {
    const cmpType = Vue.extend({
      template: `
        <div>
          <v-select
            v-model="settings.loopType"
            :items="loopTypes"
            label="Loop Type"
            :readonly="readonly"
          />
          
          <v-alert
            type="info"
            text
            dense
            class="mt-4"
          >
            <strong>Loop Types:</strong>
            <ul class="mt-2">
              <li><strong>For Each:</strong> Process items one at a time</li>
              <li><strong>Batch:</strong> Process items in groups</li>
              <li><strong>Pagination:</strong> Process items page by page</li>
            </ul>
          </v-alert>
        </div>
      `,
      props: ['settings', 'readonly'],
      data() {
        return {
          loopTypes: [
            { text: 'For Each Item', value: 'forEach' },
            { text: 'Batch Processing', value: 'batch' },
            { text: 'Pagination', value: 'pagination' }
          ]
        };
      }
    });

    const cmpCollection = Vue.extend({
      template: `
        <div>
          <v-text-field
            v-model="settings.collection"
            label="Collection Path"
            :readonly="readonly"
            hint="JSON path to your collection (e.g., data.items)"
            persistent-hint
          />
          
          <v-alert
            type="info"
            text
            dense
            class="mt-4"
          >
            <strong>Example Paths:</strong>
            <ul class="mt-2">
              <li><code>data.items</code> - Array in data.items</li>
              <li><code>response.results</code> - API response results</li>
              <li><code>state.collection</code> - Collection from state</li>
            </ul>
          </v-alert>
        </div>
      `,
      props: ['settings', 'readonly']
    });

    const cmpAdvanced = Vue.extend({
      template: `
        <div>
          <template v-if="settings.loopType === 'batch'">
            <v-text-field
              v-model.number="settings.batchSize"
              label="Batch Size"
              type="number"
              :readonly="readonly"
              min="1"
              hint="Number of items to process in each batch"
              persistent-hint
            />
          </template>
          
          <template v-if="settings.loopType === 'pagination'">
            <v-switch
              v-model="settings.pagination.enabled"
              label="Enable Pagination"
              :readonly="readonly"
            />
            
            <template v-if="settings.pagination.enabled">
              <v-text-field
                v-model.number="settings.pagination.pageSize"
                label="Page Size"
                type="number"
                :readonly="readonly"
                min="1"
                hint="Number of items per page"
                persistent-hint
              />
              
              <v-text-field
                v-model.number="settings.pagination.currentPage"
                label="Start Page"
                type="number"
                :readonly="readonly"
                min="1"
                hint="Initial page number"
                persistent-hint
              />
            </template>
          </template>

          <v-alert
            v-if="settings.loopType === 'batch'"
            type="info"
            text
            dense
            class="mt-4"
          >
            Batch processing will group items into sets of {{ settings.batchSize }} 
            items and process them together for better performance.
          </v-alert>

          <v-alert
            v-if="settings.loopType === 'pagination' && settings.pagination.enabled"
            type="info"
            text
            dense
            class="mt-4"
          >
            Pagination will process {{ settings.pagination.pageSize }} items at a time,
            starting from page {{ settings.pagination.currentPage }}.
          </v-alert>
        </div>
      `,
      props: ['settings', 'readonly']
    });

    return {
      cmpType,
      cmpCollection,
      cmpAdvanced,
      openedData: null
    };
  },

  methods: {
    onOpenType() {
      this.$root.$emit('DataEditor.open', {
        component: this.cmpType,
        props: {
          settings: this.localSettings,
          readonly: this.readonly
        },
        title: 'Loop Type'
      });
    },

    onOpenCollection() {
      this.$root.$emit('DataEditor.open', {
        component: this.cmpCollection,
        props: {
          settings: this.localSettings,
          readonly: this.readonly
        },
        title: 'Collection Configuration'
      });
    },

    onOpenAdvanced() {
      this.$root.$emit('DataEditor.open', {
        component: this.cmpAdvanced,
        props: {
          settings: this.localSettings,
          readonly: this.readonly
        },
        title: 'Advanced Settings'
      });
    }
  },

  watch: {
    openedData(value) {
      switch (value) {
        case 'type':
          this.onOpenType();
          break;
        case 'collection':
          this.onOpenCollection();
          break;
        case 'advanced':
          this.onOpenAdvanced();
          break;
        default:
          this.$root.$emit('DataEditor.close');
      }
    }
  },

  mounted() {
    this.$root.$on('DataEditor.close', this.onDataEditorClose);
    this.onOpenType();
  },

  beforeDestroy() {
    this.$root.$off('DataEditor.close', this.onDataEditorClose);
  }
};
</script> 
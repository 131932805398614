var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.wrapper,{tag:"component",attrs:{"title":"Open Project","showSubmit":false}},[_c('v-layout',[_c('v-flex',{attrs:{"sm12":""}},_vm._l((_vm.sortedProjects),function(project){return _c('v-card',{key:project.id,staticClass:"projects-list-item",attrs:{"flat":""}},[_c('v-card-title',{staticClass:"project-title"},[_c('img',{attrs:{"height":"66","src":_vm.projectImage(project)}}),_c('div',{staticClass:"project-header"},[_c('span',{staticClass:"headline font-weight-light"},[_vm._v(_vm._s(project.name))]),_c('span',{staticClass:"project-meta"},[_c('span',{staticClass:"chart-count"},[_vm._v(_vm._s(project.charts.length)+" charts")])])]),_c('v-spacer'),_c('v-menu',{attrs:{"bottom":"","float-left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("more_vert")])],1)]}}],null,true)},[_c('v-list',_vm._l((_vm.menuItems),function(menuItem,i){return _c('v-list-item',{key:i,on:{"click":function($event){menuItem.callback ? menuItem.callback(project) : null}}},[_c('v-list-item-title',{class:menuItem.color},[_vm._v(_vm._s(menuItem.title))])],1)}),1)],1)],1),_c('v-container',{attrs:{"grid-list-md":""}},[_c('v-layout',{attrs:{"wrap":""}},_vm._l((_vm.sortedCharts(project.charts)),function(chart){return _c('v-flex',{key:chart.id,attrs:{"xs12":"","md3":""}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"projects-list-item__chart",class:{
                  'on-hover': hover,
                  'is-widget': chart.widget
                },attrs:{"elevation":hover ? 12 : 2,"light":""},on:{"click":function($event){return _vm.open({ chartId: chart.id, projectId: project.id })}}},[_c('v-img',{attrs:{"src":_vm.chartPlaceholderImage(project),"aspect-ratio":"2.75"}}),_c('v-card-title',[_c('v-layout',[_c('v-flex',[_c('div',{staticClass:"chart-header"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(chart.name))]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [(chart.widget)?_c('v-chip',_vm._g(_vm._b({staticClass:"embed-badge",attrs:{"x-small":"","color":"success"}},'v-chip',attrs,false),on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("code")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Embed enabled")])])],1),_c('v-divider'),_c('div',{staticClass:"chart-meta"},[_c('div',{staticClass:"chart-time"},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("update")]),_vm._v(" Last updated "+_vm._s(_vm.friendlyTime(chart.last_update))+" ")],1)])],1)],1)],1)],1)]}}],null,true)})],1)}),1)],1)],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
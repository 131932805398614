<template>
  <div class="chatbot-action">
    <div v-if="action">
      <div class="action-name">{{ action.value }}</div>
      <div class="action-description" v-if="action.description">{{ action.description }}</div>
    </div>
    <div v-else>(empty)</div>
  </div>
</template>

<script>
import ExtendsComponent from '../../_extends/ObjectComponent';
import { getActions } from './actions';

export default {
  name: 'Chatbot_ActionObject',
  extends: ExtendsComponent,
  computed: {
    action() {
      return this.localSettings.action || '';
    },
    actionDescription() {
      const actions = getActions(this.$root.$data.projectSettings);
      return actions[this.action] || '';
    }
  }
}
</script>

<style scoped>
.chatbot-action {
  border: 3px solid rgb(132, 132, 132);
  padding: 5px;
  min-width: 250px;
  border-radius: 5px;
  cursor: default;
  background: white;
}

.action-description {
  font-size: 0.8em;
  color: #666;
  margin-top: 2px;
}
</style>
<template>
  <div>
    <v-container>
      <v-sheet
        rounded
        class="pa-4"
      >
      <v-layout>
        <v-flex xs12>
          <div class="title">{{mode == 'new' ? 'Create your new project' : ''}}</div>
          <p>{{mode == 'new' ? 'This is where you and your team organise canvases around a common topic or goal.' : ''}}</p>
          <v-text-field
            autofocus
            @keyup.enter.native="$emit('submit')"
            type="text"
            v-model="localParams.name"
            label="Project name"
            class="headline"
            filled
          />
        </v-flex>
      </v-layout>
    <div v-if="showActions" class="mt-4">
      <v-btn x-large block class="no-uppercase font-weight-black" color="primary" @click="$emit('submit')">
        {{mode == 'new' ? 'Create Project' : 'Save Project'}}
      </v-btn>
    </div>
  </v-sheet>
  </v-container>
  <v-container class="mt-8">
    <v-sheet class="pa-4">
        <div class="title">
            Advanced Settings 
        </div>    
        
        <div class="mb-4">Advanced Project settings include managing access and team members, custom colour pallets, preferences and integration credentials.</div>
      
      <v-tabs background-color="white" v-model="activeTab">
        <v-tabs-slider color="primary"/>
        <v-tab>Access</v-tab>
        <v-tab>Credentials</v-tab>
        <v-tab>Preferences</v-tab>
        <v-tab>Endpoints</v-tab>
      </v-tabs>
      <v-tabs-items v-model="activeTab">
        <v-tab-item>
          <Members :members.sync="localParams.members" :projectId="localParams.id" />
        </v-tab-item>
        <v-tab-item>
          <Variables class="mt-5" @save="setCustomCredentials" :vars="localParams.settings.credentials.custom"/>
        </v-tab-item>
        <v-tab-item>
          <v-expansion-panels class="mt-5">
            <v-expansion-panel>
              <v-expansion-panel-header>Preferences</v-expansion-panel-header>
              <v-expansion-panel-content>
                  <div class="variables_wrapper">
                    <Variables @save="setCustomPreferences" :vars="localParams.settings.preferences.custom"/>
                  </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>Colour Pallet</v-expansion-panel-header>
              <v-expansion-panel-content>
                  <div class="variables_wrapper">
                    <Variables @save="setCustomColors" :vars="localParams.settings.colors.custom" show-vars />
                  </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>Custom Actions</v-expansion-panel-header>
              <v-expansion-panel-content>
                  <div class="variables_wrapper">
                    <Variables @save="setCustomActions" :vars="localParams.settings.actions.custom" show-vars />
                  </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-tab-item>
        <v-tab-item>
          <Endpoints v-model="localParams.settings.endpoints"/>
        </v-tab-item>
      </v-tabs-items>
    </v-sheet>
  </v-container>
</div>
</template>

<script>
import { deepClone } from "@/lib";
import Credentials from "./Credentials";
import Preferences from "./Preferences";
import Endpoints from "./Endpoints";
import Members from "./Members";

export default {
  name: "ProjectForm",
  components: {
    ...Credentials,
    ...Endpoints,
    ...Preferences,
    ...Members
  },
  props: {
    params: Object,
    mode: String,   
    showActions: Boolean
  },
  mounted() {
    this.$nextTick(function() {
      this.$el.querySelector("input[autofocus]").focus();
    });
  },
  data() {
    const localParams = deepClone(this.params);
    if (!localParams.settings.credentials) {
      localParams.settings.credentials = {};
    }
    if (!localParams.settings.preferences) {
      localParams.settings.preferences = {};
    }
    if (!localParams.settings.colors) {
      localParams.settings.colors = {};
    }
    if (!localParams.settings.actions) {
      localParams.settings.actions = {};
    }

    return {
      localParams,
      activeTab: 0,
      types: [
        {
          name: "AnalysisTools",
          title: "Visual Knowledgebase",
          label: "Start researching, visualising, planning and collaborating",
          disabled: false
        }
      ],
      submitBtn: {
        edit: "Update",
        new: "Create"
      },
      alert: true
    };
  },
  methods: {
    setCustomCredentials($event) {
      this.localParams.settings.credentials = {
        ...this.localParams.settings.credentials,
        custom: $event
      };
    },
    setCustomPreferences($event) {
      this.localParams.settings.preferences = {
        ...this.localParams.settings.preferences,
        custom: $event
      };
    },
    setCustomColors($event) {
      this.localParams.settings.colors = {
        ...this.localParams.settings.colors,
        custom: $event
      };
    },
    setCustomActions($event) {
      this.localParams.settings.actions = {
        ...this.localParams.settings.actions,
        custom: $event
      };
    }
  },
  watch: {
    localParams: {
      handler() {
        this.$emit("update:params", this.localParams);
      },
      deep: true,
      immediate: true
    }
  }
};
</script>

<style>

.project-type {
  margin: 10px;
  text-align: center;
}

.project-types__title {
  text-align: center;
}

.project-types__subtitle {
  text-transform: capitalize;
  font-size: 0.8em;
}

</style>
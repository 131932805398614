const defaultSettings = {
  name: "Error Handler",
  icon: 'mdi-alert-circle-outline',
  showLabel: true,
  size: {
    width: 'auto',
    height: 96
  },
  resizable: false,
  draggable: true,
  has: {
    in: true,
    out: true,
    settings: true
  },
  inColor: "#F44336",  // Red for error handling
  outColor: "#F44336",
  layout: "horizontal",
  components: [/Workflow_.*/],
  openPropertyEditorByClick: true,
  settings: {
    locked: false,
    order: 0,
    retryConfig: {
      enabled: true,
      maxAttempts: 3,
      backoffType: 'exponential',
      backoffDelay: 1000
    },
    fallbackAction: '', // Optional fallback action
    logErrors: true
  }
};

export default defaultSettings; 
const defaultSettings = {
  name: "Loop",
  icon: 'mdi-repeat',
  showLabel: true,
  size: {
    width: 'auto',
    height: 60
  },
  resizable: false,
  draggable: true,
  has: {
    in: true,
    out: true,
    settings: true
  },
  inColor: "#FF5722",  // Deep orange for loops
  outColor: "#FF5722",
  layout: "horizontal",
  components: [/Workflow_.*/],
  openPropertyEditorByClick: true,
  settings: {
    locked: false,
    order: 0,
    loopType: 'forEach', // forEach, batch, pagination
    collection: '', // JSON path to collection
    batchSize: 10,
    pagination: {
      enabled: false,
      pageSize: 10,
      currentPage: 1
    }
  }
};

export default defaultSettings; 
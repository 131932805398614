<template>
  <div 
    v-show="visible" 
    class="floating-uploader"
    :style="{
      left: adjustedPosition.x + 'px',
      top: adjustedPosition.y + 'px'
    }"
  >
    <Uploader
      width="40px"
      height="40px"
      :disabled="readonly"
      label="Image"
      icon="add_photo_alternate"
      :value="currentImage"
      @input="onImageUpdate"
    />
  </div>
</template>

<script>
import Uploader from '@/components/_Global/Uploader.vue'

export default {
  name: 'FloatingUploader',
  
  components: {
    Uploader
  },

  data() {
    return {
      visible: false,
      position: { x: 0, y: 0 },
      baseObjectId: null,
      settings: {},
      readonly: false
    }
  },

  computed: {
    currentImage() {
      return this.settings?.image || {}
    },

    viewport() {
      return this.$store.state.chart.viewport
    },

    adjustedPosition() {
      // Get viewport scale and translate from store
      const scale = this.$store.state.chart.scale || 1
      const translate = this.$store.state.chart.translate || { x: 0, y: 0 }
      
      // Get viewport element bounds
      const viewportEl = document.querySelector('.viewport')
      const viewportRect = viewportEl ? viewportEl.getBoundingClientRect() : { left: 0, top: 0 }

      return {
        x: this.position.x + viewportRect.left,
        y: this.position.y + viewportRect.top
      }
    }
  },

  mounted() {
    this.$root.$on('Uploader.show', this.show);
    document.addEventListener('click', this.hide);
  },

  beforeDestroy() {
    this.$root.$off('Uploader.show', this.show);
    document.removeEventListener('click', this.hide);
  },

  methods: {
    show(data) {
      console.log('Uploader.show event received', data);
      this.position = {
        x: data.position.x,
        y: data.position.y
      };
      this.baseObjectId = data.baseObjectId;
      this.settings = data.settings;
      this.readonly = data.readonly;
      this.visible = true;
    },

    hide(event) {
      // Don't hide if clicking inside the uploader or its modal
      if (event && (
        event.target.closest('.floating-uploader') ||
        event.target.closest('.uploader-modal')
      )) {
        return;
      }
      this.visible = false;
    },

    onImageUpdate(value) {
      if (!this.baseObjectId) return;
      
      const updatedSettings = {
        ...this.settings,
        image: value
      };

      console.log('Updating settings:', {
        id: this.baseObjectId,
        settings: updatedSettings
      });
      
      this.$store.dispatch('object/updateSettings', {
        id: this.baseObjectId,
        settings: updatedSettings
      });
    }
  }
}
</script>

<style scoped lang="scss">
.floating-uploader {
  position: fixed;
  z-index: 9999;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  padding: 8px;

  :deep(.uploader-modal) {
    position: fixed;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    margin: auto;
  }
}
</style> 
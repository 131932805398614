export const menuConfig = {
  sections: [
    {
      id: 'info',
      title: 'Info & Settings',
      items: [
        {
          id: 'viewInfo',
          title: 'View Info',
          action: 'view-info',
          disabledWhen: 'readonly',
          icon: 'mdi-information'
        },
        {
          id: 'notionToggle',
          title: ({ settings }) => `${settings.notionEnabled ? 'Disable' : 'Enable'} Editor`,
          action: 'notion-toggle',
          disabledWhen: 'readonly',
          showWhen: ({ defaultProperties }) => defaultProperties?.notionOption,
          icon: 'mdi-notebook'
        }
      ]
    },
    {
      id: 'objectManipulation',
      title: 'Object Manipulation',
      items: [
        {
          id: 'changeType',
          title: ({ objectType }) => `Change ${objectType === 'Base_SymbolObject' ? 'symbol' : 'type'}`,
          action: 'change-type',
          disabledWhen: 'readonly',
          icon: 'mdi-shape'
        },
        {
          id: 'createSymbol',
          title: ({ baseObject }) => baseObject?.symbol_name ? 'Change symbol name' : 'Create symbol',
          action: 'create-symbol',
          disabledWhen: 'readonly',
          showWhen: ({ objectType }) => objectType !== 'Base_SymbolObject',
          icon: 'mdi-star-outline'
        }
      ]
    },
    {
      id: 'clipboard',
      title: 'Clipboard',
      items: [
        {
          id: 'copyLink',
          title: 'Copy link',
          action: 'copy-link',
          icon: 'mdi-link'
        },
        {
          id: 'copyId',
          title: 'Copy ID',
          action: 'copy-id',
          icon: 'mdi-identifier'
        }
      ]
    },
    {
      id: 'layerControls',
      title: 'Layer Controls',
      items: [
        {
          id: 'lock',
          title: ({ locked }) => locked ? 'Unlock' : 'Lock',
          action: 'lock',
          disabledWhen: ({ baseObject }) => !baseObject,
          hotkey: ({ modKey2 }) => `${modKey2} + L`,
          icon: 'mdi-lock'
        },
        {
          id: 'bringForward',
          title: 'Bring forward',
          action: 'bring-forward',
          disabledWhen: ({ readonly, orderForwardDisabled }) => readonly || orderForwardDisabled,
          hotkey: 'Alt + ↑',
          showWhen: ({ objectType }) => objectType !== 'Base_GroupObject' && objectType !== 'Base_CommentObject',
          icon: 'mdi-arrow-up-bold'
        },
        {
          id: 'sendBackward',
          title: 'Send backward',
          action: 'send-backward',
          disabledWhen: ({ readonly, orderBackDisabled }) => readonly || orderBackDisabled,
          hotkey: 'Alt + ↓',
          showWhen: ({ objectType }) => objectType !== 'Base_GroupObject' && objectType !== 'Base_CommentObject',
          icon: 'mdi-arrow-down-bold'
        },
        {
          id: 'bringToFront',
          title: 'Bring to front',
          action: 'bring-to-front',
          disabledWhen: ({ readonly, orderForwardDisabled }) => readonly || orderForwardDisabled,
          hotkey: 'Alt + Shift + ↑',
          showWhen: ({ objectType }) => objectType !== 'Base_GroupObject' && objectType !== 'Base_CommentObject',
          icon: 'mdi-arrow-up-bold-box'
        },
        {
          id: 'sendToBack',
          title: 'Send to back',
          action: 'send-to-back',
          disabledWhen: ({ readonly, orderBackDisabled }) => readonly || orderBackDisabled,
          hotkey: 'Alt + Shift + ↓',
          showWhen: ({ objectType }) => objectType !== 'Base_GroupObject' && objectType !== 'Base_CommentObject',
          icon: 'mdi-arrow-down-bold-box'
        }
      ]
    },
    {
      id: 'grouping',
      title: 'Grouping',
      items: [
        {
          id: 'createGroup',
          title: 'Create group',
          action: 'create-group',
          disabledWhen: 'readonly',
          showWhen: ({ inGroup, objectType }) => !inGroup && objectType !== 'Base_GroupObject',
          icon: 'mdi-folder-plus'
        },
        {
          id: 'extractFromGroup',
          title: 'Extract from group',
          action: 'extract-from-group',
          disabledWhen: 'readonly',
          showWhen: ({ inGroup, objectType }) => inGroup && objectType !== 'Base_GroupObject',
          icon: 'mdi-folder-remove'
        },
        {
          id: 'saveAsTemplate',
          title: 'Save as template',
          action: 'save-as-template',
          showWhen: ({ objectType }) => objectType === 'Base_GroupObject',
          icon: 'mdi-content-save-outline'
        },
        {
          id: 'ungroup',
          title: 'Ungroup',
          action: 'ungroup',
          showWhen: ({ objectType }) => objectType === 'Base_GroupObject',
          icon: 'mdi-folder-open'
        }
      ]
    },
    {
      id: 'actions',
      title: 'Actions',
      items: [
        {
          id: 'duplicate',
          title: 'Duplicate',
          action: 'duplicate',
          hotkey: ({ modKey2 }) => `${modKey2} + D`,
          icon: 'mdi-content-duplicate'
        },
        {
          id: 'delete',
          title: 'Delete',
          action: 'delete',
          disabledWhen: 'readonly',
          hotkey: 'Delete',
          icon: 'mdi-delete'
        }
      ]
    }
  ]
}; 
<template>
  <Modal @submit="submit" title="Edit Project" :showSubmit="!!localParams.name" submitText="Save">
    <div class="modal-content-wrapper">
      <ProjectForm mode="edit" :params.sync="localParams" @submit="submit" @close="close" class="ma-3" />
    </div>
  </Modal>
</template>

<script>
import ProjectForm from './_Form'
import { deepClone } from '@/lib'

export default {
  name: 'EditProject',
  props: {
    params: Object,
    callback: Function
  },
  components: {
    ProjectForm
  },
  data() {
    return {
      localParams: deepClone(this.params || {})
    }
  },
  methods: {
    submit() {
      if (this.callback)
        this.callback(this.localParams);
      this.close();
    },
    close() {
      this.$emit('close')
    }
  }
}
</script>
<style scoped>
.modal-content-wrapper {
  max-height: calc(100vh - 200px);
  overflow-y: auto;
}
</style>
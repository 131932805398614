const defaultSettings = {
  name: "Trigger",
  icon: 'mdi-play-circle-outline',
  showLabel: true,
  size: {
    width: 'auto',
    height: 40
  },
  resizable: false,
  draggable: true,
  has: {
    in: false,  // No input as this is an entry point
    out: true,
    settings: true
  },
  outColor: "#4CAF50",  // Green for start/trigger
  layout: "horizontal",
  components: [/Workflow_.*/],
  openPropertyEditorByClick: true,
  settings: {
    locked: false,
    order: 0,
    triggerType: 'manual', // manual, scheduled, webhook, event
    schedule: '',  // For cron-based scheduling
    webhookPath: '',  // For webhook triggers
    eventName: '',    // For event-based triggers
    description: ''   // Optional description of the trigger
  }
};

export default defaultSettings; 
<template>
  <v-toolbar dense no-padding>
    <v-btn-toggle group borderless v-model="openedData">
      <v-btn text style="opacity: 1" class="ma-0" value="request">
        Request
      </v-btn>

      <v-divider vertical />

      <v-btn text style="opacity: 1" class="ma-0" value="auth">
        Auth
      </v-btn>

      <v-divider vertical />

      <v-btn text style="opacity: 1" class="ma-0" value="advanced">
        Advanced
      </v-btn>
    </v-btn-toggle>

    <v-divider vertical />

    <slot name="menumore" />
  </v-toolbar>
</template>

<script>
import Vue from 'vue';
import ObjectPropertyEditorExtend from '../../_extends/ObjectPropertyEditor';

export default {
  name: 'Workflow_APIObjectPropertyEditor',
  extends: ObjectPropertyEditorExtend,

  data() {
    const cmpRequest = Vue.extend({
      template: `
        <div>
          <v-select
            v-model="settings.method"
            :items="httpMethods"
            label="HTTP Method"
            :readonly="readonly"
          />
          
          <v-text-field
            v-model="settings.url"
            label="URL"
            :readonly="readonly"
            hint="Full URL including protocol (https://)"
            persistent-hint
          />
          
          <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-header>Headers</v-expansion-panel-header>
              <v-expansion-panel-content>
                <div v-for="(value, key) in settings.headers" :key="key" class="d-flex mb-2">
                  <v-text-field
                    v-model="headerKeys[key]"
                    label="Header"
                    class="mr-2"
                    :readonly="readonly"
                    @input="updateHeader(key, value)"
                  />
                  <v-text-field
                    v-model="settings.headers[key]"
                    label="Value"
                    :readonly="readonly"
                  />
                  <v-btn icon @click="removeHeader(key)" :disabled="readonly">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </div>
                <v-btn
                  text
                  color="primary"
                  @click="addHeader"
                  :disabled="readonly"
                >
                  Add Header
                </v-btn>
              </v-expansion-panel-content>
            </v-expansion-panel>
            
            <v-expansion-panel>
              <v-expansion-panel-header>Body</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-textarea
                  v-model="settings.body"
                  label="Request Body"
                  :readonly="readonly"
                  rows="5"
                  hint="JSON format"
                  persistent-hint
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      `,
      props: ['settings', 'readonly'],
      data() {
        return {
          httpMethods: ['GET', 'POST', 'PUT', 'DELETE', 'PATCH'],
          headerKeys: { ...Object.keys(this.settings.headers).reduce((acc, key) => ({ ...acc, [key]: key }), {}) }
        };
      },
      methods: {
        addHeader() {
          Vue.set(this.settings.headers, `header${Object.keys(this.settings.headers).length + 1}`, '');
        },
        removeHeader(key) {
          Vue.delete(this.settings.headers, key);
          Vue.delete(this.headerKeys, key);
        },
        updateHeader(oldKey, value) {
          const newKey = this.headerKeys[oldKey];
          if (newKey !== oldKey) {
            Vue.delete(this.settings.headers, oldKey);
            Vue.set(this.settings.headers, newKey, value);
          }
        }
      }
    });

    const cmpAuth = Vue.extend({
      template: `
        <div>
          <v-select
            v-model="settings.authentication.type"
            :items="authTypes"
            label="Authentication Type"
            :readonly="readonly"
          />
          
          <template v-if="settings.authentication.type === 'basic'">
            <v-text-field
              v-model="settings.authentication.username"
              label="Username"
              :readonly="readonly"
            />
            <v-text-field
              v-model="settings.authentication.password"
              label="Password"
              type="password"
              :readonly="readonly"
            />
          </template>
          
          <template v-if="settings.authentication.type === 'bearer'">
            <v-text-field
              v-model="settings.authentication.token"
              label="Token"
              :readonly="readonly"
            />
          </template>
          
          <template v-if="settings.authentication.type === 'custom'">
            <v-textarea
              v-model="customAuthString"
              label="Custom Authentication"
              :readonly="readonly"
              rows="4"
              hint="JSON format"
              persistent-hint
              @input="updateCustomAuth"
            />
          </template>
        </div>
      `,
      props: ['settings', 'readonly'],
      data() {
        return {
          authTypes: [
            { text: 'None', value: 'none' },
            { text: 'Basic Auth', value: 'basic' },
            { text: 'Bearer Token', value: 'bearer' },
            { text: 'Custom', value: 'custom' }
          ],
          customAuthString: JSON.stringify(this.settings.authentication.custom || {}, null, 2)
        };
      },
      methods: {
        updateCustomAuth(value) {
          try {
            this.settings.authentication.custom = JSON.parse(value);
          } catch (e) {
            // Invalid JSON - could handle this with validation
          }
        }
      }
    });

    const cmpAdvanced = Vue.extend({
      template: `
        <div>
          <v-switch
            v-model="settings.retryConfig.enabled"
            label="Enable Retry"
            :readonly="readonly"
          />
          
          <template v-if="settings.retryConfig.enabled">
            <v-text-field
              v-model.number="settings.retryConfig.maxAttempts"
              label="Max Attempts"
              type="number"
              :readonly="readonly"
            />
            
            <v-select
              v-model="settings.retryConfig.backoffType"
              :items="backoffTypes"
              label="Backoff Type"
              :readonly="readonly"
            />
            
            <v-text-field
              v-model.number="settings.retryConfig.backoffDelay"
              label="Backoff Delay (ms)"
              type="number"
              :readonly="readonly"
            />
          </template>
          
          <v-text-field
            v-model.number="settings.timeout"
            label="Timeout (ms)"
            type="number"
            :readonly="readonly"
          />
          
          <v-switch
            v-model="settings.validateResponse"
            label="Validate Response Status"
            :readonly="readonly"
          />
          
          <v-combobox
            v-if="settings.validateResponse"
            v-model="settings.expectedStatus"
            label="Expected Status Codes"
            multiple
            chips
            :readonly="readonly"
            hint="Add status codes (e.g., 200, 201)"
            persistent-hint
          />
        </div>
      `,
      props: ['settings', 'readonly'],
      data() {
        return {
          backoffTypes: [
            { text: 'Fixed', value: 'fixed' },
            { text: 'Exponential', value: 'exponential' }
          ]
        };
      }
    });

    return {
      cmpRequest,
      cmpAuth,
      cmpAdvanced,
      openedData: null
    };
  },

  methods: {
    onOpenRequest() {
      this.$root.$emit('DataEditor.open', {
        component: this.cmpRequest,
        props: {
          settings: this.localSettings,
          readonly: this.readonly
        },
        title: 'Request Settings'
      });
    },

    onOpenAuth() {
      this.$root.$emit('DataEditor.open', {
        component: this.cmpAuth,
        props: {
          settings: this.localSettings,
          readonly: this.readonly
        },
        title: 'Authentication'
      });
    },

    onOpenAdvanced() {
      this.$root.$emit('DataEditor.open', {
        component: this.cmpAdvanced,
        props: {
          settings: this.localSettings,
          readonly: this.readonly
        },
        title: 'Advanced Settings'
      });
    }
  },

  watch: {
    openedData(value) {
      switch (value) {
        case 'request':
          this.onOpenRequest();
          break;
        case 'auth':
          this.onOpenAuth();
          break;
        case 'advanced':
          this.onOpenAdvanced();
          break;
        default:
          this.$root.$emit('DataEditor.close');
      }
    }
  },

  mounted() {
    this.$root.$on('DataEditor.close', this.onDataEditorClose);
    this.onOpenRequest();
  },

  beforeDestroy() {
    this.$root.$off('DataEditor.close', this.onDataEditorClose);
  }
};
</script> 
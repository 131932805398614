<template>
  <v-menu offset-y transition="slide-y-transition" :nudge-top="4">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        text
        v-bind="attrs"
        v-on="on"
        class="pa-0"
      >
        <v-list class="account-list">
          <v-list-item class="pa-0">
            <v-list-item-avatar class="account-avatar">
              <img v-if="avatar" :src="avatar" />
              <h2 v-else style="margin-bottom: 1px">{{capital}}</h2>
            </v-list-item-avatar>
          </v-list-item>
        </v-list>
      </v-btn>
    </template>

    <v-list class="account-dropdown">
      <v-list-item :to="{ name: 'settings-profile' }">
        <v-list-item-icon>
          <v-icon>mdi-account</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Profile</v-list-item-title>
      </v-list-item>

      <v-list-item :to="{ name: 'settings-configuration' }">
        <v-list-item-icon>
          <v-icon>mdi-settings</v-icon>
        </v-list-item-icon>
        <v-list-item-title>More account options</v-list-item-title>
      </v-list-item>

      <v-divider></v-divider>

      <v-list-item @click="navigateTo('components-library')">
        <v-list-item-icon>
          <v-icon>mdi-puzzle</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Build Plugins</v-list-item-title>
      </v-list-item>

      <v-list-item @click="navigateTo('connected-apps')">
        <v-list-item-icon>
          <v-icon>mdi-apps</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Manage Apps</v-list-item-title>
      </v-list-item>

      <v-divider></v-divider>

      <v-list-item @click="handleLogout">
        <v-list-item-icon>
          <v-icon>mdi-logout</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Sign Out</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "AppHeaderAccount",
  data() {
    return {
      activeOrganisation: null,
      individual: {
        id: 0,
        name: "Individual Account"
      }
    };
  },
  methods: {
    navigateTo(route) {
      switch(route) {
        case 'settings-profile':
          this.$router.push('/settings/profile');
          break;
        case 'settings-configuration':
          this.$router.push('/settings/configuration');
          break;
        case 'connected-apps':
          this.$router.push('/settings/connected-apps');
          break;
        case 'components-library':
          this.$router.push('/components-library');
          break;
      }
    },
    handleLogout() {
      this.$store.dispatch('application/logOut');
    }
  },
  computed: {
    ...mapGetters({
      visibleName: "user/visibleName"
    }),
    avatar() {
      if (this.currentUser?.profile) {
        return this.currentUser.profile.avatar_url;
      }
      return null;
    },
    capital() {
      return this.visibleName.charAt(0).toUpperCase();
    }
  }
};
</script>

<style scoped>
.account-list {
  padding: 0;
  background-color: transparent !important;
  cursor: pointer;
}

.account-avatar {
  background-color: #EBEBEB;
}

.v-list-item {
  background-color: transparent !important;
}

.v-list-item__content {
  padding-bottom: 0;
}

.account-dropdown {
  min-width: 250px;
  border-radius: 8px !important;
  overflow: hidden;
}

.v-btn {
  height: auto !important;
}

.v-btn::before {
  background-color: transparent !important;
}

/* Optional: Add hover effects for menu items */
.v-list-item:hover {
  background-color: #f5f5f5 !important;
}

/* Target the menu content wrapper */
:deep(.v-menu__content) {
  border-radius: 8px !important;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1) !important;
  overflow: hidden;
}
</style>

import { deepClone } from "@/lib";
import { PropertyEditors } from "../index";

export default {
  props: {
    id: String,
    type: String,
    settings: Object,
    baseObject: Object,
    readonly: Boolean
  },
  created() {
    if (this.settings._new) {
      this.openSettings();
    }
  },
  data() {
    return {
      localSettings: deepClone(this.settings)
    };
  },
  methods: {
    openSettings() {
      this.$root.$emit("PropertyEditor.open", {
        component: PropertyEditors[`${this.type}PropertyEditor`],
        props: {
          baseObjectId: this.baseObject.id,
          settings: this.localSettings,
          id: this.id,
          callback: this.save,
          readonly: this.readonly
        }
      });
    },
    save(newSettings) {
      this.localSettings = deepClone({...newSettings, _new: false});
      this.$emit("update:settings", {...newSettings, _new: false});
    }
  },
  watch: {
    localSettings: {
      handler() {
        if (JSON.stringify(this.localSettings) != JSON.stringify(this.settings))
          this.$emit("update:settings", this.settings);
      },
      deep: true
    },
    settings: {
      handler() {
        this.localSettings = deepClone(this.settings);
      },
      deep: true
    }
  }
};

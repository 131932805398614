<template>
  <div>
    <Separator
        @add="openControlPicker()"
        :label="label"
        :actions="['add']"
        :readonly="readonly"
    />

    <div @mousedown.stop>
      <draggable class="object-controls" :class="type" v-model="localControls">
        <div
            class="object_control"
            :class="type"
            :key="control.id"
            v-for="control in localControls"
            :style="{width: (control.settings.width || 100) + '%'}"
        >
          <component
              :is="control.type"
              :type="control.type"
              :baseObject="baseObject"
              :settings.sync="control.settings"
              :id="control.id"
              :readonly="readonly"
          >
            <template slot-scope="slotProps">
              <Separator
                  v-if="type == 'body'"
                  @delete="deleteControl(control.id)"
                  @settings="slotProps.openSettings"
                  child
                  :label="control.name"
                  :actions="['handle', 'settings', 'delete']"
                  :readonly="readonly"
              />
              <div v-if="type == 'actions'" class="delete-icon">
                <v-icon @click="deleteControl(control.id)" color="primary" size="30">mdi-delete-circle</v-icon>
              </div>
            </template>
          </component>
        </div>
      </draggable>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import {deepClone} from '@/lib';
import {Controls} from './Elements';
import ControlPicker from './ControlPicker';
import Separator from "./Separator";

export default {
  name: "ControlElements_Controls",
  components: {
    draggable,
    Separator,
    ...Controls
  },
  props: {
    group: String,
    controls: Array,
    label: String,
    type: String,
    customComponent: Boolean,
    baseObject: Object,
    readonly: Boolean
  },
  data() {
    return {
      localControls: deepClone(this.controls || [])
    };
  },
  methods: {
    deleteControl(controlId) {
      const index = this.localControls.findIndex(lc => lc.id == controlId);
      this.localControls.splice(index, 1);
      this.$root.$emit('PropertyEditor.close');
    },
    openControlPicker() {
      this.localControls = this.localControls.map(lc => ({...lc, new: false}))
      this.$emit('update:controls', this.localControls);
      this.$root.$emit('PropertyEditor.open', {
        component: ControlPicker,
        showSave: false,
        props: {
          baseObjectId: this.baseObject.id,
          type: this.type,
          controls: this.localControls,
          customComponent: this.customComponent,
          callback: (control) => {
            this.localControls.push(control);
            this.$emit('update:controls', this.localControls);
          }
        }
      })
    }
  },
  watch: {
    controls: {
      handler() {
        if (JSON.stringify(this.controls) != JSON.stringify(this.localControls))
          this.localControls = deepClone(this.controls || [])
      },
      deep: true
    },
    localControls: {
      handler() {
        if (JSON.stringify(this.controls) != JSON.stringify(this.localControls))
          this.$emit('update:controls', this.localControls)
      },
      deep: true
    }
  }
}
</script>

<style scoped>
.object-controls {
  margin-top: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.object-controls.body {
  flex-direction: column;
  align-items: center;
}

.object-controls.actions {
  flex-direction: row;
}

.object_control {
  position: relative;
  width: 100%;
}

.object_control.body {
  min-height: 60px;
  margin-top: 15px;
}

.object_control.actions {
  margin: 10px 10px 0px 10px;
}

.object_control:last-child {
  margin-right: 0;
}

.object_control:first-child {
  margin-left: 0;
}

.delete-icon {
  position: absolute;
  top: -12px;
  right: -12px;
  z-index: 5;
  border-radius: 100%;
  background: white;
  margin: 0 !important;
}
</style>

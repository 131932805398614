// Manages position calculations for pasted content
export class PositionManager {
    constructor(translate, scale, viewport, mouseCoordinates) {
      this.translate = translate;
      this.scale = scale;
      this.viewport = viewport;
      this.mouseCoordinates = mouseCoordinates;
    }
  
    calculateFinalPosition() {
      const rect = this.viewport.getBoundingClientRect();
      const defaultPosition = {
        x: (-(this.translate?.x || 0) + rect.width / 2) / (this.scale || 1),
        y: (-(this.translate?.y || 0) + rect.height / 2) / (this.scale || 1)
      };
  
      if (!this.mouseCoordinates || typeof this.mouseCoordinates.x === 'undefined' || typeof this.mouseCoordinates.y === 'undefined') {
        return defaultPosition;
      }
  
      const scrollX = window.pageXOffset || document.documentElement.scrollLeft;
      const scrollY = window.pageYOffset || document.documentElement.scrollTop;
  
      return {
        x: (this.mouseCoordinates.x + scrollX - rect.x - (this.translate?.x || 0)) / (this.scale || 1),
        y: (this.mouseCoordinates.y + scrollY - rect.y - (this.translate?.y || 0)) / (this.scale || 1)
      };
    }
  }
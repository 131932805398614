<template>
  <v-card v-if="opened && !hidden" class="data-editor pa-4 d-flex flex-column" :style="styleEl">
    <v-layout v-if="title" class="mb-2 ml-1">
      <v-flex grow pa-1>
        <h2>{{title}}</h2>
      </v-flex>
      <v-flex shrink pa-1>
        <v-btn text class="button-icon" @click="onPin">
          <v-icon>mdi-pin</v-icon>
        </v-btn>
        <v-btn text class="button-icon" @click="close">
          <v-icon>close</v-icon>
        </v-btn>
      </v-flex>
    </v-layout>
    <div class="content" :style="{maxHeight: title ? '300px' : '350px'}">
      <component
        maxHeight="350px"
        @updateSize="updateSize"
        ref="component"
        :is="component"
        v-bind="props"
        v-model="settings[dataField]"
        :value="props.value"
        :baseObject="baseObject"
        :settings="settings"
        :onPin="onPin"
        :onClose="close"
      />
    </div>
  </v-card>
</template>

<script>
import {getObjPosition, getObjSize} from "./lib";

export default {
  name: "DataEditor",

  props: {
    viewport: Object,
    scale: Number,
    translate: Object
  },

  data() {
    return {
      component: null,
      props: {},
      dataField: null,
      height: 0,
      width: 0,
      readyToShow: false,
      hidden: false,
      title: null
    };
  },

  computed: {
    opened() {
      return !!this.component;
    },
    baseObject() {
      return this.$store.getters["object/findById"](this.props.baseObjectId);
    },
    settings() {
      if (this.props && this.props.settings)
        return this.props.settings;
      if (this.baseObject)
        return this.baseObject.info.settings;
      return {};
    },
    styleEl() {
      if (this.readyToShow)
        return {
          left: this.position.x + "px",
          top: this.position.y + "px"
        };
      else
        return {
          visibility: "hidden"
        };
    },
    position() {
      const position = { x: 200, y: 150 };

      const obj = this.baseObject;
      const connection = this.props.baseConnection;

      const objPosition = getObjPosition(obj, connection),
        objSize = getObjSize(obj, connection);

      if (!objPosition || !objSize)
        return position;

      const viewportRect = this.viewport.$el.getBoundingClientRect();

      //top-left corner of element
      position.x = this.translate.x + viewportRect.x + objPosition.x * this.scale;
      position.y = this.translate.y + objPosition.y * this.scale;

      //x - left/right the element
      if (position.x + objSize.width * this.scale + 50 + this.width > viewportRect.width &&
        position.x - this.width > 0
      )
        position.x = Math.max(20, position.x - this.width - 50);
      else
        position.x = Math.min(
          position.x + objSize.width * this.scale + 50,
          viewportRect.width - this.width - 20
        );

      //y - correction
      position.y = Math.max(position.y, 20);
      position.y = Math.min(position.y, viewportRect.height - this.height - 20);

      position.x = Math.round(position.x);
      position.y = Math.round(position.y);
      return position;
    }
  },

  mounted() {
    this.$root.$on("DataEditor.open", this.onOpen);
    this.$root.$on("DataEditor.close", this.onClose);
    this.$root.$on("DataEditor.update", this.onUpdate);
    this.$root.$on("DataEditor.hide", this.onHide);
    this.$root.$on("DataEditor.unhide", this.onUnhide);
  },

  updated() {
    // Костыль
    if (Math.abs(this.height - this.$el.clientHeight) > 3)
      this.height = this.$el.clientHeight;
    if (Math.abs(this.width - this.$el.clientWidth) > 3)
      this.width = this.$el.clientWidth;
  },

  beforeDestroy() {
    this.$root.$off("DataEditor.open", this.onOpen);
    this.$root.$off("DataEditor.close", this.onClose);
    this.$root.$off("DataEditor.update", this.onUpdate);
    this.$root.$off("DataEditor.hide", this.onHide);
    this.$root.$off("DataEditor.unhide", this.onUnhide);
  },

  methods: {
  
    async onOpen({ component, props, title, dataField }) {
      // console.log('DataEditor.onOpen received:', { component, props, title, dataField });
      this.component = component;
      this.props = props;
      this.title = title;
      this.dataField = dataField;

      if (this.component === 'TwitterFeed') {
        // console.log('TwitterFeed props:', this.props);
        // console.log('TwitterFeed settings:', this.settings);
        // Ensure the settings are passed correctly
        if (!this.props.settings) {
          this.$set(this.props, 'settings', this.settings);
        }
        
      }

      this.hidden = false;
      await this.$nextTick();
      this.readyToShow = true;

    },

    onClose() {
      this.component = null;
      this.title = null;
      this.dataField = null;
      this.readyToShow = false;
    },

    onUpdate({ settings }) {
      if (this.props)
        this.props.settings = settings;
    },

    onHide() {
      if (!this.opened)
        return;
      this.hidden = true;
      this.readyToShow = false;
    },

    async onUnhide() {
      if (!this.opened)
        return;
      this.hidden = false;
      await this.$nextTick();
      this.readyToShow = true;
    },

    save() {
      this.$refs.component.save();
    },
    updateSize() {
      this.height = this.$el.clientHeight;
      this.width = this.$el.clientWidth;
    },
    onPin() {
      this.$root.$emit("PinnedContent.open", {
        baseObject: this.baseObject,
        props: this.props,
        component: this.component,
        title: this.title,
        dataField: this.dataField
      });
      this.close();
    },
    close() {
      this.$root.$emit("DataEditor.close");
    }
  }
};
</script>

<style scoped lang="scss">
.data-editor {
  position: absolute;
  z-index: 3;
  background: white;
  width: 500px;
  text-align: left;
  min-height: 200px;

  .content {
    overflow-y: auto;
  }
}
</style>
<template>
  <div class="template-object">
    <v-dialog v-model="modalOpened" width="80%">
      <v-card>
        <v-card-title>
          <v-layout class="template-object__head my-4" justify-space-between>
            <h2>Template Library</h2>
          </v-layout>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="2">
              <v-text-field
                outlined
                dense
                placeholder="Search for templates"
                type="text"
                v-model="search"
                width="200"
                prepend-inner-icon="mdi-magnify"
              >
            </v-text-field>
              <v-tabs v-model="tab" vertical>
                <v-tab class="no-uppercase font-weigth-bold">My templates</v-tab>
                <v-tab class="no-uppercase font-weigth-bold">General templates</v-tab>
              </v-tabs>
            </v-col>
            <v-col cols="10">
              <v-row class="templates-grid-bg mb-4 mr-2">
                  <v-col cols="12"
                    sm="3"
                    v-for="template in searchedTemplates"
                    :key="template.id">

                    <v-card
                      @click="selectTemplate(template)"
                      height="320"
                    >
                      <v-img
                        :src="template.settings.image"
                        height="150px"

                      >
                      </v-img>
                      <v-card-title class="font-weight-black" style="word-break: break-word; font-size: medium; line-height: 1.2;">
                        {{template.name}}
                      </v-card-title>
                      <v-card-subtitle>
                        by {{template.user ? template.user.username : ''}}
                      </v-card-subtitle>
                      <v-card-text
                        v-if="template.settings.objects"
                      >
                      <v-container>
                      <v-row >
                      <div>
                        {{template.settings.objects.length}} object{{template.settings.objects.length > 1 ? 's' : ''}}
                      </div>
                      <v-spacer></v-spacer>
                      <v-menu top origin="left left" transition="scale-transition">
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on">
                              more_vert
                            </v-icon>
                          </template>
                            <v-card>
                              <v-list dense>
                                <v-list-item
                                  v-if="template.status != 'submitted'"
                                  @click.stop="publish(template)"
                                  link
                                >
                                  <v-list-item-content>
                                    <v-list-item-title>Publish</v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                                <v-list-item disabled v-else>
                                  <v-list-item-content>
                                    <v-list-item-title><v-icon left color="primary">mdi-checkbox-marked-circle-outline</v-icon>Submitted!</v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                                <v-divider></v-divider>
                                <v-list-item
                                  @click.stop="destroy(template)"
                                  link
                                >
                                  <v-list-item-content>
                                    <v-list-item-title><v-icon left>mdi-delete</v-icon>Delete</v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                              </v-list>
                            </v-card>
                        </v-menu>
                      </v-row>
                      </v-container>
                      </v-card-text>
                    </v-card>
                  </v-col>
              </v-row>
              <v-row justify="center" v-if="searchedTemplates.length == 0">
                <v-container>
                  <v-row>
                    <v-col
                      cols="12"
                      md="4"
                    >
                      <v-skeleton-loader
                        v-bind="attrs"
                        type="card-avatar, article, actions"
                      ></v-skeleton-loader>

                      <v-skeleton-loader
                        v-bind="attrs"
                        type="date-picker"
                      ></v-skeleton-loader>
                    </v-col>

                    <v-col
                      cols="12"
                      md="4"
                    >
                      <v-skeleton-loader
                        v-bind="attrs"
                        type="article, actions"
                      ></v-skeleton-loader>

                      <v-skeleton-loader
                        v-bind="attrs"
                        type="table-heading, list-item-two-line, image, table-tfoot"
                      ></v-skeleton-loader>
                    </v-col>

                    <v-col
                      cols="12"
                      md="4"
                    >
                      <v-skeleton-loader
                        v-bind="attrs"
                        type="list-item-avatar, divider, list-item-three-line, card-heading, image, actions"
                      ></v-skeleton-loader>

                      <v-skeleton-loader
                        v-bind="attrs"
                        type="list-item-avatar-three-line, image, article"
                      ></v-skeleton-loader>
                    </v-col>
                  </v-row>
                </v-container>
              </v-row>
            </v-col>

          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ExtendsComponent from "../../_extends/ObjectComponent";
import { deepClone } from "@/lib";

export default {
  name: "Base_TemplateObject",
  extends: ExtendsComponent,
  data() {
    return {
      modalOpened: true,
      templatesByTab: {
        my_templates: [],
        general_templates: []
      },
      search: "",
      tab: 0,
      loading: false,
      closeOnClick: true,
      offset: true,
      attrs: {
        class: 'mb-6',
        boilerplate: true,
        elevation: 2,
      }
    };
  },
  mounted() {
    this.setChartTemplates();

  },
  methods: {
    async setChartTemplates() {
      this.loading = true;
      const { data } = await this.api.ChartTemplate.query();
      this.templatesByTab = data;
      this.loading = false;
    },
    async selectTemplate(template) {
      const newIds = {};

      const promises = template.settings.objects.map(o => {
        const patch = {};
        if (o.type ==="Chatbot_BotMessageObject" && o.info.settings) {
          ['actionsControls', 'bodyControls', 'messages'].forEach(type => {
            if (o.info.settings[type])
              patch[type] = o.info.settings[type].map(c => ({ ...c, id: this.guid() }));
          })
        }
        return this.$store
          .dispatch("object/create", {
            ...o,
            ...patch,
            position: {
              x: o.position.x + this.baseObject.position.x,
              y: o.position.y + this.baseObject.position.y
            }
          })
          .then(newObject => {
            newIds[o.id] = newObject.id;
          });
      });

      await Promise.all(promises);

      template.settings.connections.forEach(c => {
        this.$store.dispatch("connection/create", {
          ...c,
          from: newIds[c.from],
          to: newIds[c.to]
        });
      });

      template.settings.group.objectIds = template.settings.group.objectIds.map(
        t => newIds[t]
      );

      this.$store.dispatch("object/create", {
        type: "Base_GroupObject",
        openPropertyEditorAfterCreate: true,
        info: {
          settings: deepClone(template.settings.group)
        }
      });

      this.modalOpened = false;
    },
    async destroy(template) {
      if (confirm("Are you sure?")) {
        await this.api.ChartTemplate.delete({ id: template.id });
        this.setChartTemplates();
      }
    },
    async publish(template) {
      await this.api.ChartTemplate.publish(
        { id: template.id },
        { id: template.id }
      );
      this.setChartTemplates();
    }
  },
  computed: {
    searchedTemplates() {
      return this.templates.filter(t =>
        JSON.stringify(t)
          .toLowerCase()
          .includes(this.search.toLowerCase())
      );
    },
    templates() {
      return this.templatesByTab[this.namedTab];
    },
    namedTab() {
      return ["my_templates", "general_templates"][this.tab];
    }
  },
  watch: {
    modalOpened() {
      if (!this.modalOpened) {
        this.$store.dispatch("object/delete", this.baseObject.id);
      }
    }
  }
};
</script>

<style scoped>
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  position: absolute;
  width: 100%;
}

.font-weight-black {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  display: block;
}

.templates-grid-bg {
  background-color: #f4f4f4;
  border-radius: 8px;
}
</style>

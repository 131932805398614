import TheEditor from "@/components/Layout/Authorized/TheEditor";
import TheSettings from "@/components/Layout/Authorized/TheSettings";
import TheOrganisation from "@/components/Layout/Authorized/TheOrganisation";
import TheTeams from "@/components/Layout/Authorized/TheTeams";
import TheComponentsLib from "@/components/Layout/Authorized/TheComponentsLib";

import TheDashboard from "@/components/Layout/Authorized/TheDashboard";
import TheWidget from "@/components/Layout/NotAuthorized/TheWidget";
import TheSignIn from "@/components/Layout/NotAuthorized/SignForms/TheSignIn";
import TheSignUp from "@/components/Layout/NotAuthorized/SignForms/TheSignUp";
import TheForgotPassword from "@/components/Layout/NotAuthorized/SignForms/TheForgotPassword";
import Profile from "@/components/Layout/Authorized/TheSettings/Profile";
import Configuration from "@/components/Layout/Authorized/TheSettings/Configuration";
import OAuthConfig from "@/components/Layout/Authorized/TheSettings/OAuthConfig";

const routes = [
  {
    name: "dashboard",
    path: "/",
    component: TheDashboard,
    meta: { requiresAuth: true }
  },
  {
    name: "signIn",
    path: "/sign-in",
    component: TheSignIn,
    meta: { isAuth: true }
  },
  {
    name: "signUp",
    path: "/sign-up",
    component: TheSignUp,
    meta: { isAuth: true }
  },
  {
    name: "forgotPassword",
    path: "/forgot-password",
    component: TheForgotPassword,
    meta: { isAuth: true }
  },
  {
    name: "editorSlug",
    path: "/project/:projectId/chart/:chartId/:slug",
    component: TheEditor,
    meta: { requiresAuth: true }
  },
  {
    name: "editor",
    path: "/project/:projectId/chart/:chartId",
    component: TheEditor,
    meta: { requiresAuth: true }
  },
  {
    name: "widget",
    path: "/widget/:id",
    component: TheWidget
  },
  {
    name: "teams",
    path: "/teams",
    component: TheTeams,
    meta: { requiresAuth: true }
  },
  {
    name: "components_library",
    path: "/components-library",
    component: TheComponentsLib,
    meta: { requiresAuth: true }
  },
  {
    path: "/settings",
    component: TheSettings,
    meta: { requiresAuth: true },
    children: [
      {
        path: "",
        name: "settings",
        redirect: { name: "settings-profile" }
      },
      {
        path: "profile",
        name: "settings-profile",
        component: Profile
      },
      {
        path: "configuration",
        name: "settings-configuration",
        component: Configuration
      },
      {
        path: "connected-apps",
        name: "settings-connected-apps",
        component: OAuthConfig
      }
    ]
  },
  {
    name: "organisation",
    path: "/organisation",
    component: TheOrganisation,
    meta: { requiresAuth: true }
  }
];

export default routes;

<template>
  <canvas
    v-if="isVisible"
    ref="gridCanvas"
    class="grid-ruler-canvas"
  ></canvas>
</template>

<script>
export default {
  name: "GridRuler",
  props: {
    viewportDimension: Object,
    scale: Number,
    translate: {
      type: Object,
      default() {
        return { x: 0, y: 0 };
      }
    },
    gridSize: [String, Number],
    gridColor: String
  },
  data() {
    return {
      ctx: null,
      dpr: window.devicePixelRatio || 1,
    };
  },
  mounted() {
    this.initCanvas();
    this.drawGrid();
  },
  computed: {
    isVisible() {
      return Number(this.scale) >= 0.4;
    },
    dotSize() {
      if (this.scale < 0.6) return 1.0;
      if (this.scale < 0.8) return 1.5;
      return 2.5;
    },
    scaleFactor() {
      if (typeof this.gridSize === 'number') return this.gridSize;
      if (this.gridSize === "Small") return 20;
      if (this.gridSize === "Large") return 120;
      return 60; // default: "Medium"
    }
  },
  methods: {
    initCanvas() {
      if (!this.$refs.gridCanvas) return;
      
      const canvas = this.$refs.gridCanvas;
      this.ctx = canvas.getContext('2d');
      
      // Set canvas size accounting for device pixel ratio
      canvas.width = this.viewportDimension.width * this.dpr;
      canvas.height = this.viewportDimension.height * this.dpr;
      canvas.style.width = `${this.viewportDimension.width}px`;
      canvas.style.height = `${this.viewportDimension.height}px`;
      
      this.ctx.scale(this.dpr, this.dpr);
    },

    drawGrid() {
      if (!this.isVisible || !this.ctx || !this.$refs.gridCanvas) return;

      // Clear previous drawing
      this.ctx.clearRect(0, 0, this.viewportDimension.width, this.viewportDimension.height);

      const currentScale = this.scaleFactor * this.scale;
      const offsetX = this.translate.x % currentScale;
      const offsetY = this.translate.y % currentScale;
      
      // Set dot style
      this.ctx.fillStyle = '#D3D2D2';

      // Only iterate over visible area
      for (let x = -currentScale; x < this.viewportDimension.width + currentScale; x += currentScale) {
        for (let y = -currentScale; y < this.viewportDimension.height + currentScale; y += currentScale) {
          const dotX = x + offsetX;
          const dotY = y + offsetY;
          
          // Draw circle
          this.ctx.beginPath();
          this.ctx.arc(dotX, dotY, this.dotSize, 0, Math.PI * 2);
          this.ctx.fill();
        }
      }
    },

    clearCanvas() {
      if (this.ctx && this.$refs.gridCanvas) {
        this.ctx.clearRect(0, 0, this.viewportDimension.width, this.viewportDimension.height);
      }
    }
  },
  watch: {
    scale() {
      this.$nextTick(() => this.drawGrid());
    },
    translate() {
      this.$nextTick(() => this.drawGrid());
    },
    gridSize() {
      this.$nextTick(() => this.drawGrid());
    },
    isVisible(newValue) {
      if (newValue) {
        this.$nextTick(() => {
          this.initCanvas();
          this.drawGrid();
        });
      } else {
        this.clearCanvas();
      }
    },
    viewportDimension: {
      handler() {
        this.$nextTick(() => {
          this.initCanvas();
          this.drawGrid();
        });
      },
      deep: true
    }
  }
};
</script>

<style scoped>
.grid-ruler-canvas {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
}
</style>


const defaultSettings = {
  name: "Filter",
  icon: 'mdi-filter-variant',
  showLabel: true,
  size: {
    width: 'auto',
    height: 70
  },
  resizable: false,
  draggable: true,
  has: {
    in: true,
    out: true,
    settings: true
  },
  inColor: "#9C27B0",  // Purple for filters
  outColor: "#9C27B0",
  layout: "horizontal",
  components: [/Workflow_.*/],
  openPropertyEditorByClick: true,
  settings: {
    locked: false,
    order: 0,
    condition: '', // JavaScript expression for condition
    trueRoute: '', // Route for true condition
    falseRoute: '' // Route for false condition
  }
};

export default defaultSettings; 
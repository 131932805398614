<template>
  <div
      :class="[
        position ? `text-object--${position}` : '',
        {'text-object--empty': !localSettings[modelName] && !contentEditable}
      ]"
      class="text-object"
      :style="textObjectStyles"
      @dblclick.stop.prevent="setEditable"
  >
    <div
        class="text-object__placeholder"
        v-if="!localSettings[modelName] && !contentEditable"
    >{{ placeholder }}
    </div>
    <div
        class="text-object__content"
        ref="content"
        @mousedown="stopPropagationIfContentEditable"
        :contenteditable="contentEditable"
        @blur="onBlur"
        @input="update"
    />
  </div>
</template>

<script>
import ExtendsComponent from "../../_extends/ObjectComponent";
import {deepClone} from "@/lib";
import * as _ from "underscore";

export default {
  name: "Base_TextObject",
  extends: ExtendsComponent,
  props: {
    placeholder: {
      type: String,
      default: "Add text..."
    },
    position: [String, Object],
    align: String,
    color: String,
    fontSize: Number,
    fontWeight: Number,
    fontFamily: String,
    modelName: {
      type: String,
      default: "content"
    },
    dark: Boolean,
    textAlign: String
  },
  mounted() {
    const text = this.localSettings[this.modelName]
    this.$refs.content.textContent = text || "";

    // focus immediately if the sticky has been created
    if (!text && this.modelName == 'content') {
      this.setEditable()
    }
  },
  data() {
    return {
      contentEditable: false
    };
  },
  methods: {
    update: _.debounce(function (event) {
      this.localSettings = deepClone({
        ...this.localSettings,
        [this.modelName]: event.target.textContent
      });

      this.$store.dispatch("object/update", {
        id: this.baseObject.id,
        info: {
          ...this.baseObject.info,
          settings: deepClone(this.localSettings)
        }
      });
    }),
    setEditable() {
      if (this.readonly)
        return;

      if (!this.contentEditable) {
        this.contentEditable = true;
        this.$store.commit('object/setContentEditable', true);

        this.$nextTick(function () {
          this.$refs.content.focus();
        });
      }
    },
    stopPropagationIfContentEditable(event) {
      if (this.contentEditable) {
        event.stopPropagation();
      }
    },
    onBlur(event) {
      this.contentEditable = false;
    }
  },
  computed: {
    textObjectStyles() {
      return {
        cursor: this.contentEditable || this.readonly ? "" : "pointer",
        outline: "none",
        color: this.settings.textColor || this.color || "#333",
        textAlign: this.settings.textAlign || this.align || "center",
        justifyContent: this.settings.textAlign || this.align || "center",
        alignItems: this.settings.verticalAlign || this.align || "center",
        fontSize: (this.settings.fontSize || this.fontSize || 18) + "px",
        fontWeight: this.settings.fontWeight || this.fontWeight || 300,
        fontFamily: this.settings.fontFamily || this.fontFamily || "open sans",
        fontStyle: this.settings.fontStyle || this.fontStyle || "normal",
        textDecoration: this.settings.textDecoration || this.textDecoration || "none",
        backgroundColor: this.dark ? 'rgba(255,255,255,0.3)' : 'none'
      };
    }
  },
  watch: {
    localSettings: {
      handler() {
        if (this.localSettings[this.modelName] != this.$refs.content.textContent) {
          this.$refs.content.textContent = this.localSettings[this.modelName] || "";
        }
        if (JSON.stringify(this.localSettings) != JSON.stringify(this.settings)) {
          this.$emit("update:settings", this.localSettings);
        }
      },
      deep: true
    }
  }
};
</script>

<style scoped>
.text-object {
  height: 100%;
  display: flex;
}

.text-object--bottom {
  position: absolute;
  left: -20px;
  right: -20px;
  top: 100%;
  height: auto !important;
  margin-top: 15px;
  min-height: 34px;
}

.text-object--top {
  position: absolute;
  bottom: calc(100% + 5px);
  height: auto !important;
  left: 50%;
  transform: translateX(-50%);
  min-width: 200px;
}

.text-object__placeholder {
  position: absolute;
  opacity: 0;
  font-style: italic;
  padding: 6px;
  pointer-events: none;
  transition: opacity 0.2s ease;
}

.text-object.text-object--empty:hover .text-object__placeholder {
  opacity: 0.5;
}

.text-object__content {
  position: relative;
  min-width: 100%;
  outline: none;
  word-break: break-word;
  white-space: pre-line;
  padding: 4px;
  border-radius: 3px;
}
</style>

<style>
.text-object__content p {
  margin-bottom: 0;
}

.Base_TextObject > .base-object__content {
  height: 100%;
}
</style>

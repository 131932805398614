import defaultProperties from './default';
import Component from './Component';
import PropertyEditor from './PropertyEditor';

const TriggerObject = {
  Component,
  PropertyEditor,
  defaultProperties
};

export default TriggerObject;
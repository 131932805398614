<template>
  <div ref="viewport" @scroll="onScroll" class="viewport"
    :class="{'following': following}"
    :style="{ cursor: cursor, '--localImage': 'url(' + localImage + ')', borderColor: followingColor }" @click="onClick"
    @mousemove="setCursorPosition">

    <div class="grid-container" v-if="activeChart.options && activeChart.options.gridEnabled">
      <GridRuler :viewportDimension="viewportDimension" :scale="localScale" :translate="localTranslate"
        :gridSize="activeChart.options.gridSize" :gridColor="activeChart.options.gridColor" />
    </div>

    <div class="html-container" :class="{ moving }" :style="canvasStyles">
      <slot name="html" />

      <div v-if="activeChart.options.cursorsEnabled" class="viewport-cursors">
        <div :style="{ left: cursor.params.x + 'px', top: cursor.params.y + 'px', transform: `scale(${1 / localScale})`,
      transformOrigin: 'top left' }"
          v-for="cursor in activeChart ? activeChart.users_cursors : []" :key="cursor.id" class="viewport-cursor"
          :class="{ inactive: isInactiveCursor(now, cursor.updated_at) }">
          <div class="viewport-cursor-arrow">
            <svg
              width="24"
              height="36"
              viewBox="0 0 24 36"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.65376 12.3673H5.46026L5.31717 12.4976L0.500002 16.8829L0.500002 1.19841L11.7841 12.3673H5.65376Z"
                :fill="generateColor(cursor.user.username)"
              />
            </svg>
            </div>
          <div
            class="viewport-cursor-name"
            :style="{
              backgroundColor: generateColor(cursor.user.username),
            }"
          >
            {{ cursor.user.username }}
          </div>
          <!-- <div class="viewport-cursor-arrow">
            <v-icon :style="{ color: generateColor(cursor.user.username) }">pan_tool</v-icon>
          </div> -->
          <!-- <div :style="{ color: generateColor(cursor.user.username) }" class="viewport-cursor-name">{{
            cursor.user.username }}
          </div> -->
        </div>
      </div>
    </div>

    <div class="svg-container" :class="{ moving }">
      <svg width="100%" height="100%">
        <defs id="arrow-defs" />
        <!-- <marker
          id="arrow"
          viewBox="0 -5 10 10"
          refX="5"
          refY="0"
          markerWidth="4"
          markerHeight="4"
          orient="auto"
        >
          <path fill="#e44e9d" d="M0,-5L10,0L0,5" />
        </marker>-->
        <g ref="svgCanvas">
          <slot name="svg" />
        </g>
      </svg>
    </div>

    <div class="selection-container">
      <svg width="100%" height="100%">
        <rect :x="selection.x" :y="selection.y" :width="selection.width" :height="selection.height"
          stroke="rgba(223, 78, 158, .35)" fill="rgba(223, 78, 158, .15)" stroke-width="1" />
      </svg>
    </div>

    <div class="drawing-container" v-if="objectMode == 'drawing'">
      <svg width="100%" height="100%">
        <path v-if="interpolatedLine" fill="none" :d="interpolatedLine" :stroke="drawingOptions.lineColor"
          :stroke-width="drawingOptions.strokeWidth" :stroke-dasharray="drawingOptions.strokeDasharray"
          stroke-linecap="round" />
      </svg>
    </div>

    <div class="comment-content-container" :style="canvasStyles" @click.stop>
      <CommentContent v-for="comment in openedComments" :key="comment.id" :comment="comment" :readonly="!currentUser" />
    </div>

    <p v-if="following && following.username" class="following-banner" :style="{backgroundColor: followingColor}">
      You are following {{ following.username }}
    </p>

    <FloatingUploader />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import * as d3 from "d3";
import * as _ from "underscore";

import { deepClone, generateColor, isInactiveCursor } from "@/lib";
import Utils from "./Utils";
import CommentContent from './Object/Types/Base/CommentObject/Content';
import GridRuler from './GridRuler';
import FloatingUploader from './_Global/FloatingUploader.vue';

import { HEADER_HEIGHT, objectModeTypes } from "../../../../utils/const";

const MINIMUM_POINTS_LENGTH = 2;
export const MIN_SCALE = 0.05;
export const MAX_SCALE = 5;


export default {
  name: "Viewport",
  components: { CommentContent, GridRuler, FloatingUploader },
  title() {
    return (this.activeProject && this.activeChart) ?
      `${this.activeProject.name} - ${this.activeChart.name} || Vulcan` :
      'Vulcan || Intelligence Technology for Everyday Folk';
  },
  props: {
    scale: {
      type: Number,
      default: 1
    },
    readonly: Boolean,
    translate: {
      type: Object,
      default() {
        return { x: 0, y: 0 };
      }
    },
    minTranslate: Object,
    maxTranslate: Object,
    bgImage: String,
    objects: Array,
    allowedObjectTypes: Object,
    moving: Boolean
  },
  data() {
    return {
      localTranslate: deepClone(this.translate),
      localScale: this.scale,
      generateColor,
      isInactiveCursor,
      now: new Date(),
      localImage: "",
      points: [],
      selection: {
        x: 0,
        y: 0,
        width: 0,
        height: 0,
        startX: 0,
        startY: 0,
        aspectRatio: 0
      },
      fixScaleFlag: false,
      visibleRegion: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0
      },
      viewportDimension: {
        width: 0,
        height: 0
      },
      drawingOptions: {
        lineStyle: "Solid",
        strokeWidth: 1,
        strokeColor: "#605da5",
        strokeDasharray: "0"
      },
      interpolatedLine: null,
      cursor: 'auto',
      lastCreatedId: null,
      mediaStreamSource: null
    };
  },
  mounted() {
    this.interval = setInterval(() => {
      this.now = new Date();
    }, 5000);
    this.d3Viewport = d3.select(this.$refs.viewport);
    this.d3SvgCanvas = d3.select(this.$refs.svgCanvas);

    this.d3Viewport
      .call(this.dragHandler())
      .call(this.zoomAction())
      .on("dblclick.zoom", null)
      .on("dblclick", this.onDblClick);

    this.setZoom();
    this.dragAndDrop();

    this.navigateTo(this.$route.query.object);

    window.addEventListener("keydown", this.onKeyDown);
    window.addEventListener("keyup", this.onKeyUp);
    this.localImage = this.bgImage;
    this.cursor = this.chartCursor;
    this.$root.$on('grabMode', this.handleGrabMode)
  },
  async beforeDestroy() {
    clearInterval(this.interval);
    window.removeEventListener("keydown", this.onKeyDown);
    window.removeEventListener("keyup", this.onKeyUp);
    this.$root.$off('grabMode', this.handleGrabMode)
  },
  computed: {
    ...mapGetters({
      openedComments: "chart/openedComments",
      objectMode: "chart/objectMode",
      chartCursor: "chart/mouseCursor",
      specialKey: "chart/specialKey"
    }),
    ...mapState({
      following: state => state.chart.following,
    }),
    followingColor() {
      return this.following ? generateColor(this.following.username) : null;
    },
    canvasStyles() {
      if (this.d3SvgCanvas) {
        this.d3SvgCanvas.attr(
          "transform",
          `translate(${this.localTranslate.x}, ${this.localTranslate.y})
                                              scale(${this.localScale})`
        );
      }
      return {
        transform: `translate3d(${this.localTranslate.x}px, ${this.localTranslate.y}px, ${this.fixScaleFlag ? '-1px' : '0'})
                      scale(${this.localScale})`
      };
    },
  },
  methods: {
    onScroll() {
      //workaround to prevent scrolling on autofocus
      this.$refs.viewport.scrollTop = 0;
      this.$refs.viewport.scrollLeft = 0;
    },
    handleGrabMode({ activate }) {
      activate ? this.activateGrab() : this.disableGrab()
    },
    activateGrab() {
      if (this.interpolatedLine === null) {
        this.$store.commit("chart/setSpecialKey", "space");
        this.cursor = "grab";
        this.d3Viewport.on(".drag", null);
      }
    },
    disableGrab() {
      this.$store.commit("chart/setSpecialKey", null);
      this.cursor = this.chartCursor;
      this.d3Viewport
        .call(this.dragHandler())
        .call(this.zoomAction())
        .on("dblclick.zoom", null);
    },
    onKeyDown(event) {
      if (event.key == ' ') {
        this.activateGrab()
      }
      if (event.shiftKey && this.interpolatedLine === null)
        this.$store.commit("chart/setSpecialKey", "shift");
    },
    onKeyUp(event) {
      this.$store.commit("chart/setSpecialKey", null);
      if (event.key == ' ' || event.key == 'Escape') {
        this.disableGrab()
      }
    },
    onClick() {
      this.$root.$emit("PropertyEditor.close");
      this.$store.commit("object/setContentEditable", false);
      this.deselectAll();
      this.closeAllComments();
      this.selection.width = 0;
      this.selection.height = 0;
    },
    onDblClick() {
      const { x: clientX, y: clientY } = d3.event;
      const x = (clientX - this.localTranslate.x) / this.localScale;
      const y = (clientY - this.localTranslate.y - HEADER_HEIGHT) / this.localScale;
      const groupObjects = this.$store.getters["object/list"].filter(
        o => o.type == 'Base_GroupObject' && 
          (x > o.position.x && x < (o.position.x + o.size.width)) &&
          (y > o.position.y && y < (o.position.y + o.size.height))
      );
      if (groupObjects && groupObjects.length > 0) {
        const objectIds = _.chain(groupObjects).pluck('info').pluck('settings').pluck('objectIds').flatten().value(); 

        const childrenObjects = this.$store.getters["object/list"].filter(
          o => objectIds.includes(o.id) && 
            (x > o.position.x && x < (o.position.x + o.size.width)) &&
            (y > o.position.y && y < (o.position.y + o.size.height))
        );
        if (childrenObjects && childrenObjects[0]) {
          this.$store.commit("object/deselect", groupObjects[0].id);
          this.$store.commit("object/select", childrenObjects[0].id);
          return;
        }
      }

      // if there's mode and there's a tool name from this mode, create this tool's instance
      if (this.objectMode !== objectModeTypes.Shape && this.objectMode !== objectModeTypes.Drawing) {
        this.$emit("createObject", d3.event, this.activeChart.options.activeTool.name)
      }
    },
    navigateTo(objectId) {
      const object = this.$store.getters["object/list"].find(
        o => o.id == objectId
      );
      if (!object)
        return;
      const { x, y } = object.position;
      if (x && y) {
        const width = this.$refs.viewport.clientWidth / 2;
        const height = this.$refs.viewport.clientHeight / 2;
        this.$store.commit("chart/setScale", 1);
        this.$store.commit("chart/setTranslate", {
          x: -parseInt(x) + width,
          y: -parseInt(y) + height
        });
      }
    },
    setCursorPosition(event) {
      if (this.readonly)
        return;

      const { clientX, clientY } = event;
      const x = (clientX - this.localTranslate.x) / this.localScale;
      const y = (clientY - this.localTranslate.y - HEADER_HEIGHT) / this.localScale;
      this.setCursorWithDelay(x, y);
    },
    setCursorWithDelay: _.debounce(function (x, y) {
      if (!this.activeProject)
        return;

      this.api.Project.set_cursor(
        { id: this.activeProject.id },
        {
          cursor: { x, y, selected: this.$store.getters["object/selectedIds"], scale: this.localScale, translate: this.localTranslate },
          chart_id: this.activeChart.id
        }
      );
    }, 300),
    deselectAll() {
      this.$store.commit("object/deselectAll");
      this.$store.commit("object/removeGroupPreview");
    },
    closeAllComments() {
      this.$root.$emit("closeAllComments");
    },
    setZoom() {
      this.d3Viewport.call(
        this.zoomAction().transform,
        d3.zoomIdentity
          .translate(this.translate.x, this.translate.y)
          .scale(this.scale)
      );

      this.localScale = this.scale;
      this.localTranslate.x = this.translate.x;
      this.localTranslate.y = this.translate.y;

      this.viewportDimension.width = this.$refs.viewport.clientWidth;
      this.viewportDimension.height = this.$refs.viewport.clientHeight;
    },
    //this dirty hack keeps Chrome's scaling high-quality
    fixScale: _.debounce(function () {
      setTimeout(() => this.fixScaleFlag = !this.fixScaleFlag, 10);
    }, 80),
    zoomAction() {
      let hide = false;

      return d3
        .zoom()
        .scaleExtent([MIN_SCALE, MAX_SCALE])
        .on("zoom", () => {
          if (!hide) {
            hide = true;
            this.$root.$emit("PropertyEditor.hide");
            this.$root.$emit("DataEditor.hide");
          }

          let { x, y, k } = d3.event.transform;

          this.localScale = +k.toFixed(4);
          this.localTranslate = { x: Math.round(x), y: Math.round(y) };

          this.updateRenderQuality(this.localScale);

          if (this.localScale != this.scale) {
            this.$store.dispatch("chart/setScale", this.localScale);
            this.fixScale();
          }

          if (this.localTranslate.x != this.translate.x || this.localTranslate.y != this.translate.y)
            this.$store.dispatch("chart/setTranslate", this.localTranslate);
        })
        .on("end", () => {
          if (hide) {
            hide = false;
            this.$root.$emit("PropertyEditor.unhide");
            this.$root.$emit("DataEditor.unhide");
          }
        });
    },
    dragHandler() {
      let offsetX, offsetY;
      let minX, minY, maxX, maxY;
      let d3Points = [];
      let activeOptions;
      return d3
        .drag()
        .on("start", async () => {
          const activeChart = this.$store.getters["chart/active"];
          if (activeChart?.options?.objectMode == objectModeTypes.Drawing) {
            activeOptions = activeChart.options.activeTool.options;
            offsetX = activeOptions.offsetX || 0; // drawing offset with mouse cursor
            offsetY = activeOptions.offsetY || 0;
            minX = maxX = d3.event.x + offsetX + activeOptions.strokeWidth / 2;
            minY = maxY = d3.event.y + offsetY + activeOptions.strokeWidth / 2;
            d3Points = [[minX, minY]];
            this.drawingOptions = {
              strokeWidth: activeOptions.strokeWidth * this.scale,
              lineColor: activeOptions.lineColor,
              lineStyle: activeOptions.lineStyle,
              strokeDasharray: this.lineStyles(activeOptions.lineStyle, activeOptions.strokeWidth * this.scale)
            }
          } else {
            this.selection.startX = d3.event.x;
            this.selection.startY = d3.event.y;
            this.selection.x = d3.event.x;
            this.selection.y = d3.event.y;
            this.selection.width = 1;
            this.selection.height = 1;
            this.selection.aspectRatio = this.specialKey == "shift" ? 1 : 0;

            if (this.objectMode == objectModeTypes.Shape) {
              const activeChart = this.$store.getters["chart/active"];
              if (activeChart.options && activeChart.options.activeTool.name) {
                const shape = activeChart.options.activeTool.name;
                const position = { x: d3.event.x + offsetX, y: d3.event.y + offsetY };

                if (shape === 'Rect')
                  this.$emit("createObject", position, "Base_RectObject", {
                    width: this.selection.width,
                    height: this.selection.height
                  });
                else
                  this.$emit("createObject", position, "Base_PolygonObject", {
                    shape,
                    width: this.selection.width,
                    height: this.selection.height
                  });

                this.lastCreatedId = this.$store.getters["object/lastCreatedId"];
              }
            }
          }
        })
        .on("drag", () => {
          if (this.readonly)
            return;

          if (this.objectMode === objectModeTypes.Drawing) {
            if (activeOptions && activeOptions.brushType !== "Eraser") { // TODO: should handle Eraser later on
              // Drawing mode, update the drawing board path
              const x = d3.event.x + offsetX;
              const y = d3.event.y + offsetY;
              if (minX > x) minX = x;
              if (minY > y) minY = y;
              if (maxX < x) maxX = x;
              if (maxY < y) maxY = y;
              d3Points.push([x, y]);
              this.interpolatedLine = d3.line().curve(d3.curveBasis)(d3Points);
            }
          } else {
            const offsetX = d3.event.x - this.selection.startX;
            const offsetY = d3.event.y - this.selection.startY;
            let width = Math.abs(offsetX);
            let x, y;
            if (offsetX >= 0)
              x = this.selection.startX;
            else
              x = this.selection.startX + offsetX;

            let height = Math.abs(offsetY);
            if (offsetY >= 0)
              y = this.selection.startY;
            else
              y = this.selection.startY + offsetY;

            // To keep aspect ratio when user holds shift
            if (this.specialKey == "shift") {
              if (this.selection.aspectRatio == 0 || isNaN(this.selection.aspectRatio)) this.selection.aspectRatio = height / width;
              height = this.selection.aspectRatio * width;
            } else
              this.selection.aspectRatio = 0;

            if (this.objectMode == objectModeTypes.Shape) {
              // Support Drag to draw the shape
              this.$store.dispatch("object/update", {
                id: this.lastCreatedId,
                position: { x: (x - this.localTranslate.x) / this.scale, y: (y - this.localTranslate.y) / this.scale },
                size: { width: width / this.scale, height: height / this.scale }
              });
            } else {
              // Plain object select mode
              this.selection.x = x;
              this.selection.y = y;
              this.selection.width = width;
              this.selection.height = height;
              this.selectObjects();
            }
          }
        })
        .on("end", async () => {
          if (this.objectMode == objectModeTypes.Drawing) {
            if (activeOptions && activeOptions.brushType !== "Eraser") { // TODO: should handle Eraser later on
              // TODO fix drawing offset
              // drawing mode, prepare necessary information to create drawing object
              if (d3Points.length > MINIMUM_POINTS_LENGTH) {
                const lineColor = activeOptions.lineColor;
                const lineStyle = activeOptions.lineStyle;
                const strokeWidth = activeOptions.strokeWidth;
                const width = Math.round((maxX - minX) / this.scale + strokeWidth);
                const height = Math.round((maxY - minY) / this.scale + strokeWidth);
                const position = {
                  x: (minX + maxX - this.scale) / 2,
                  y: (minY + maxY - this.scale) / 2 + HEADER_HEIGHT
                };

                // pointXOffset and pointYOffset are just pre-calculation to save calculation load for d3Points => points mapping
                const pointXOffset = (-minX - 1) / this.scale + strokeWidth / 2;
                const pointYOffset = ((-minY - 1) / this.scale + strokeWidth / 2)
                const points = d3Points.map(point => ([
                  point[0] / this.scale + pointXOffset,
                  point[1] / this.scale + pointYOffset
                ]));

                this.$emit("createObject", position, "Base_DrawingObject", {
                  lineColor,
                  lineStyle,
                  strokeWidth,
                  points: [points],
                  width,
                  height
                });
              }
            }
            this.interpolatedLine = null;
          }
          this.selection.width = 0;
          this.selection.height = 0;
        });
    },

    selectObjects: _.throttle(function () {
      const x = (Math.min(this.selection.startX, this.selection.x) - this.translate.x) / this.scale;
      const y = (Math.min(this.selection.startY, this.selection.y) - this.translate.y) / this.scale;
      const width = this.selection.width / this.scale;
      const height = this.selection.height / this.scale;

      for (let object of this.objects) {
        if (!object.chart_id || !this.allowedObjectTypes[object.type])
          continue;

        if (object.type == "Base_CommentObject" && object.info.settings.parent_id)
          continue;

        if (object.position.x + object.size.width > x &&
          object.position.x < x + width &&
          object.position.y + object.size.height > y &&
          object.position.y < y + height)
          this.$store.commit("object/select", object.id);
        else
          this.$store.commit("object/deselect", object.id);
      }

      this.$store.dispatch("object/setGroupPreview");
    }, 100, { trailing: false }),


    dragAndDrop() {
      let node = this.$refs.viewport;

      node.addEventListener("dragover", function (e) {
        e.stopPropagation();
        e.preventDefault();
      });

      node.addEventListener("drop", async e => {
        e.preventDefault();
        e.stopPropagation();

        console.log('Drop event triggered');

        const rect = node.getBoundingClientRect();
        const position = {
          x: (e.x - rect.x - this.localTranslate.x) / this.localScale,
          y: (e.y - rect.y - this.localTranslate.y) / this.localScale
        };

        let text = e.dataTransfer.getData("text/html") || e.dataTransfer.getData("text");
        console.log('Received data:', text);

        // Handle files first
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
          const files = e.dataTransfer.files;
          let prevWidth = 0;
          
          for (let i = 0; i < files.length; i++) {
            const file = files[i];
            if ([
              "image/jpeg",
              "image/jpg",
              "image/png",
              "image/svg",
              "image/gif"
            ].includes(file.type)) {
              try {
                const imgObj = await Utils.insertImage({
                  position: { 
                    x: position.x + prevWidth, 
                    y: position.y 
                  },
                  file
                });
                prevWidth = imgObj.width + 50;
              } catch (error) {
                console.error('Error inserting image:', error);
              }
            } else {
              try {
                await Utils.insertFile({ position, file });
              } catch (error) {
                console.error('Error inserting file:', error);
              }
            }
          }
          return;
        }

        // Then try to parse JSON data
        if (text) {
          try {
            const transferJSON = JSON.parse(text);
            console.log('Parsed JSON:', transferJSON);

            if (transferJSON.type === "IMAGE_DROP") {
              console.log('Creating image object');
              const objectParams = {
                type: "Base_ImageObject",
                position: {
                  x: position.x - 50, // Center the image (100/2)
                  y: position.y - 50
                },
                size: {
                  width: 100,
                  height: 100
                },
                info: {
                  settings: {
                    visible: true,
                    title: transferJSON.payload?.info?.settings?.title || "",
                    image: transferJSON.payload?.info?.settings?.url || null,
                  }
                }
              };
              
              console.log('Object params:', objectParams);
              await this.$store.dispatch("object/create", objectParams);
              return;
            }

            if (transferJSON.type === "UPDATE_OBJECT") {
              await this.$store.dispatch("object/update", {
                id: transferJSON.payload.id,
                position
              });
              return;
            }
          } catch (error) {
            console.log('Error parsing JSON:', error);
            // Continue to other handlers if JSON parsing fails
          }
        }

        // Handle text/links
        if (text) {
          if (Utils.isLink(text)) {
            if (Utils.youtubeId(text)) {
              await Utils.addYoutube({ text, position });
            } else {
              await Utils.addCrawlerData({ link: text, position });
            }
          } else {
            await Utils.insertNotes({ text, position });
          }
        }
      });
    },
    lineStyles(setting, scale) {
      switch (setting) {
        case 'Dashed':
          return `${10 * scale},${10 * scale}`;
        case 'Dotted':
          return `${5 * scale},${5 * scale}`;
        case 'Solid':
        default:
          return "0";
      }
    },
    updateRenderQuality(scale) {
      if (!this.$refs.svgCanvas) return;

      const qualityLevels = {
        low: { threshold: 0.5, detail: 'low' },
        medium: { threshold: 2, detail: 'medium' },
        high: { threshold: 4, detail: 'high' }
      };

      // Only adjust text quality based on zoom level
      if (scale <= qualityLevels.low.threshold) {
        this.adjustTextQuality('low');
      } else if (scale <= qualityLevels.medium.threshold) {
        this.adjustTextQuality('medium');
      } else if (scale <= qualityLevels.high.threshold) {
        this.adjustTextQuality('high');
      } else {
        this.adjustTextQuality('low'); // Reduce text quality at very high zoom
      }

      // Keep performance monitoring
      if (this.performanceMonitor) {
        this.performanceMonitor.currentZoomLevel = scale;
        this.performanceMonitor.recordZoomMetric(scale);
      }
    },

    adjustTextQuality(level) {
      if (!this.$refs.svgCanvas) return;

      this.$refs.svgCanvas.querySelectorAll('text').forEach(text => {
        switch (level) {
          case 'low':
            text.style.textRendering = 'optimizeSpeed';
            break;
          case 'medium':
            text.style.textRendering = 'optimizeLegibility';
            break;
          case 'high':
            text.style.textRendering = 'geometricPrecision';
            break;
        }
      });
    }
  },
  watch: {
    scale() {
      if (this.localScale != this.scale) {
        this.setZoom();
        // Force grid redraw when scale changes
        this.$nextTick(() => {
          if (this.$refs.gridCanvas) {
            this.initCanvas();
            this.drawGrid();
          }
        });
      }
    },
    translate() {
      if (this.localTranslate.x != this.translate.x || this.localTranslate.y != this.translate.y)
        this.setZoom();
    },
    chartCursor() {
      this.cursor = this.chartCursor;
    },
    $route(to) {
      this.navigateTo(to.query.object);
    },
    scale: {
      handler(newScale) {
        this.localScale = newScale;
        this.updateRenderQuality(newScale);
      },
      immediate: true
    }
  }
};
</script>

<style scoped>
.viewport {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transform-origin: 0 0;
  overflow: hidden;
  background-color: #eeeeee;
  background-image: var(--localImage);
  background-size: cover;
  background-repeat: no-repeat;
  -webkit-overflow-scrolling: touch;
  z-index: 1;
}
.viewport.following {
  border-width: 2px;
  border-style: solid;
  border-radius: 8px;;
}

.following-banner {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  padding: 0 10px 2px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 999;
}

.svg-container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0;
  height: 100%;
  will-change: transform;
}

.grid-container {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  z-index: 0;
  height: calc(100%);
  will-change: transform;
}

.html-container {
  position: relative;
  z-index: 1;
  will-change: transform;
  transform-origin: 0 0;
  height: 0;
  width: 0;
}

.selection-container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
  height: 100%;
  pointer-events: none;
}

.drawing-container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 3;
  height: 100%;
  pointer-events: none;
}


.comment-content-container {
  position: relative;
  z-index: 3;
  will-change: transform;
  transform-origin: 0 0;
  height: 0;
  width: 0;
}


.viewport-cursor-arrow {
  width: 16px;
  height: 24px;
}

.viewport-cursor-name {
  white-space: nowrap;
  margin-left: -8px;
  margin-top: 32px;
  border-radius: 8px;
  font-size: 12px;
  font-weight: bold;
  color: white;
  padding: 4px 8px;
}

.viewport-cursor.inactive {
  opacity: 0.5;
}

.viewport-cursor {
  position: absolute;
  width: 25px;
  height: 25px;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  z-index: 2;
  will-change: transform;
}

.html-container.moving,
.svg-container.moving>svg>g {
  transition: 0.3s transform;
  will-change: transform;
}
</style>

import { Project } from "@/resources";

const store = {
  namespaced: true,
  state: {
    active: null
  },
  mutations: {
    set(state, project) {
      project.charts.sort(
        (a, b) => a.options.order - b.options.order
      );
      state.active = project;
    },
    update(state, project) {
      project.charts.sort(
        (a, b) => a.options.order - b.options.order
      );
      state.active = { ...state.active, ...project };
    }
  },
  actions: {
    set({ commit }, id) {
      return Project.get({ id })
        .then(res => {
          commit("set", res.body);
        });
    }
  },
  getters: {
    active(state) {
      return state.active;
    },
    members(state) {
      if (!state.active?.members) return [];
      
      return state.active.members.map(member => ({
        id: member.id,
        firstname: member.username,
        email: member.email,
        avatar: null
      }));
    }
  }
};

export default store;

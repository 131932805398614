import Vue from "vue";
import Vuex from "vuex";
import object from "./modules/object";
import navigation from "./modules/navigation";
import connection from "./modules/connection";
import project from "./modules/project";
import chart from "./modules/chart";
import user from "./modules/user";
import application from "./modules/application";
import history from "./modules/history";
import intellisense from "./modules/intellisense";
import propertyEditor from "./modules/propertyEditor";
import wayfinder from "./modules/wayfinder";
import component from "./modules/component";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    object,
    navigation,
    connection,
    project,
    chart,
    user,
    application,
    history,
    intellisense,
    propertyEditor,
    wayfinder,
    component
  }
});

export default store;

import processUser from './processUser.yaml';
import userDataSync from './userDataSync.yaml';
import dataTransform from './dataTransform.yaml';

export const workflowExamples = [
  {
    text: 'Process User Data',
    value: 'processUser',
    yaml: processUser
  },
  {
    text: 'User Data Sync',
    value: 'userDataSync',
    yaml: userDataSync
  },
  {
    text: 'Data Transform Pipeline',
    value: 'dataTransform',
    yaml: dataTransform
  }
]; 
<template>
  <div class="workflow-state-manager">
    <div class="state-manager-content">
      <div class="state-manager-header">
        <v-icon small class="mr-2">mdi-database</v-icon>
        <span>State Manager</span>
      </div>
      <div class="state-manager-details">
        <div class="detail-item">
          <v-icon x-small class="mr-1">mdi-database-outline</v-icon>
          {{ persistenceText }}
        </div>
        <div class="detail-item">
          <v-icon x-small class="mr-1">mdi-earth</v-icon>
          {{ scopeText }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ExtendsComponent from '../../_extends/ObjectComponent';

export default {
  name: 'Workflow_StateObject',
  extends: ExtendsComponent,
  computed: {
    persistenceText() {
      return this.localSettings.persistence === 'persistent' ? 'Persistent' : 'Session';
    },
    scopeText() {
      return this.localSettings.scope === 'global' ? 'Global' : 'Local';
    }
  }
};
</script>

<style scoped>
.workflow-state-manager {
  border: 2px solid #009688;
  padding: 8px;
  min-width: 200px;
  border-radius: 5px;
  cursor: default;
  background: white;
}

.state-manager-content {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.state-manager-header {
  display: flex;
  align-items: center;
  font-weight: 500;
}

.state-manager-details {
  display: flex;
  gap: 12px;
  font-size: 0.8em;
  color: #666;
  margin-top: 4px;
  flex-wrap: wrap;
}

.detail-item {
  display: flex;
  align-items: center;
  background: rgba(0, 150, 136, 0.1);
  padding: 2px 6px;
  border-radius: 4px;
}
</style> 
<template>
  <SignFormsWrapper :loading="loading" subtitle="Sign In to continue" :signIn="true">
    <v-divider class="mb-4"></v-divider>
    <v-form @submit.prevent="signIn">
      <v-text-field
        v-model="email"
        @input="onInputEmail"
        :error-messages="errorVisibleEmail"
        :disabled="loading"
        label="Email"
        type="email"
        autofocus
        flat
        hide-details
        outlined
        dense
      />
      <v-text-field
        v-model="password"
        @input="onInputPassword"
        :error-messages="errorVisiblePassword"
        :disabled="loading"
        label="Password"
        type="password"
        counter="32"
        class="mt-2"
        flat
        hide-details
        outlined
        dense
      />
      <v-btn
        type="submit"
        :disabled="!dirtyForm || loading"
        class="mt-6 auth-btn"
        color="primary"
        block
        dense
        elevation="0"
      >Sign In</v-btn>
    </v-form>
    <template v-slot:footer>
      <div class="mt-8 text-center">
        Need an account?
        <router-link class="weight-500" to="/sign-up">Sign Up</router-link>
      </div>
      <div class="mt-4 text-center">
        <router-link class="weight-500" to="/forgot-password">Forgot Password</router-link>
      </div>
    </template>
  </SignFormsWrapper>
</template>

<script>
  import SignFormsWrapper from "./SignFormsWrapper";

  export default {
    name: "TheSignIn",
    components: {
      SignFormsWrapper
    },
    data() {
      return {
        email: '',
        password: '',
        errorVisibleEmail: null,
        errorVisiblePassword: null,
        dirtyForm: false,
        loading: false
      };
    },
    computed: {
      errorEmail() {
        if (!this.email)
          return 'Email can not be empty';
        if (!this.email.match(/.+@.+/))
          return 'Email must be valid';
        return null;
      },
      errorPassword() {
        if (!this.password)
          return 'Password can not be empty';
        if (this.password.length < 8 || this.password.length > 32)
          return 'Password shoud contain 8-32 characters';
        return null;
      }
    },
    mounted() {
      window.analytics.page('Sign In');
    },
    methods: {
      onInputEmail() {
        this.errorVisibleEmail = null;
        this.dirtyForm = true;
      },
      onInputPassword() {
        this.errorVisiblePassword = null;
        this.dirtyForm = true;
      },

      signIn() {
        this.dirtyForm = false;
        if (this.errorEmail || this.errorPassword) {
          this.errorVisibleEmail = this.errorEmail;
          this.errorVisiblePassword = this.errorPassword;
          return;
        }

        this.loading = true;
        this.api.User.authorize({
          session: {
            email: this.email,
            password: this.password
          }
        })
          .then(res => {
            const token = res.body.access_token;
            this.$store.commit("user/setToken", token);
            return this.$store.dispatch("user/load");
          })
          .then(() => {
            if (this.$route.query.redirect) {
              this.$router.push({ path: this.$route.query.redirect });
            } else {
              this.$router.push({ name: "dashboard" });
            }
          })
          .catch(error => {
            this.loading = false;
            if (!error)
              return;

            let message = '';
            error.forEach(_message => {
              message = message + _message + '<br>';
            });
            message = message.substr(0, message.length - 4);

            let data = {message};
            if (message.indexOf('account is not verified') != -1) {
              data.timeout = -1;
              data.actionText = 'Resend verification email';
              data.actionCallback = () => {
                //todo
                this.$root.$emit("showSnack", {
                  message: 'Verification email was sent'
                });
              };
            }
            this.$root.$emit("showSnack", data);
          });
      }
    }
  };
</script>

<style scoped>
.auth-btn {
  text-transform: capitalize !important;
}
</style>
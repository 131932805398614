<template>
  <div
    class="comment-object"
    :class="{opened: 'opened'}"
    :style="objectStyles"
    @dblclick.stop
    @mousemove.stop
    @mousedown.stop
    @mouseup.stop
  >
    <div ref="container" :style="containerStyles">
      <div class="comment-object__container" ref="commentsContainer">
        <div class="comment-object__head" v-if="(!readonly && hasComments) || localSettings.resolve">
          <div class="comment-object__actions">
            <v-switch label="Resolve" v-model="localSettings.resolve" />
          </div>
          <div class="comment-object__colors mx-3">
            <div
              class="comment-object__color"
              v-for="(color, index) in colors"
              :key="index"
              :class="{'comment-object__color--selected': localSettings.color === color}"
              @click="setColor(color)"
              :style="{background: color}"
            />
          </div>
          <v-btn @click="onDelete" text class="toolbar-button">
            <v-icon>delete</v-icon>
          </v-btn>
        </div>
        <div v-if="hasComments" @mousemove.stop @mousedown.stop @mouseup.stop>
          <div 
            v-for="(comment, index) in localSettings.comments" 
            :key="'tile-' + index"
            class="comment-message"
          >
            <div class="comment-message__header">
              <span class="comment-message__author">
                {{ comment.user ? comment.user.username : 'anonymous' }}
              </span>
              <span class="comment-message__date">
                {{ formatDate(comment.datetime) }}
              </span>
            </div>
            <div class="comment-message__text">
              {{ comment.text }}
            </div>
          </div>
        </div>
        <div class="comment-object__input" v-if="!localSettings.resolve && !localReadonly">
          <v-text-field
            class="pt-2 px-2"
            placeholder="Add a comment. Use @ to mention."
            v-model="text"
            @keyup.enter="sendComment"
            @input="handleInput"
            ref="commentInput"
            autofocus
          />
          
          <!-- Custom mention dropdown -->
          <div v-show="showMentionMenu" class="mention-dropdown">
            <v-list v-if="filteredMembers && filteredMembers.length" class="mention-list">
              <v-list-item
                v-for="member in filteredMembers"
                :key="member.id"
                @click="selectMention(member)"
                class="mention-item"
              >
                <v-list-item-avatar size="32">
                  <v-avatar color="primary" size="32">
                    {{ member && member.username ? member.username[0] : '' }}
                  </v-avatar>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{ member.username || '' }}</v-list-item-title>
                  <v-list-item-subtitle>{{ member.email || '' }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import * as d3 from "d3";
  import {deepClone, parseDate} from "@/lib";

  export default {
    name: "CommentContent",
    props: {
      readonly: Boolean,
      comment: Object
    },
    data() {
      return {
        localSettings: deepClone(this.comment.info.settings),
        text: "",
        colors: ["#ffd900", "#00d800", "#ff3e00", "#0076ff", "#333333"],
        showMentionMenu: false,
        mentionPosition: { x: 0, y: 0 },
        mentionSearch: '',
        mentionStartIndex: -1,
      };
    },
    computed: {
      objectStyles() {
        return {
          transform: `translate(${this.comment.position.x}px, ${this.comment.position.y}px) scale(${1/this.scale})`,
        };
      },
      localReadonly() {
        return this.readonly && !(this.activeChart && this.activeChart.options.public_comments);
      },
      containerStyles() {
        const { x2, y2 } = this.localSettings;

        return {
          transform: `translate(${x2}px, ${y2}px)`,
          position: "relative",
          zIndex: 2,
          transformOrigin: '0 0',
        };
      },
      scale() {
        return this.$store.getters["chart/scale"];
      },
      hasComments() {
        return this.localSettings.comments && this.localSettings.comments.length > 0;
      },
      projectMembers() {
        const members = this.$store.getters['project/members'];
        return members || [];
      },
      filteredMembers() {
        if (!this.projectMembers) return [];
        
        // If search is empty, show all members
        if (!this.mentionSearch) {
          return this.projectMembers;
        }
        
        const search = this.mentionSearch.toLowerCase();
        return this.projectMembers.filter(member => {
          if (!member) return false;
          
          const username = member.username || '';
          const email = member.email || '';
          
          return username.toLowerCase().includes(search) ||
                 email.toLowerCase().includes(search);
        });
      }
    },
    watch: {
      settings: {
        handler() {
          if (JSON.stringify(this.settings) != JSON.stringify(this.localSettings))
            this.localSettings = deepClone(this.settings);
        }
      },
      localSettings: {
        handler() {
          if (JSON.stringify(this.settings) != JSON.stringify(this.localSettings))
            this.save();
        },
        deep: true
      },
      localReadonly: {
        handler(val) {
          if (val)
            this.d3container.on(".drag", null);
          else
            this.d3container.call(this.dragHandlerEnd());
        }
      }
    },
    created() {
      this.localSettings.comments = this.localSettings.comments || [];
      this.localSettings.color = this.localSettings.color || "#ffd900";
      this.localSettings.x2 = this.localSettings.x2 || 130;
      this.localSettings.y2 = this.localSettings.y2 || 12;
    },
    mounted() {
      this.d3container = d3.select(this.$refs.container);

      if (this.localReadonly)
        return;

      this.d3container.call(this.dragHandlerEnd());

      this.$nextTick(() => {
        const input = this.$el.querySelector('.comment-object__input input');
        if (input) {
          input.focus();
        }
      });
    },
    methods: {
      save() {
        // save the object/update
        this.$store.dispatch(
          "object/update",
          {
            id: this.comment.id,
            info: {
              ...this.comment.info,
              settings: deepClone(this.localSettings)
            }
          }
        );
      },
      dragHandlerEnd() {
        let initialTransform, initialEvent;

        const dragHandler = d3
          .drag()
          .on("start", () => {
            initialTransform = { x: this.localSettings.x2, y: this.localSettings.y2 };
            initialEvent = d3.event;
          })
          .on("drag", () => {
            this.localSettings.x2 = initialTransform.x + (d3.event.x - initialEvent.x) / this.scale;
            this.localSettings.y2 = initialTransform.y + (d3.event.y - initialEvent.y) / this.scale;
          });

        return dragHandler;
      },
      setColor(color) {
        this.localSettings.color = color;
      },
      sendComment() {
        if (!this.text)
          return;

        const comment = {
          user: this.currentUser,
          text: this.text,
          datetime: new Date()
        };
        this.localSettings.comments.push(comment);
        this.text = "";
      },
      onDelete() {
        this.$store.commit("chart/closeComment", this.comment);
        this.$store.dispatch("object/delete", this.comment.id);
      },
      parseDate(dateStr) {
        return parseDate(dateStr);
      },
      formatDate(date) {
        const now = new Date();
        const commentDate = new Date(date);
        
        // If it's today, show "Today, HH:MM"
        if (commentDate.toDateString() === now.toDateString()) {
          return `Today, ${commentDate.getHours().toString().padStart(2, '0')}:${commentDate.getMinutes().toString().padStart(2, '0')}`;
        }
        
        // If it's yesterday, show "Yesterday, HH:MM"
        const yesterday = new Date(now);
        yesterday.setDate(yesterday.getDate() - 1);
        if (commentDate.toDateString() === yesterday.toDateString()) {
          return `Yesterday, ${commentDate.getHours().toString().padStart(2, '0')}:${commentDate.getMinutes().toString().padStart(2, '0')}`;
        }
        
        // Otherwise show the full date
        return commentDate.toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit'
        });
      },
      handleInput(value) {
        const lastAtIndex = value.lastIndexOf('@');
        if (lastAtIndex !== -1) {
          const cursorPosition = this.$refs.commentInput.$refs.input.selectionStart;
          
          if (lastAtIndex <= cursorPosition && !value.substring(lastAtIndex, cursorPosition).includes(' ')) {
            this.mentionStartIndex = lastAtIndex;
            this.mentionSearch = value.substring(lastAtIndex + 1, cursorPosition);
            this.showMentionMenu = true;
            
            // Show all members when @ is first typed
            if (this.mentionSearch === '') {
              
            }
            return;
          }
        }
        
        this.showMentionMenu = false;
        this.mentionStartIndex = -1;
        this.mentionSearch = '';
      },
      selectMention(member) {
        if (!member) return;
        
        // Use username if available, otherwise use email
        const mentionText = member.username || member.email || '';
        if (!mentionText) return;
        
        const beforeMention = this.text.substring(0, this.mentionStartIndex);
        const afterMention = this.text.substring(this.$refs.commentInput.$refs.input.selectionStart);
        
        // Insert the mention text and add a space after it
        this.text = `${beforeMention}@${mentionText} ${afterMention}`;
        
        // Reset mention state
        this.showMentionMenu = false;
        this.mentionSearch = '';
        this.mentionStartIndex = -1;
        
        // Focus back on input
        this.$nextTick(() => {
          this.$refs.commentInput.focus();
        });
      },
      getCaretCoordinates(element, position) {
        const input = element.querySelector('input');
        const style = window.getComputedStyle(input);
        
        // Create a temporary element to measure text
        const div = document.createElement('div');
        div.style.position = 'absolute';
        div.style.visibility = 'hidden';
        div.style.whiteSpace = 'pre';
        div.style.font = style.font;
        div.textContent = this.text.substring(0, position);
        
        document.body.appendChild(div);
        const coordinates = {
          left: div.offsetWidth + input.offsetLeft,
          top: input.offsetTop,
          height: input.offsetHeight
        };
        document.body.removeChild(div);
        
        return coordinates;
      }
    }
  };
</script>

<style scoped>
  .comment-object {
    position: absolute;
    left: 0;
    top: 0;
    box-shadow: none !important;
  }

  .comment-object__container * {
    transform: translateZ(0);
  }

  .comment-object__container {
    width: 330px;
    background: white;
    position: absolute;
    left: 20px;
    top: -10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    z-index: 2;
    min-height: 50px;
  }

  .comment-object__head {
    height: 45px;
    border-bottom: 1px solid #e6e6e6;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0 0 15px;
    cursor: grab;
  }

  .comment-object__colors {
    display: flex;
  }

  .comment-object__color {
    height: 12px;
    width: 12px;
    border-radius: 10px;
    margin-left: 10px;
    cursor: pointer;
  }

  .comment-object__color--selected {
    border: 2px solid #000;
  }

  .comment-object__input {
    height: 50px;
    border-top: 1px solid #e6e6e6;
    position: relative;
    z-index: 2;
  }

  .comment-object__input >>> input {
    width: 100%;
    height: 100%;
    outline: none;
    padding: 10px;
  }

  .comment-message {
    padding: 12px 16px;
    border-bottom: 1px solid #e6e6e6;
  }

  .comment-message__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
  }

  .comment-message__author {
    font-weight: 700;
    color: rgba(0, 0, 0, 0.6);
  }

  .comment-message__date {
    color: rgba(0, 0, 0, 0.6);
    font-size: 0.875rem;
  }

  .comment-message__text {
    color: rgba(0, 0, 0, 0.87);
    line-height: 1.4;
  }

  .mention-item {
    cursor: pointer;
  }

  .mention-item:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }

  .comment-object__input {
    position: relative;
  }

  .mention-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 200;
    background: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    margin-top: 4px;
  }

  .mention-list {
    max-height: 300px;
    overflow-y: auto;
  }

  .mention-item {
    cursor: pointer;
  }
</style>

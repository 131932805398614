<template>
  <v-toolbar dense no-padding>
    <v-btn-toggle group borderless v-model="openedData">
      <v-btn text style="opacity: 1" class="ma-0">Action</v-btn>
    </v-btn-toggle>

    <v-divider vertical />

    <slot name="menumore" />
  </v-toolbar>
</template>

<script>
import Vue from "vue";
import { getActions } from './actions';
import ObjectPropertyEditorExtend from "../../_extends/ObjectPropertyEditor";

export default {
  name: "Chatbot_MuralActionObjectPropertyEditor",

  extends: ObjectPropertyEditorExtend,

  data() {
    const cmpAction = Vue.extend({
      template: `
        <div style="overflow: hidden">
          <v-combobox
            v-model="settings.action"
            class="mr-1"
            :items="actionItems"
            item-text="text"
            item-value="value"
            label="Actions"
            placeholder="Start typing to Search"
            prepend-icon="mdi-database-search"
          >
            <template v-slot:item="{ item }">
              <div>
                <div>{{ item.value }}</div>
                <div class="caption grey--text">{{ item.description }}</div>
              </div>
            </template>
          </v-combobox>
          <v-textarea
            name="input-7-1"
            filled
            label="Arguments"
            auto-grow
            v-model="settings.arguments"
          />
        </div>
      `,
      props: ["settings", "readonly"],
      computed: {
        actionItems() {
          const actions = getActions(this.activeProject.settings);
          console.log(actions);
          return Object.entries(actions).map(([key, description]) => ({
            text: `${key} - ${description}`,
            value: key,
            description
          }));
        }
      }
    });

    return {
      cmpAction,
      openedData: 0
    };
  },

  methods: {
    onOpenAction() {
      this.$root.$emit("DataEditor.open", {
        component: this.cmpAction,
        props: {
          baseObjectId: this.baseObject.id,
          settings: this.localSettings,
          readonly: this.readonly
        },
        title: "Action"
      });
    }
  },

  watch: {
    openedData() {
      switch (this.openedData) {
        case 0:
          this.onOpenAction();
          break;
        default:
          this.$root.$emit("DataEditor.close");
      }
    },
    'baseObject.id'() {
      this.openedData = 0;
    }
  },

  mounted() {
    this.$root.$on("DataEditor.close", this.onDataEditorClose);
    this.onOpenAction();
  },
  beforeDestroy() {
    this.$root.$off("DataEditor.close", this.onDataEditorClose);
  }
};
</script>
